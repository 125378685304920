import http, { BASE_URL } from "./lib/http";
import withCache from './lib/withCache';

// import zones from './data/zones.json';

/**
 * Restituisce la zona il cui id è passato come parametro. Se nessun id viene
 * passato, viene restituito un array contenente tutte le zone
 * @param {number} id
 * @returns {Array|Object} 
 */
async function fetch(id = null) {
    try {
        //const zones = await withCache(http).get(`${BASE_URL}/zones/active`);
        const zones = await http.get(`${BASE_URL}/zones/active`);

        if (!id) {
            return zones;
        }
    
        return zones.find((zone) => zone.id === id);
    } catch (error) {
        throw error;
    }
}

class Zone {

    /**
     * Costruttore
     * @param {number} id 
     * @param {string} name 
     */
    constructor({ id, name,rewrite, active, is_showcase, image }) {
        this.id = id;
        this.name = name;
        this.rewrite = rewrite;
        this.active = active;
        this.is_showcase = is_showcase;
        this.image = image;
    }

    /**
     * Compone la proprietà 'code' da usare negli URL
     * @returns {string}
     */
    get code() {
        return this.name.toLowerCase().replace(' ', '-');
    }

    /**
     * Restituisce l'elenco di tutte le zone
     * @returns {Array<Zone>}
     */
    static async all() {
        const zones = await fetch();
        return !zones ? [] : zones.map((data) => new Zone(data));
    }

    /**
     * Restituisce i dati della zona indicata dall'id passato come parametro
     * @param {number} id 
     * @returns {Zone}
     */
    static async findById(id) {
        const data = await fetch(id);
        return !data ? {} : new Zone(data);
    }

    /**
     * Restituisce i dati della zona indicata dal codice passato come parametro
     * @param {string} code 
     * @returns {Zone}
     */
    static async findByCode(code) {
        if (!code) {
            throw new Error("Code is missing!");
        }

        const zones = await Zone.all();

        return zones.find((zone) => zone.rewrite === code);
    }

    /**
     * Restituisce le province
     * @returns {Array|Object} 
     */
    static async provinces() {
        try {
            //const provinces = await withCache(http).get(`${BASE_URL}/macro-zones/active`);
            const provinces = await http.get(`${BASE_URL}/macro-zones/active`);

            return provinces;

        } catch (error) {
            throw error;
        }
    }
}

export default Zone;