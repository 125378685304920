import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: '8px 0px',
  }
}));

const Alert = (props) => {
    const classes = useStyles();

  return(
    <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
    >
        <DialogTitle id="alert-dialog-title">
            <span className="alert-dialog-title">{props.title}</span><br />
            <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-alert-content">
            {props.content}
        </DialogContent>
    </Dialog>
  )
}

export default Alert;