import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Order from '../../api/Order';
import OrderSlotsCollection from '../../api/OrderSlotsCollection';
import User from '../../api/User';
import { BEACH_DELIVERY, HOME_DELIVERY, RESTAURANT_DELIVERY } from '../../api/DeliveryAddress';
import Alert from '../Utils/Alert';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as HourIcon } from '../../assets/hour-icon.svg';
import { Store } from '../../contexts/Store/Store';

const ChooseDayHour = (props) => {
    const { setHourIsValid, order, setOrderData, addressType, onSelect } = props;

    const { state } = React.useContext(Store);
    
    const [openAlertError, setOpenAlertError] = React.useState(false);
    const [daySlots, setDaySlots] = React.useState({});
    const [hourSlots, setHourSlots] = React.useState([]);
    const [hourVisibility, setHourVisibility] = React.useState(false);
    const [dayChosen, setDayChosen] = React.useState("");

    

    function setDayActive(id){
        let els = document.getElementsByClassName('set-day active');
        while (els[0]) {
            els[0].classList.remove('active')
        }
        document.getElementById(id).classList.add('active');
        let els2 = document.getElementsByClassName('set-hour active');
        while (els2[0]) {
            els2[0].classList.remove('active')
        }
        setHourVisibility(true);
        setHourIsValid(false);
        setDayChosen(id);
        setHourSlots(daySlots[id]);
    }

    function setHourActive(id, index){
        let els = document.getElementsByClassName('set-hour active');
        while (els[0]) {
            els[0].classList.remove('active')
        }
        document.getElementById(id).classList.add('active');
        onSelect(daySlots[dayChosen][index]);
    }

    function handleCloseAlertError() {
        setOpenAlertError(false);
    }

    React.useEffect(() => {
        OrderSlotsCollection.allAvailableFor({
            ...state,
            deliveryCode: addressType,
        })
        .then((availableSlots) => {
            setDaySlots(availableSlots);
        })
        .catch((error) => {
            setOpenAlertError(true);
        });
    }, [addressType]);

    const isAutoslot = state.shop.info.is_autoslot;

    /* React.useEffect(() => {
        if(daySlots.today && daySlots.today.length > 0 && isAutoslot === 1){
            setDayChosen('today');
            setHourSlots(daySlots['today']);
        }
    }, [daySlots]); */

    React.useEffect(() => {
        if(hourSlots.length > 0 && isAutoslot === 1){
            onSelect(daySlots[dayChosen][0]);
        }
    }, [hourSlots]);
    
    return (
        <div className="delivery-container">
            
            <span className="order-title">
                <HourIcon className="order-icon" />
                {isAutoslot === 1 ? 'Giorno di consegna' : 'Orario di consegna'}
            </span>
            
            <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Siamo spiacenti, il negozio scelto non è al momento operativo.`} />
            {(
                (daySlots.today && daySlots.today.length > 0) || 
                (daySlots.tomorrow && daySlots.tomorrow.length > 0) || 
                (daySlots.threeDays && daySlots.threeDays.length > 0) || 
                (daySlots.fourDays && daySlots.fourDays.length > 0) || 
                (daySlots.fiveDays && daySlots.fiveDays.length > 0) || 
                (daySlots.sixDays && daySlots.sixDays.length > 0) || 
                (daySlots.sevenDays && daySlots.sevenDays.length > 0)
            ) && isAutoslot !== 1 && (<span className="order-subtitle">Seleziona un orario di consegna.</span>)}
            {
                (
                    (daySlots.today && daySlots.today.length > 0) || 
                    (daySlots.tomorrow && daySlots.tomorrow.length > 0) || 
                    (daySlots.threeDays && daySlots.threeDays.length > 0) || 
                    (daySlots.fourDays && daySlots.fourDays.length > 0) || 
                    (daySlots.fiveDays && daySlots.fiveDays.length > 0) || 
                    (daySlots.sixDays && daySlots.sixDays.length > 0) || 
                    (daySlots.sevenDays && daySlots.sevenDays.length > 0)
                ) ? (
                    <>
                    <div className="order-hour">
                        <strong>Scegli un giorno</strong>
                        <Container className="cart-buttons-container">
                            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                {
                                    daySlots.today.length > 0 && 
                                        <Grid item xs={12} sm={6}>
                                            <span id="today" className="cart-buttons set-day" onClick={() => setDayActive("today")}>
                                                {daySlots.today[0].title}
                                            </span>
                                        </Grid>
                                }
                                {
                                    daySlots.tomorrow.length > 0 && 
                                        <Grid item xs={12} sm={6}>
                                            <span id="tomorrow" className="cart-buttons set-day" onClick={() => setDayActive("tomorrow")}>
                                                {daySlots.tomorrow[0].title}
                                            </span>
                                        </Grid>
                                }
                                {
                                    daySlots.threeDays.length > 0 && 
                                        <Grid item xs={12} sm={6}>
                                            <span id="threeDays" className="cart-buttons set-day" onClick={() => setDayActive("threeDays")}>
                                                {daySlots.threeDays[0].title}
                                            </span>
                                        </Grid>
                                }
                                {
                                    daySlots.fourDays.length > 0 && 
                                        <Grid item xs={12} sm={6}>
                                            <span id="fourDays" className="cart-buttons set-day" onClick={() => setDayActive("fourDays")}>
                                                {daySlots.fourDays[0].title}
                                            </span>
                                        </Grid>
                                }
                                {
                                    daySlots.fiveDays.length > 0 && 
                                        <Grid item xs={12} sm={6}>
                                            <span id="fiveDays" className="cart-buttons set-day" onClick={() => setDayActive("fiveDays")}>
                                                {daySlots.fiveDays[0].title}
                                            </span>
                                        </Grid>
                                }
                                {
                                    daySlots.sixDays.length > 0 && 
                                        <Grid item xs={12} sm={6}>
                                            <span id="sixDays" className="cart-buttons set-day" onClick={() => setDayActive("sixDays")}>
                                                {daySlots.sixDays[0].title}
                                            </span>
                                        </Grid>
                                }
                                {
                                    daySlots.sevenDays.length > 0 && 
                                        <Grid item xs={12} sm={6}>
                                            <span id="sevenDays" className="cart-buttons set-day" onClick={() => setDayActive("sevenDays")}>
                                                {daySlots.sevenDays[0].title}
                                            </span>
                                        </Grid>
                                }
                            </Grid>
                        </Container>
                    </div>
                    {
                        (hourSlots.length > 0 && isAutoslot !== 1) ? (
                            <div className="order-hour">
                                <strong>Scegli un orario</strong>
                                <Container className="cart-buttons-container">
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                                        {
                                            hourSlots.map((item, index) => 
                                                <Grid key={`hour-${index}`} item xs={12} sm={3}>
                                                {
                                                    item.enabled ? 
                                                    <span id={`hour-${index}`} className="cart-buttons set-hour" onClick={() => setHourActive(`hour-${index}`, index)}>
                                                        {`${item.start}-${item.end}`}
                                                    </span>
                                                    :
                                                    <Tooltip title="non disponibile" placement="top" arrow disableFocusListener enterTouchDelay={0}>
                                                        <span id={`hour-${index}`} className="cart-buttons set-hour deactive">
                                                            {`${item.start}-${item.end}`}
                                                        </span>
                                                    </Tooltip>
                                                }
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Container>
                            </div>
                        ) : (
                            <>
                            {hourVisibility && isAutoslot !== 1 && <strong>Siamo spiacenti, il negozio scelto non è al momento operativo.</strong>}
                            </>
                        )
                    }
                    </>
                ) : (
                    <>
                    {isAutoslot !== 1 && <strong>Siamo spiacenti, il negozio scelto non è al momento operativo.</strong>}
                    </>
                )
            }
        </div>
    )
}

export default ChooseDayHour;