import http, { BASE_URL } from './lib/http';
import MapPoint from './MapPoint';

class DeliveryPoint extends MapPoint {

    constructor({ latitude, longitude, address }) {
        super({ latitude, longitude });
        this.address = address;
    }

    /**
     * Restituisce le coordinate dell'indirizzo indicato
     * @param {object} data
     * @param {string} data.street
     * @param {number} data.number
     * @param {string} data.city
     * @param {string} data.country
     * @returns {DeliveryPoint}
     * @throws {Error}
     */
    static async fromAddress({ street, number, city, country = "Italy" }) {
        
        const address = `${number}+${street.replace(/[, ]+/g, '+')}`;
        
        let result;
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?street=${address}&city=${city}&country=${country}&format=json`);
            [ result ] = await response.json();
        } catch (error) {
            throw error;
        }

        if (!result) {
            throw new Error("No results!");
        }

        const { lat, lon } = result;

        if (!lat || !lon) {
            throw new Error("No results!");
        }

        return new DeliveryPoint({
            latitude: Number(lat), 
            longitude: Number(lon),
            address: `${street} ${number} ${city}`,
        });
    }

    static withGoogle({ latitude, longitude, address }) {

        return new DeliveryPoint({
            latitude: latitude, 
            longitude: longitude,
            address: address,
        });
    }

    /**
     * Verifica se le coordinate corrispondono ad un indirizzo di consegna
     * coperto dal servizio
     * @returns {boolean}
     * @throws {Error} 
     */
    async isCovered(id) {

        if (!this.latitude || !this.longitude) {
            throw new Error("Data is missing!");
        }

        try {
            await http.post(`${BASE_URL}/check-delivery-area-by-catalog`, {
                latitude: this.latitude,
                longitude: this.longitude,
                id_catalog: id
            })
        } catch (error) {
            if (error.message === JSON.stringify({})) {
                return false;
            };
            throw error;
        }

        return true;
    }

    /**
     * Restituisce un oggetto contenente i valori delle proprietà dell'oggetto
     * @returns {object} data
     * @returns {number} data.latitude
     * @returns {number} data.longitude
     * @returns {string} data.address
     */
    getProperties() {
        return {
            latitude: this.latitude,
            longitude: this.longitude,
            address: this.address,
        };
    }

    /**
     * Metodo statico per ottenere l'intero elenco di delivery points sulla 
     * spiaggia dal server
     * @returns {Array<DeliveryPoint>}
     * @throws {Error}
     */
    static async onTheBeach() {

        try {
            const list = await http.get(`${BASE_URL}/get-delivery-point`);
            return list.map(({ name, latitude, longitude }) => new DeliveryPoint({
                address: name,
                latitude,
                longitude,
            }));
        } catch (error) {
            throw error;
        }
    }

}

export default DeliveryPoint;