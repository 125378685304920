import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import smoothscroll from 'smoothscroll-polyfill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Hidden from '@material-ui/core/Hidden';
import ShopProducts from '../../components/Shop/ShopProducts';
import Alert from '../Utils/Alert';
import ShopCart from '../../components/Cart/ShopCart';
import { Store } from '../../contexts/Store/Store';
import Shop from '../../api/Shop';
import ShopTabs from './ShopTabs';

const scrollToRef = (ref) => ref.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
});

const ShopMenu = (props) => {
  const { shop } = props;
  const { state, dispatch } = React.useContext(Store);
  const [shopData] = React.useState(new Shop(shop));
  const [productsCategories, setProductsCategories] = React.useState([]);
  const [menuItems, setMenuItems] = React.useState([]);
  const [activeMenuCategory, setActiveMenuCategory] = React.useState([]);
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const [tabShown, setTabShown] = React.useState('listino');
  const [cartTotal, setCartTotal] = React.useState(0);
  const [showAll, setShowAll] = React.useState(false);
  const [otherVisible, setOtherVisible] = React.useState(false);
  const [isLoadingCategory, setIsLoadingCategory] = React.useState(false);

  const categoriesList = document.getElementById('categoriesList');
  const shopSubmenu = document.getElementById('shopSubmenu');
  React.useEffect(() => {
    if(shopSubmenu && categoriesList && shopSubmenu.offsetHeight < categoriesList.offsetHeight){
      setOtherVisible(true);
    }
  }, [categoriesList, shopSubmenu]);

  React.useEffect(() => {
    shopData.getSubcategories()
      .then((list) => {
        setProductsCategories(list);
        return list[0];
      })
      .then((firstCategory) => {
        setActiveMenuCategory(firstCategory);
        const res = shopData.getProductsFor(firstCategory);
        return res;
      })
      .then((res) => {
        setMenuItems(res);
        setOpenAlertError(false);
      })
      .catch((error) => {
            setOpenAlertError(true);
        });
  }, [shopData]);

  function handleCloseAlertError(){
        setOpenAlertError(false);
    }

  function handleTab(tab){
      setTabShown(tab);
  }

  function onShowAll(){
      setShowAll(!showAll);
  }

  smoothscroll.polyfill();
  const myRef = React.useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  async function menuClick(productsCategory) {
    setIsLoadingCategory(true);
    setActiveMenuCategory(productsCategory);
    const list = await shopData.getProductsFor(productsCategory);
    setMenuItems(list);
    //setShowAll(false);
    setIsLoadingCategory(false);
    executeScroll();
  }

  function getCartTotal({ total }) {
        setCartTotal(total);
    }

  return(
    <div>
      <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Si è verificato un errore nel caricamento dei dati.`} />
      <div className="shop-menu" ref={myRef}>
      {
        (typeof state.user.isShop == 'undefined' || state.user.isShop === false) &&
          <Container style={{ maxWidth: '1180px', position: 'relative' }} fixed>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
              <Grid item xs={12} md={8}>
                  <ul>
                      <li className={tabShown === "listino" ? "active" : ""} onClick={() => handleTab('listino')}>LISTINO</li>
                      <li className={tabShown === "info" ? "active" : ""} onClick={() => handleTab('info')}>INFO</li>
                      <li className={tabShown === "condizioni" ? "active" : ""} onClick={() => handleTab('condizioni')}>CONSEGNA</li>
                  </ul>
              </Grid>
            </Grid>
          </Container>
      }
      </div>
      {tabShown === "condizioni" &&
        <div style={{ position: 'relative' }}>
          <div className="fascia-consegna"></div>
        </div>
      }
      <Container style={{ maxWidth: '1180px', position: 'relative' }} fixed>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
          <Hidden only={['md', 'lg', 'xl']}>
            <Grid item xs={12}>
              <ShopCart 
                  items={state.cart}
                  user={state.user}
                  shippingCost={shop.info.ship_cost}
                  dispatch={dispatch}
                  onUpdate={getCartTotal}
                  shopData={shopData.info}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8} className="product-grid">
            {
            tabShown === 'listino' ? (
              <>
                <div className="shop-submenu-container">
                  <div id="shopSubmenu" className={showAll ? "shop-submenu show-all" : "shop-submenu"}>
                    {productsCategories.length > 0 ? (
                      <>
                      <ul id="categoriesList">
                        {
                          productsCategories.map((productsCategory, index) => 
                            <li key={`subcategory-${index}`} className={activeMenuCategory.title === productsCategory.title ? "active" : ""} onClick={() => menuClick(productsCategory)}>{productsCategory.title}</li>
                          )
                        }
                      </ul>
                      {otherVisible && <div className="altro" onClick={onShowAll}><span>ALTRO</span>{showAll ? ( <KeyboardArrowUpIcon /> ) : ( <KeyboardArrowDownIcon /> )}</div>}
                      </>
                    ) : (
                      <p style={{ textAlign: 'center', padding: '30px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>
                    )}
                  </div>
                </div>
                <div className="shop-products">
                  {isLoadingCategory && <p style={{ textAlign: 'center', padding: '30px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>}
                  {menuItems.length > 0 && !isLoadingCategory &&
                    <ShopProducts shop={shop} menuItems={menuItems} />
                  }
                </div>
              </>
            ) : (
              <ShopTabs shop={shop} tabShown={tabShown} />
            )}
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={4} className="sticky">
              <ShopCart 
                  items={state.cart}
                  user={state.user}
                  shippingCost={shop.info.ship_cost}
                  dispatch={dispatch}
                  onUpdate={getCartTotal}
                  shopData={shopData.info}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  )
}

export default ShopMenu;