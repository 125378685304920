import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Redirect } from '@reach/router';
import { ReactComponent as Onda } from '../../assets/onda.svg';

import { Store } from '../../contexts/Store/Store';

import ShopCart from '../../components/Cart/ShopCart';
import OrderCart from '../../components/Cart/OrderCart';

import './Cart.scss';

const Cart = (props) => {
    const { state, dispatch } = React.useContext(Store);
    const user = state.user;
    const [cartTotal, setCartTotal] = React.useState(0);
    const [cartSubTotal, setCartSubTotal] = React.useState(0);
    const [shipCost, setShipCost] = React.useState(0);

    function getCartTotal({ total }) {
        setCartTotal(total);
    }
    function getCartSubTotal(subtotal) {
        setCartSubTotal(subtotal);
    }

    if(state.cart.length === 0 || typeof user.id === 'undefined' || !user.isLoggedIn()){
        return(
            <>
                {window.history.length > 0 ? window.history.back() : <Redirect noThrow to="/" />}
            </>
        )
    } else {
        return(
            <>
                <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!">
                    <title>Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!</title>
                    <meta name="description" content="Sei a casa e non ti va di cucinare? Devi fare la spesa ma non vuoi perdere tempo facendo lunghe code? Ti sei dimenticato di passare in farmacia? Visita il sito Rivemo e ordina tutto ciò che vuoi! Puoi ricevere i tuoi prodotti comodamente a domiclio oppure ritirarli in negozio all’orario che preferisci" />
                    <meta name="format-detection" content="telephone=no" />
                </Helmet>
                <div className="top-location in-cart">
                    <Onda className="onda" />
                    <div className="shop-products">
                        <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0, paddingBottom: 100, paddingLeft:0, paddingRight:0 }} fixed>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
                                <Grid item xs={12} md={7}>
                                    <OrderCart setShipCost={setShipCost} cartSubTotal={cartSubTotal} />
                                </Grid>
                                <Grid item xs={12} md={1}></Grid>
                                <Grid item xs={12} md={4} className="sticky">
                                    <ShopCart
                                        items={state.cart}
                                        user={state.user}
                                        shopData={state.shop}
                                        shippingCost={shipCost}
                                        dispatch={dispatch}
                                        getCartSubTotal={getCartSubTotal}
                                        onUpdate={getCartTotal}
                                        position={'in-cart'}
                                        thankYou={true}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </div>
            </>
        )
    }
}

export default Cart;