import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Leaflet from 'leaflet';
import { Map as LeafletMap, TileLayer, Marker, Tooltip } from 'react-leaflet';

Leaflet.Icon.Default.imagePath = `${process.env.REACT_APP_LEAFLET_CDN_URL}/images/`;

const OpenStreetMap = (props) => {
    const { center, zoom, maxZoom, markers, onMarkerClick } = props;

    function handleMarkerClick(event, address) {
        
        const { latlng: {
            lat: latitude,
            lng: longitude,
        } } = event;

        onMarkerClick({
            latitude,
            longitude,
            address,
        });
    }

    return (
        <>
            <Helmet>
                <link 
                    rel="stylesheet" 
                    href={`${process.env.REACT_APP_LEAFLET_CDN_URL}/leaflet.css`}
                    integrity={process.env.REACT_APP_LEAFLET_CDN_INTEGRITY}
                    crossorigin=""
                />
            </Helmet>
            <LeafletMap
                center={center}
                zoom={zoom}
                maxZoom={maxZoom}
            >
                <TileLayer
                    url={process.env.REACT_APP_LEAFLET_TILE_LAYER_URL}
                    attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot; target=&quot;_blank&quot;>OpenStreetMap</a> contributors"
                />
                {
                    markers.length > 0 && markers.map(({ latitude, longitude, address }, index) => (
                            <Marker 
                                key={`marker+${index}`} 
                                position={[latitude, longitude]}
                                onClick={(event) => handleMarkerClick(event, address)} 
                            >
                                <Tooltip>{address}</Tooltip>
                            </Marker>
                        ))
                }
            </LeafletMap>
        </>
    );

};

OpenStreetMap.propTypes = {
    center: PropTypes.arrayOf(PropTypes.number).isRequired,
    zoom: PropTypes.number,
    maxZoom: PropTypes.number,
    markers: PropTypes.arrayOf(PropTypes.object),
    onMarkerClick: PropTypes.func.isRequired,
};

OpenStreetMap.defaultProps = {
    center: [45.9578114, 12.6608068],
    zoom: 13,
    maxZoom: 19,
    markers: [],
    onMarkerClick: () => undefined,
}

export default OpenStreetMap;