import React, { useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Zone from '../../api/Zone';

import './Cities.scss';

const Cities = (props) => {
    const { setLocation, zone, currentCat } = props;
    const [zones, setZones] = useState([]);

    const catCurrent = typeof currentCat == 'undefined' ? null : currentCat.info.rewrite;

    useEffect(() => {
        Zone.all()
            .then((zones) => {
                setZones(zones);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    

    return (
        <Autocomplete
            id="combo-box-demo"
            options={zones}
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => {
                if(typeof value != 'undefined' && value != null){
                    setLocation(value.rewrite, catCurrent, value.id, value.is_showcase);
                } else {
                    setLocation("");
                }
            }}
            renderInput={(params) => <TextField {...params} value={zone || `Inserisci comune`} placeholder={zone || `Inserisci comune`} variant="standard" className={zone ? 'is-zone' : ''} />}
        />
    )
};

export default Cities;