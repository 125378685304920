import http, { BASE_URL } from './lib/http';

export const CARD_PAYMENT = "CARD";
export const CASH_PAYMENT = "CASH";
export const POS_PAYMENT = "POS";

const VALID_METHODS = new Set([CARD_PAYMENT, CASH_PAYMENT, POS_PAYMENT]);

class Payment {

    /**
     * Costruttore
     * @param {User} user 
     * @param {string} method
     * @throws {Error} 
     */
    constructor(user, method) {
        if (!VALID_METHODS.has(method)) {
            throw new Error("Invalid payment method");
        }

        this.user = user;
        this.method = method;
    }

    /**
     * Metodo statico per inizializzare un oggetto Payment
     * @param {object} params
     * @param {User} params.user
     * @param {string} params.method
     * @returns {Payment}
     * @throws {Error}
     */
    static init({ user, method }) {
        try {
            return new Payment(user, method);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Richiede al server il client token con cui inizializzare il drop-in di
     * Braintree 
     * @returns {string}
     * @throws {Error}
     */
    async getToken() {
        let response;
        try {
            response = await http.get(`${BASE_URL}/get-braintree-token`, {
                "Access-Token": this.user.token.get(),
            });
        } catch (error) {
            throw error;
        }

        return this.clientToken = response.token;
    }

    /**
     * Memorizza il nonce ottenuto dal server Braintree e necessario per 
     * completare il pagamento attraverso le API di Rivemo
     * @param {string} nonce
     * @returns {this} 
     */
    addNonce(nonce) {
        this.nonce = nonce;
        return this;
    }

    /**
     * Restituisce i dati relativi al pagamento da inviare alle API in base
     * al metodo di pagamento impostato
     * @returns {object}
     * @throws {Error}
     */
    getData() {
        let data = {
            method: this.method,
        };

        if (this.isInCash() || this.isByPOS()) {
            return data;
        }

        if (!this.nonce) {
            throw new Error("Braintree nonce is missing!");
        }

        data.nonce = this.nonce;

        return data;
    }

    /**
     * Verifica se il metodo di pagamento impostato è la carta di credito (e
     * dunque Braintree).
     * @returns {boolean}
     */
    isByCard() {
        return this.method === CARD_PAYMENT;
    }

    /**
     * Verifica se il metodo di pagamento impostato sono i contanti
     * @returns {boolean}
     */
    isInCash() {
        return this.method === CASH_PAYMENT;
    }

    /**
     * Verifica se il metodo di pagamento impostato è il pos
     * @returns {boolean}
     */
    isByPOS() {
        return this.method === POS_PAYMENT;
    }
}

export default Payment;