import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Onda } from '../../assets/onda.svg';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from '@reach/router';
import { setShop } from '../../contexts/Store/actions';
import { Store } from '../../contexts/Store/Store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'

import './Location.scss';
import { setZone } from '../../contexts/Store/actions';
import Zone from '../../api/Zone';
import Category from '../../api/Category';
import Shop from '../../api/Shop';
//import ShopGrid from '../../components/Location/ShopGrid';

import Ascom from '../../assets/ascom.png';
import Comune from '../../assets/comune_monfalcone.png';
import AscomPN from '../../assets/ascom-pn.png';
import Sviluppo from '../../assets/sviluppo-e-territorio.png';

import Spesa from '../../assets/spesa_miniatura.jpg';
import Casa from '../../assets/casa_giardino_miniatura.jpg';
import Cibo from '../../assets/gastronomia_miniatura.jpg';
import Riparazioni from '../../assets/manutenzioni_miniatura.jpg';
import Salute from '../../assets/salute_benessere_miniatura.jpg';
import Shopping from '../../assets/shopping_miniatura.jpg';
import Scooter from '../../assets/condizioni/scooter-blu.svg';
import Take from '../../assets/condizioni/store-blu.svg';
//import MetroCart from '../../assets/condizioni/metro-cart.svg';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as NextArrow } from '../../assets/arrow-sx.svg';

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <NextArrow
      className={className}
      onClick={onClick}
    />
  );
}

const Partnership = (props) => {
    const city = props.zone.name;
    if(city == 'Monfalcone'){
        return(
            <div className="partnership">
                <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0 }} fixed>
                    <Grid container direction="row" justify="space-between" className="partnership-container" alignItems="center" spacing={2}>
                        <p className="partnership-text">
                            <span className="tit"><strong>in collaborazione con</strong></span><br className="mob" />
                            Ascom Monfalcone è un’organizzazione sindacale che raggruppa le imprese operanti nel territorio di Monfalcone e del suo Mandamento con l’obiettivo di rappresentare e tutelare gli interessi sociali, morali ed economici dei soggetti imprenditoriali e professionali che operano nel settore terziario.
                        </p>
                        <p className="partnership-logo"><a href="http://www.ascom-monfalcone.com/wp/" target="_blank"><img src={Ascom} alt="Ascom Monfalcone" /></a></p>
                        <p className="partnership-logo"><a href="https://www.comune.monfalcone.go.it/" target="_blank"><img src={Comune} alt="Comune di Monfalcone" /></a></p>
                    </Grid>
                </Container>
            </div>
        )
    } else if(city == 'Pordenone'){
        return(
            <div className="partnership">
                <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0 }} fixed>
                    <Grid container direction="row" justify="space-between" className="partnership-container" alignItems="center" spacing={2}>
                        <p className="partnership-text">
                            <span className="tit"><strong>in collaborazione con</strong></span><br className="mob" />
                            Ascom Pordenone è un’organizzazione sindacale che raggruppa le imprese operanti nel territorio di Pordenone e del suo Mandamento con l’obiettivo di rappresentare e tutelare gli interessi sociali, morali ed economici dei soggetti imprenditoriali e professionali che operano nel settore terziario.
                        </p>
                        <p className="partnership-logo"><a href="https://ascom.pn.it/it/home" target="_blank"><img src={AscomPN} alt="Ascom Pordenone" /></a></p>
                        <p className="partnership-logo"><a href="http://centroancheio.sviluppoeterritorio.it/" target="_blank"><img src={Sviluppo} alt="Sviluppo e territorio" /></a></p>
                    </Grid>
                </Container>
            </div>
        )
    } else {
        return(
            <div></div>
        )
    }
}

const SingleLocation = (props) => {
    const { zoneCode } = props;
    const [selectedZone, setSelectedZone] = useState();
    const [categories, setCategories] = React.useState([]);
    const [shopsForCategories, setShopsForCategories] = React.useState([]);
    const [totalShops, setTotalShops] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);

    const { dispatch } = useContext(Store);


    /* useEffect(() => {
        if(shopsForCategories && shopsForCategories.length > 0){
            console.log('from here shopsForCategories: ');
            console.log(shopsForCategories);
        }
    }, [shopsForCategories]); */

    let groupShopsCategories = [];
    let numShops = 0;

    useEffect(() => {
        if(selectedZone && categories && categories.length > 0){
            const forLoop = async _ => {
                for(let i = 0; i < categories.length; i++){
                    let params = {
                        info: categories[i].info,
                        zone: selectedZone
                    }
                    const shops = await Shop.listForCategory(params);
                    numShops += shops.length;
                    setTotalShops(numShops);
                    let shopsToPush = [];
                    if(shops.length > 10){
                        shopsToPush = shops.slice(0, 10);
                    } else {
                        shopsToPush = shops;
                    }
                    if(shopsToPush.length > 0){
                        groupShopsCategories.push({
                            info: params.info,
                            shops: shopsToPush
                        });
                    }
                }
                setShopsForCategories(groupShopsCategories);
                setIsLoading(false);
            }
            forLoop();
        }
    }, [selectedZone, categories]);

    useEffect(() => {
        if(selectedZone){
            Category.departments()
            .then((list) => {
                setCategories(list);
            })
            .catch((error) => {
                console.log(error)
            });
        }
    }, [selectedZone]);
  
    useEffect(() => {
        Zone.findByCode(zoneCode)
        .then((zone) => {
            setSelectedZone(zone);
            dispatch(setZone({ zone }));
        })
        .catch((error) => {
            console.error(error);
        })
    }, [zoneCode, dispatch]);

  return(
    <div>
        {selectedZone && 
            <>
                <Helmet defaultTitle={`${totalShops} attività che consegnano a domicilio a ${selectedZone.name}`}>
                <title>{`${totalShops} attività che consegnano a domicilio a ${selectedZone.name}`}</title>
                <meta charSet="utf-8" />
                <meta name="format-detection" content="telephone=no" />
                </Helmet>
                <div className="top-shop normal-page" style={{ backgroundImage: `url(${selectedZone.image})` }}>
                    <Onda className="onda" />
                    <div className="top-text">
                        <h1>Acquista a {selectedZone.name}</h1>
                        <h2>{totalShops} attività che consegnano a domicilio.</h2>
                    </div>
                </div>
            </>
        }
        <div className="shop-page">
            <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0 }} className="restaurants-container" fixed>
                {isLoading === true &&
                    <>
                    <p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>
                    </>
                }
                {
                    shopsForCategories.length > 0 && shopsForCategories.map((category, index) => {

                        const categoryRewrite = `${category.info.rewrite}/`;

                        let imageTop = Spesa;
                        switch(category.info.id_lang){
                            default:
                            case '81':
                                imageTop = Spesa;
                                break;
                            case '66':
                                imageTop = Casa;
                                break;
                            case '69':
                                imageTop = Cibo;
                                break;
                            case '72':
                                imageTop = Riparazioni;
                                break;
                            case '75':
                                imageTop = Salute;
                                break;
                            case '78':
                                imageTop = Shopping;
                                break;
                        }

                        const settings = {
                            dots: false,
                            infinite: category.shops.length > 3,
                            arrows: true,
                            speed: 500,
                            //rows: 0,
                            slidesToShow: 3,
                            slidesToScroll: 2,
                            prevArrow: <SampleNextArrow />,
                            nextArrow: <SampleNextArrow />,
                            responsive: [
                                {
                                    breakpoint: 640,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                }
                            ]
                        };

                        return(
                        <div key={`category-${index}`} className="single-city-content">
                            <div className="single-city-row">
                                <div className="single-city-cat">
                                    <img src={category.info.svg} alt={category.info.svg_title} className="food-img" />
                                    <div className="single-city-cat-name">
                                        {category.info.name}
                                        <Link to={`${category.info.rewrite}`} className="view-all">vedi tutto</Link>
                                    </div>
                                </div>
                                <div className="single-city-grid">
                                    <Slider {...settings}>
                                        {category.shops.map((shop, index) => 
                                        <div className="shop-card isPortal" key={`card-${index}`}>
                                            {(typeof shop.info != 'undefined' && Array.isArray(shop.info.image) && shop.info.image[0] != null) ? (
                                            <div className="shop-card-media">
                                                <Link to={`./${categoryRewrite}${shop.info['company_name'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w]/gi,' ').trim().replace(/\s+/g,'-').toLowerCase()}/${shop.id}`} title={shop.info.company_name} onClick={() => dispatch(setShop({ shop }))}><div style={{ overflow: 'hidden' }}><div className="shop-card-media-img lonely" style={{ backgroundImage:`url(${shop.info.image[0]})`}}></div></div></Link>
                                                <div className="shop-alerts">
                                                {shop.info.tags.map((tag, index) => 
                                                    <div className="shop-first" key={`tag-${index}`}>{tag}</div>
                                                )}
                                                </div>
                                            </div>
                                            ) : (
                                            <Link to={`./${categoryRewrite}${shop.info['company_name'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w]/gi,' ').trim().replace(/\s+/g,'-').toLowerCase()}/${shop.id}`} className="link_scheda" title={shop.info.company_name} onClick={() => dispatch(setShop({ shop }))}>
                                                <div className="shop-card-media">
                                                    <div className="shop-card-media-img no-img" style={{ backgroundImage:`url(${imageTop})`}}></div>
                                                    <div className="shop-alerts">
                                                    {shop.info.tags.map((tag, index) => 
                                                        <div className="shop-first" key={`tag-${index}`}>{tag}</div>
                                                    )}
                                                    </div>
                                                </div>
                                            </Link>
                                            )}
                                            <Link to={`./${categoryRewrite}${shop.info['company_name'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w]/gi,' ').trim().replace(/\s+/g,'-').toLowerCase()}/${shop.id}`} className="link_scheda" title={shop.info.company_name} onClick={() => dispatch(setShop({ shop }))}>
                                                <div className="shop-card-content">
                                                    <h3 className="shop-name">{shop.info.company_name}</h3>
                                                    {
                                                    shop.info.delivery_free && shop.info.delivery_free == 1 &&
                                                        <><span className="consegna-gratuita">Consegna gratuita</span></>
                                                    }
                                                    <div className="buy-type-container">
                                                    {
                                                        (shop.info.delivery_mode && shop.info.delivery_mode !== null && shop.info.delivery_mode !== '' && shop.info.delivery_mode.indexOf('DELIVERY') !== -1) &&
                                                        <div className="delivery-type">
                                                            <img src={Scooter} alt="consegna a domicilio" width="21" />
                                                            <span>
                                                            <strong>DOMICILIO</strong>
                                                            {
                                                                shop.info.has_catalog == 1 && ((shop.info.first_slot && shop.info.first_slot.day !== null && shop.info.first_slot.day != "")) &&
                                                                <><br className="mob" /> Ordina ora, ricevi <strong>{shop.info.first_slot.day.toLowerCase()} {(shop.info.first_slot.hour && shop.info.first_slot.hour !== "" && shop.info.is_autoslot !== 1) ? `ore ${shop.info.first_slot.hour}` : ''}</strong></>
                                                            }
                                                            </span>
                                                        </div>
                                                    }
                                                    {
                                                        (shop.info.delivery_mode && shop.info.delivery_mode !== null && shop.info.delivery_mode !== '' && shop.info.delivery_mode.indexOf('TAKEAWAY') !== -1) &&
                                                        <div className="delivery-type">
                                                            <img src={Take} alt="ritiro per asporto" width="17" />
                                                            <span>
                                                            <strong>ASPORTO</strong>
                                                            {
                                                                shop.info.has_catalog == 1 && ((shop.info.first_slot && shop.info.first_slot.day !== null && shop.info.first_slot.day != "")) &&
                                                                <><br className="mob" /> Ordina ora, ritiri <strong>{shop.info.first_slot.day.toLowerCase()} {(shop.info.first_slot.hour && shop.info.first_slot.hour !== "" && shop.info.is_autoslot !== 1) ? `ore ${shop.info.first_slot.hour}` : ''}</strong></>
                                                            }
                                                            </span>
                                                        </div>
                                                    }
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        )}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        )
                    })
                }
            </Container>
            {selectedZone && <Partnership zone={selectedZone} />}
        </div>
    </div>
  )
}

export default SingleLocation;