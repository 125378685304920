import React, { useState, useEffect } from 'react';
import './docs.css';
import shopsData from '../../api/__tests__/fixtures/restaurants.json';
import Shop from '../../api/Shop';
import console from './helpers';
// import Subcategory from '../../api/Subcategory';

const ShopDocs = (props) => {
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [options, setOptions] = useState([]);
    const [shop] = useState(new Shop(shopsData.info[0]));
    
    useEffect(() => {
        shop.getSubcategories()
            .then((list) => {
                console.pretty("subcategories", list, "table");
                setSubcategories(list);

                return shop.getProductsFor(list[0]);
            })
            .then((list) => {
                console.pretty("products", list, "table");
                setProducts(list);

                console.pretty("product", list[29], "dir");

                return list[29].getAllOptions();
            })
            .then(([list]) => {
                console.pretty("options", list, "table");

                setOptions(list);
            })
            .catch((error) => console.wtf(error));
    }, [shop]);

    return (
        <div className={"code"}>
            <h1>Shop docs</h1>
            <p>
                <span role="img" aria-label="wait">✋</span> 
                &nbsp;Apri la <strong>console</strong> per vedere alcuni esempi <em>live</em>&nbsp; 
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
            </p>
            <h3 id="api-shop">API Shop</h3>
            <p>Gli oggetti Shop hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>id</strong> id numerico</li>
                <li><strong>code</strong> codice alfanumerico</li>
                <li><strong>name</strong> dicitura completa </li>
                <li><strong>info</strong> oggetto contenente ulteriori dati (ad es., gli orari d'apertura)</li>
            </ul>
            <p>i seguenti metodi utili in questo contesto:</p>
            <ul>
                <li><strong>load(<em>id</em>)</strong> metodo <em>statico</em> e <em>asincrono</em> per ottenere i dati dal server e creare un'istanza di Shop a partire dall'id del negozio</li>
                <li><strong>getSubcategories()</strong> metodo <em>asincrono</em> per ottenere l'elenco completo delle sottocategorie dei prodotti disponibili</li>
                <li><strong>getProductsFor(<em>subcategory</em>)</strong> metodo <em>asincrono</em> per ottenere l'elenco completo dei prodotti di una certa sottocategoria</li>
            </ul>
            <pre>
                <code>
                    {`
                        import Shop from '../../api/Shop';
                        ...
                        // Per chiedere al server i dati di un negozio a partire
                        // dal suo id, occorre invocare il metodo load() che dai
                        // dati ottenuti dal server costruisce un'istanza di Shop:
                        try {
                            const shop = await Shop.load(16);
                        } catch (error) {
                            // Si finisce qui se il server risponde picche
                        }

                        // ponendo che shop sia un oggetto di tipo Shop,
                        // l'elenco di tutte le sottocategorie di prodotti
                        // si può ottenere così:
                        let subcategories;
                        shop.getSubcategories()
                            .then((list) => {
                                subcategories = list;
                            });

                        // Si ottiene un elenco di oggetti Subcategory fatti così:
                        // ${JSON.stringify(subcategories[0])}

                        // per ottenere l'elenco dei prodotti disponibili di 
                        // una data sottocategoria, si può usare il metodo getProductsFor()
                        let products;
                        shop.getProductsFor(subcategories[0])
                            .then((list) => {
                                products = list;
                            });
                        
                        // Si ottiene un elenco di oggetti Product fatti così:
                        // {
                        //      "id": 839,
                        //      "title": "Tonno & Cipolla",
                        //      "description": "Pomodoro, mozzarella,tonno, cipolla",
                        //      "imageUrl": null,
                        //      "price": 7.6,
                        //      "standardOptions": [
                        //          {"id":809,"title":"Pomodoro","price":0,"info":{"product_code":"ACCESSORI_PIZZE_PEPE_01"}},
                        //          {"id":892,"title":"Mozzarella","price":0,"info":{"product_code":"ACCESSORI_PIZZE_PEPE_03"}},
                        //          {"id":926,"title":"Tonno","price":0,"info":{"product_code":"ACCESSORI_PIZZE_PEPE_38"}},
                        //          {"id":908,"title":"Cipolla","price":0,"info":{"product_code":"ACCESSORI_PIZZE_PEPE_20"}}
                        //      ],
                        //      "info": {
                        //          "product_code":"PIZZE_PEPE_30",
                        //          "max_accessories":3,
                        //          "min_accessories":null,
                        //          "shopId":16,
                        //          "subcategoryId":90
                        //      },
                        //      "maxOptions":null,
                        //      "options":[...]
                        //  }
                    `}
                </code>
            </pre>
            <h3 id="api-product">API Product</h3>
            <p>Gli oggetti Product hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>id</strong> id numerico</li>
                <li><strong>title</strong> dicitura</li>
                <li><strong>description</strong> descrizione estesa </li>
                <li><strong>imageUrl</strong> URL immagine </li>
                <li><strong>price</strong> prezzo </li>
                <li><strong>standardOptions</strong> elenco di oggetti Option per rappresentare le opzioni già incluse </li>
                <li><strong>selectedOptions</strong> elenco di Option selezionate</li>
                <li><strong>removedStandardOptions</strong> elenco di Option standard rimosse</li>
                <li><strong>info</strong> oggetto contenente ulteriori dati</li>
            </ul>
            <p>il seguente metodo <strong>statico</strong>:</p>
            <ul>
                <li><strong>make(<em>product</em>)</strong> per clonare un oggetto di tipo Product</li>
            </ul>
            <p>e i seguenti metodi:</p>
            <ul>
                <li><strong>getAllOptions()</strong> metodo <em>asincrono</em> per ottenere l'elenco completo delle opzioni disponibili per il prodotto e il numero massimo di opzioni selezionabili</li>
                <li><strong>selectOption(<em>option</em>)</strong> metodo per aggiungere un'opzione a quelle selezionate per il prodotto</li>
                <li><strong>getTotalPrice()</strong> restituisce il prezzo totale del prodotto (base + opzioni selezionate)</li>
                <li><strong>hasOption(<em>option</em>)</strong> verifica se l'opzione è fra quelle selezionate</li>
                <li><strong>removeOption(<em>option</em>)</strong> elimina l'opzione dall'elenco di quelle selezionate</li>
                <li><strong>isStandardOption(<em>option</em>)</strong> verifica se l'opzione è fra quelle standard</li>
                <li><strong>isStandardOptionRemoved(<em>option</em>)</strong> verifica se l'opzione standard passata è stata rimossa</li>
                <li><strong>removeStandardOption(<em>option</em>)</strong> rimuove l'opzione standard dal prodotto</li>
                <li><strong>restoreStandardOption(<em>option</em>)</strong> ripristina l'opzione standard precedentemente rimossa</li>
            </ul>
            <pre>
                <code>
                    {`
                        import Product from '../../api/Product';
                        ...
                        
                        // ponendo che product sia un oggetto di tipo Product,
                        // l'elenco di tutte le opzioni disponibili ed il numero
                        // massimo selezionabile si può ottenere così:
                        let options;
                        let maxOptions;
                        product.getAllOptions()
                            .then(([list, number]) => {
                                options = list;
                                maxOptions = number;
                            });

                        // Si ottiene un elenco di oggetti Option fatti così:
                        // ${JSON.stringify(options[0])}

                        // La selezione di un'opzione si può eseguire così:
                        product.selectOption(options[0]);

                        // NB: se il massimo di opzioni selezionabili è stato raggiunto,
                        // il metodo tira un'eccezione catturabile in un blocco try/catch

                        // Il prezzo totale di un prodotto si ottiene così:
                        const total = product.getTotalPrice();
                    `}
                </code>
            </pre>
        </div>
    );
};

export default ShopDocs;