import http, { BASE_URL, BASE_URL_1_3 } from './lib/http';
import DeliveryAddress from './DeliveryAddress';
import TimeSlot from './TimeSlot';
import Validator from './lib/validation/Validator';

class OrderSlotsCollection {

    /**
     * Costruttore
     * @param {object} params
     * @param {Array<TimeSlot>} params.today
     * @param {Array<TimeSlot>} params.tomorrow
     */
    constructor({ today = [], tomorrow = [], threeDays = [], fourDays = [], fiveDays = [], sixDays = [], sevenDays = [] }) {
        this.today = today;
        this.tomorrow = tomorrow;
        this.threeDays = threeDays;
        this.fourDays = fourDays;
        this.fiveDays = fiveDays;
        this.sixDays = sixDays;
        this.sevenDays = sevenDays;
    }

    /**
     * Metodo statico per creare un'istanza  dopo aver ottenuto dal server
     * i dati necessari
     * @param {object} params 
     * @param {Zone} params.zone 
     * @param {Shop} params.shop 
     * @param {string} params.deliveryCode 
     * @param {User} params.user
     * @param {Array<object>} params.cart
     * @returns {OrderSlotsCollection}
     * @throws {Error}
     */
    static async allAvailableFor({ zone, shop, deliveryCode, user, cart }) {

        /* if (Validator.isAValidZone(zone).fails()) {
            throw new Error("Not a valid zone");
        } 

        if (Validator.isAValidShop(shop).fails()) {
            throw new Error("Not a valid shop");
        }
        
        if (Validator.isAValidUser(user).fails()) {
            throw new Error("Not a valid user");
        }

        if (!DeliveryAddress.isThisAValidTypeCode(deliveryCode)) {
            throw new Error(`"${deliveryCode}" is not a valid delivery code`);
        }*/
        
        let slotsCollection;
        try {
            slotsCollection = await http.post(
                `${BASE_URL_1_3}/slots-for-shop`,
                {
                    zoneId: zone.id,
                    shopId: shop.info.id_catalog,
                    deliveryCode,
                    products: cart.map(({ counter, product }) => ({
                        amount: counter,
                        id: product.id,
                    })),
                },
                {
                    "Access-Token": user.token.get(), 
                }
            );
            return new OrderSlotsCollection({
                today: slotsCollection[0] ? slotsCollection[0].slots.map((slotInfo) => TimeSlot.parse(slotsCollection[0].title, slotInfo)) : [],
                tomorrow: slotsCollection[1] ? slotsCollection[1].slots.map((slotInfo) => TimeSlot.parse(slotsCollection[1].title, slotInfo)) : [],
                threeDays: slotsCollection[2] ? slotsCollection[2].slots.map((slotInfo) => TimeSlot.parse(slotsCollection[2].title, slotInfo)) : [],
                fourDays: slotsCollection[3] ? slotsCollection[3].slots.map((slotInfo) => TimeSlot.parse(slotsCollection[3].title, slotInfo)) : [],
                fiveDays: slotsCollection[4] ? slotsCollection[4].slots.map((slotInfo) => TimeSlot.parse(slotsCollection[4].title, slotInfo)) : [],
                sixDays: slotsCollection[5] ? slotsCollection[5].slots.map((slotInfo) => TimeSlot.parse(slotsCollection[5].title, slotInfo)) : [],
                sevenDays: slotsCollection[6] ? slotsCollection[6].slots.map((slotInfo) => TimeSlot.parse(slotsCollection[6].title, slotInfo)) : [],
            });
        } catch (error) {
            throw error;
        }

    }

};

export default OrderSlotsCollection;