import React from 'react';
import { Router } from '@reach/router';

import { StoreProvider } from '../../contexts/Store/Store';

import ScrollToTop from "./Top";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Home from '../Home/Home';
import Privacy from '../Home/Privacy';
import Copyright from '../Home/Copyright';
import Cookie from '../Home/Cookie';
import Contacts from '../Home/Contacts';
import Azienda from '../Home/Azienda';
import Adesione from '../Home/Adesione';
import Rider from '../Home/Rider';
import Thanks from '../Home/Thanks';
import Faq from '../Home/Faq';
import Shop from '../Shop/Shop';
import Location from '../Location/Location';
import SingleLocation from '../Location/SingleLocation';
import Cart from '../Cart/Cart';
import YourOrder from '../Cart/Order';
import Register from '../Login/Register';
import Login from '../Login/Login';
import NotFound from './NotFound';

import Docs from '../../components/docs/Docs';
import LocationDocs from '../../components/docs/LocationDocs';
import ShopDocs from '../../components/docs/ShopDocs';
import CartDocs from '../../components/docs/CartDocs';
import UserDocs from '../../components/docs/UserDocs';
import OrderDocs from '../../components/docs/OrderDocs';
import MapDocs from '../../components/docs/MapDocs';

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-59R6Q9F',
    dataLayerName: 'pageview'
}
 
TagManager.initialize(tagManagerArgs);


const App = () => (
  <StoreProvider>
    <Header />
    <Router primary={false}>
      <ScrollToTop path="/">
        <NotFound default />
        <Home path="/" />
        <Register path="register" />
        <Login path="login" />
        <Cart path="cart" />
        <YourOrder path="your-order/:orderId" />
        <Faq path="supporto" />
        <Contacts path="contatti" />
        <Azienda path="azienda" />
        <Adesione path="adesione" />
        <Rider path="rider" />
        <Thanks path="thankyou/:page" />
        <Privacy path="privacy-policy" />
        <Copyright path="copyright" />
        <Cookie path="cookie-policy" />
        <SingleLocation path=":zoneCode" /> 
        <Location path=":zoneCode/:catCode" /> 
        <Shop path=":zoneCode/:catCode/:shopName/:shopID" /> 
        <Shop path=":zoneCode/:catCode/:shopName/:shopID/:hash" /> 
        <Docs path="docs">
          <LocationDocs path=":zoneCode" />
          <ShopDocs path="bibione-spiaggia/shop/:shopName" />
          <CartDocs path="cart" />
          <UserDocs path="user" />
          <OrderDocs path="order" />
          <MapDocs path="map" />
        </Docs>
      </ScrollToTop>
    </Router>
    <Footer />
  </StoreProvider>
);

export default App;