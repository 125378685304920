class Subcategory {

    /**
     * Costruttore
     * @param {Object} data 
     */
    constructor({ title, image, ...info }) {
        this.title = title;
        this.imageUrl = image;
        this.info = info;
    }

}

export default Subcategory;