console.pretty = (msg, data, type = "log") => {
    console.log(
        `%c👇 ${msg} 👇`, 
        "background: #3F51B5;color:#FFF;padding:5px;border-radius: 5px;line-height: 26px;"
    );
    console[type](data);
};

console.wtf = (msg) => {
    console.log(
        `%c😱 ${msg} 😱`,
        "background: #E9253E;color:#FFF;padding:5px;border-radius: 5px;line-height: 26px;"
    );
};

export default console;