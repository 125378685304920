import http, { BASE_URL } from './lib/http';
import Validator from './lib/validation/Validator';

class User {

    /**
     * Costruttore
     * @param {object} data 
     * @param {number} data.id 
     * @param {string} data.firstName 
     * @param {string} data.lastName 
     * @param {string} data.email 
     * @param {string} data.phone 
     * @param {Token} token 
     * @param {DeliveryAddress} deliveryAddress 
     * @param {string} note 
     * @param {boolean} isShop 
     * @param {string} pwd 
     */
    constructor({ id, firstName, lastName, email, phone, token, deliveryAddress, note, isShop, pwd }) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
        this.token = token;
        this.deliveryAddress = deliveryAddress;
        this.note = note;
        this.isShop = isShop;
        this.pwd = pwd;
    }

    /**
     * Metodo statico per ripristinare un oggetto User a partire da un oggetto
     * generico che ne contiene i dati
     * @param {object} data 
     * @param {Token} token 
     * @returns {User}
     */
    static make(data, token, deliveryAddress, note, isShop, pwd ) {
        return new User({
            ...data,
            token,
            deliveryAddress,
            note,
            isShop,
            pwd
        });
    }

    /**
     * Metodo statico per creare un nuovo utente
     * @param {object} data 
     * @returns {User}
     * @throws {Error}
     */
    static async create(data) {

        const requiredParams = [
            'firstName',
            'lastName',
            'email',
            'phone',
            'password',
        ];

        if (Validator.checkIf(data).hasAllRequiredParams(requiredParams).fails()) {
            throw new Error("Data is missing!");
        }

        let response;
        try {
            response = await http.post(`${BASE_URL}/register-user`, User.formatRequestData(data));
        } catch (error) {
            throw error;
        }

        return new User(User.parseResponse(response));
    }

    /**
     * Metodo statico per aggiornare l'account di un utente già registrato su 
     * mobile
     * @param {object} data 
     * @returns {User}
     * @throws {Error}
     */
    static async update(data) {

        const requiredParams = [
            'email',
            'password',
        ];

        if (Validator.checkIf(data).hasAllRequiredParams(requiredParams).fails()) {
            throw new Error("Data is missing!");
        }

        let response;
        try {
            response = await http.put(`${BASE_URL}/update-user-account`, data);
        } catch (error) {
            throw error;
        }

        return new User(User.parseResponse(response));
    }

    /**
     * Metodo statico per effettuare il login sul server ed ottenere i dati 
     * dell'utente
     * @param {object} credentials 
     * @param {string} credentials.email 
     * @param {string} credentials.password 
     * @param {Token} token
     * @returns {User}
     * @throws {Error} 
     */
    static async login(credentials, token, isShop = false) {

        const pwd = isShop ? credentials.password : null;
        
        const requiredParams = [
            'email',
            'password',
        ];

        if (Validator.checkIf(credentials).hasAllRequiredParams(requiredParams).fails()) {
            throw new Error("Data is missing!");
        }

        let response;
        try {
            response = await http.post(`${BASE_URL}/get-user`, credentials, {
                "Access-Token": token.get(), 
            });
        } catch (error) {
            throw error;
        }

        return new User({ ...User.parseResponse(response), token, isShop, pwd });
    }

    static async loginSocial(credentials) {

        let response;
        try {
            response = await http.post(`${BASE_URL}/social-login`, credentials);
        } catch (error) {
            throw error;
        }

        return new User({ ...User.parseResponse(response) });
    }

    static async logoutUser() {

        let response;
        try {
            response = await http.get(`${BASE_URL}/logout`);
        } catch (error) {
            throw error;
        }

        return response;
    }

    static setCookie(name, value, days) {
        var d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    /**
     * Esegue il logout eliminando contestualmente il token di accesso
     * @returns {boolean}
     * @throws {Error}
     */
    logout() {
        if (!this.token || !this.token.forget) {
            throw new Error("No token or invalid token");
        }

        return this.token.forget();
    }

    /**
     * Verifica se l'utente corrente è loggato o meno
     * @returns {boolean}
     */
    isLoggedIn() {
        return Boolean(this.token) && Boolean(this.token.get());
    }

    /**
     * Aggiunge le note dell'utente
     * @param {String} note 
     * @returns {this}
     * @throws {Error}
     */
    insertNote(note) {

        this.note = note;
        return this;
    }

    /**
     * Prepara il body della richiesta per la registrazione di un utente
     * @param {object} data 
     * @returns {object}
     */
    static formatRequestData(data) {
        const { 
            firstName: name, 
            lastName: surname, 
            email, 
            phone: phone_number, 
            password, 
        } = data;
        
        return {
            name,
            surname,
            email,
            phone_number,
            password,
        }
    }

    /**
     * Prepara i dati per la creazione di un utente a partire dalla risposta del server
     * @param {object} data 
     * @returns {object}
     */
    static parseResponse(data) {
        const {
            id, 
            name: firstName, 
            surname: lastName, 
            email, 
            phone_number: phone, 
        } = data;
        
        return {
            id,
            firstName,
            lastName,
            email,
            phone,
        }
    }

    /**
     * Invia al server i dati sull'indirizzo di consegna
     * @param {DeliveryAddress} deliveryAddress 
     * @returns {this}
     * @throws {Error}
     */
    setDeliveryAddress(deliveryAddress) {

        const requiredParams = [
            'address',
            'latitude',
            'longitude',
            'type',
        ];

        if (Validator.checkIf(deliveryAddress).hasAllRequiredParams(requiredParams).fails()) {
            throw new Error("Data is missing!");
        }

        if (Validator.isAValidDeliveryAddress(deliveryAddress).fails()) {
            throw new Error(`${deliveryAddress.type} is not a valid address type!`);
        }

        this.deliveryAddress = deliveryAddress;

        return this;
    }

    /**
     * Comunica al server l'indirizzo di consegna dell'ordine
     * @returns {this}
     * @throws {Error}
     */
    async confirmDeliveryAddress() {

        if (Validator.isAValidDeliveryAddress(this.deliveryAddress).fails()) {
            throw new Error("Not a valid delivery address!");
        }

        try {
            const deliveryAddressId = await http.post(`${BASE_URL}/set-user-address`, {
                user_id: this.id,
                latitude: this.deliveryAddress.latitude,
                longitude: this.deliveryAddress.longitude,
                address_type: this.deliveryAddress.type,
                address: this.deliveryAddress.address,
                address_name: this.deliveryAddress.address,
            }, {
                "Access-Token": this.token.get(), 
            });
            this.deliveryAddress.withId(deliveryAddressId);
        } catch (error) {
            throw error;
        }

        return this;
    }

}

export default User;