import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from '@reach/router';

import { Store } from '../../contexts/Store/Store';

import Alert from '../Utils/Alert';
import Payment, { CASH_PAYMENT, CARD_PAYMENT, POS_PAYMENT } from '../../api/Payment';
import Braintree from '../Cart/Braintree';

import { ReactComponent as PaymentIcon } from '../../assets/payment-icon.svg';
import Order from '../../api/Order';

import Category from '../../api/Category';
import TagManager from 'react-gtm-module'

const pushGTM = (comune, business, shop, id, ship_price, cart_total, carrello) => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview',
        'categoryComune': comune,
        'businessCategory': business,
        'shopName': shop,
        'transactionId': id,
        'transactionAffiliation': shop,
        'transactionTotal': cart_total + ship_price, 
        'transactionTax': 0,
        'transactionShipping': ship_price,
        'transactionProducts': carrello
    },
    dataLayerName: 'pageview'
});


const LoginButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Gotham Bold',
    fontSize: '1.125em',
    padding: '10px 100px',
    border: '1px solid',
    lineHeight: 1.5,
    display: 'block',
    marginLeft:'auto',
    marginRight:'auto',
    backgroundColor: '#009fe2',
    borderColor: '#009fe2',
    borderRadius: '30px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&:active': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
  },
})(Button);

const PaymentMethod = (props) => {
    const { state } = React.useContext(Store);
    const { user, cart, timeSlot, shipPrice, cartSubTotal, maxOrderCost, addressType } = props;
    const [payment, setPayment] = useState();
    const [paymentCode, setPaymentCode] = useState('');
    const [isPurchasing, setIsPurchasing] = useState(false);
    const [purchaseDone, setPurchaseDone] = useState(false);
    const [openAlertPurchaseCashError, setOpenAlertPurchaseCashError] = useState(false);
    const [openAlertMaxError, setOpenAlertMaxError] = useState(false);
    const [order, setOrder] = useState();
    const [cashAvailable, setCashAvailable] = useState(true);

    function handleCloseAlertError(){
        setOpenAlertPurchaseCashError(false);
    }
    function handleCloseMaxError(){
        setOpenAlertMaxError(false);
    }

    function setPaymentMethod(paymentMethod){
        setPaymentCode(paymentMethod);
        if(typeof user.isShop == 'undefined' || user.isShop === false){
            let els = document.getElementsByClassName('set-payment active');
            while (els[0]) {
                els[0].classList.remove('active');
            }
            document.getElementById(paymentMethod).classList.add('active');
        }
        const payment = Payment.init({
            user,
            method: paymentMethod,
        });
        setPayment(payment);
    }

    function payByCash(order, payment){
        setIsPurchasing(true);
        order.purchase(payment)
        .then((purchase) => {
            setPurchaseDone(purchase);
            if(!purchase) {
                setOpenAlertPurchaseCashError(true);
            }
            else setOpenAlertPurchaseCashError(false);
        })
        .catch((error) => {
            console.error(error);
            setIsPurchasing(false);
            setOpenAlertPurchaseCashError(true);
        });
    }

    useEffect(() => {
        if(cartSubTotal > maxOrderCost && addressType == 'RESTAURANT') {
            setCashAvailable(false);
            setOpenAlertMaxError(true);
        } else {
            setCashAvailable(true);
            setOpenAlertMaxError(false);
        }
    }, [cartSubTotal, maxOrderCost]);

    useEffect(() => {
        if(typeof user.isShop != 'undefined' || user.isShop === true){
            setPaymentMethod(CASH_PAYMENT);
        }
        Order.checkout({
            user,
            cart,
            timeSlot,
        }).then((order) => {
            setOrder(order);
            setOpenAlertPurchaseCashError(false);

            const dep = JSON.parse(state.shop.info.departments);
            let currentCat = [];
            let cartForTagmanager = [];
            Category.departments()
                .then((list) => {
                    currentCat = list.filter(function(cat) {
                        return cat.info.id_lang == dep[0];
                    });
                    state.cart.forEach((k) =>{
                        let optionsPrice = k.product.selectedOptions.reduce(
                            (sum, { price }) => price ? sum += price : sum,
                            0
                        );
                        let tot_price = k.product.price + optionsPrice;
                        cartForTagmanager.push({
                            'sku': k.product.id,
                            'name': k.product.title,
                            'category': k.product.info.subcategoryName,
                            'price': tot_price,
                            'quantity': k.counter 
                        });
                    });
                    pushGTM(state.zone.name, currentCat[0].info.name, state.shop.info.company_name, order.id, shipPrice, cartSubTotal, cartForTagmanager);
                })
                .catch((error) => {
                    console.log(error)
                });
        }).catch((error) => {
            console.error(error);
            setOpenAlertPurchaseCashError(true);
        });
    }, [user, cart, timeSlot]);

    return (
        <>
            <Alert open={openAlertMaxError} onClose={handleCloseMaxError} title="Attenzione" content={`Il totale dell'ordine supera il massimo consentito per il take away.`} />
            <Alert open={openAlertPurchaseCashError} onClose={handleCloseAlertError} title="Attenzione" content={`L'ordine non è andato a buon fine, si prega di riprovare.`} />
            
            {(typeof user.isShop == 'undefined' || user.isShop === false) &&
            <div className="delivery-container" style={{ paddingBottom: 0 }}>
                <span className="order-title">
                    <PaymentIcon className="order-icon" />
                    Metodo di pagamento
                </span>
                <Container className="cart-buttons-container">
                    <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <span className={paymentCode === CARD_PAYMENT ? "cart-buttons set-payment active" : "cart-buttons set-payment"} id={CARD_PAYMENT} onClick={() => setPaymentMethod(CARD_PAYMENT)}>
                                Carta di credito
                            </span>
                        </Grid>
                        {cashAvailable &&
                            <Grid item xs={12} sm={6}>
                                <span className={paymentCode === CASH_PAYMENT ? "cart-buttons set-payment active" : "cart-buttons set-payment"} id={CASH_PAYMENT} onClick={() => setPaymentMethod(CASH_PAYMENT)}>
                                    Contanti
                                </span>
                            </Grid>
                        }
                        {user.isShop === true &&
                            <Grid item xs={12} sm={6}>
                                <span className={paymentCode === POS_PAYMENT ? "cart-buttons set-payment active" : "cart-buttons set-payment"} id={POS_PAYMENT} onClick={() => setPaymentMethod(POS_PAYMENT)}>
                                    POS
                                </span>
                            </Grid>
                        }
                    </Grid>
                </Container>
            </div>
            }
            
            {
                payment && payment.method === CARD_PAYMENT && (
                    <>
                    {
                        order ? (
                            <Braintree order={order} payment={payment} />
                        ) : (
                            <p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>
                        )
                    }
                    </>
                ) 
            }
            {
                payment && (payment.method === CASH_PAYMENT || payment.method === POS_PAYMENT) && (
                    <>
                    {
                        (order && !isPurchasing) ? (
                            <LoginButton variant="contained" color="primary" style={{ marginTop: 50 }} disableRipple onClick={() => payByCash(order, payment)}>INVIA ORDINE</LoginButton>
                        ) : (
                            <p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>
                        )
                    }
                    </>
                )
            }
            {
                purchaseDone ? (
                    <Redirect noThrow to={`/your-order/${order.id}`} />
                ) : (
                    <Alert open={openAlertPurchaseCashError} onClose={handleCloseAlertError} title="Attenzione" content={`L'ordine non è andato a buon fine, si prega di riprovare.`} />
                )
            }
        </>
    )
}

export default PaymentMethod;