import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import { Link, Redirect } from '@reach/router';
import { ReactComponent as Onda } from '../../assets/onda.svg';
import aziendaImg from '../../assets/azienda.jpg';
import riderImg from '../../assets/rider.jpg';
import contactsImg from '../../assets/contatti.jpg';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const Thanks = (props) => {
    const { page } = props;

    let bg = '';
    let bp = 'center center';

    switch(page) {
        case 'azienda':
            bg = aziendaImg;
            bp = 'center center';
            break;
        case 'rider':
            bg = riderImg;
            bp = 'center top';
            break;
        case 'registrazione':
        case 'contatti':
        default:
            bg = contactsImg;
            bp = 'center center';
            break;
    }

    React.useEffect(() => {
        pushGTM();
    }, []);

    return(
        <>
            <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!">
                <title>Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!</title>
                <meta name="description" content="Sei a casa e non ti va di cucinare? Devi fare la spesa ma non vuoi perdere tempo facendo lunghe code? Ti sei dimenticato di passare in farmacia? Visita il sito Rivemo e ordina tutto ciò che vuoi! Puoi ricevere i tuoi prodotti comodamente a domiclio oppure ritirarli in negozio all’orario che preferisci" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-shop normal-page" style={{ backgroundImage: `url(${bg})`, backgroundPosition: `${bp}` }}>
                <Onda className="onda" />
            </div>
            <div className="generic">
                <Container style={{ maxWidth: '1200px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                    {page === 'registrazione' ? (
                        <div style={{ textAlign: 'center', padding: '100px 0 200px' }}>
                            <span className="thank-you-text1">Grazie per esserti registrato</span>
                            <span className="thank-you-text2">Ora puoi effettuare il login <Link to="/login"><u>cliccando qui</u></Link></span>
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center', padding: '100px 0 200px' }}>
                            <span className="thank-you-text1">Grazie per aver compilato il form</span>
                            <span className="thank-you-text2">Ti contatteremo nel più breve tempo possibile</span>
                        </div>
                    )}
                </Container>
            </div>
        </>
    )
}

export default Thanks;