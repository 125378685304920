import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import { navigate } from '@reach/router';
import smoothscroll from 'smoothscroll-polyfill';
import { ReactComponent as MapIcon } from '../../assets/map-icon.svg';

import { Store } from '../../contexts/Store/Store';
import Alert from '../Utils/Alert';

import ShowMapType from './ShowMapType';
import ChooseDayHour from './ChooseDayHour';
import PaymentMethod from './PaymentMethod';
import { HOME_DELIVERY, RESTAURANT_DELIVERY } from '../../api/DeliveryAddress';
import Order from '../../api/Order';
import { setUser } from '../../contexts/Store/actions';

const scrollToRef = (ref) => ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });

const OrderCart = (props) => {
    const { setShipCost, cartSubTotal } = props;
    const { state, dispatch } = React.useContext(Store);
    const [addressType, setAddressType] = useState(HOME_DELIVERY);
    const [userAddressValid, setUserAddressValid] = useState(false);
    const [hourValid, setHourValid] = useState(false);
    const [order, setOrder] = useState();
    const [openAlertError, setOpenAlertError] = useState(false);
    const [openShopError, setOpenShopError] = useState(false);
    const [timeSlot, setTimeSlot] = useState();
    const [delivery, setDelivery] = useState(false);
    const [takeaway, setTakeaway] = useState(false);
    const [shipPrice, setShipPrice] = useState(0);

    smoothscroll.polyfill();

    const cdh = React.useRef(null)
    const pm = React.useRef(null)

    const deliveryMode = state.shop.info.delivery_mode;
    const maxOrderCost = typeof state.shop.info.max_order_cost == 'number' ? state.shop.info.max_order_cost : 100;
    React.useEffect(() => {
        if(deliveryMode && deliveryMode !== null && deliveryMode !== '' ){
            deliveryMode.indexOf('DELIVERY') !== -1 ? setDelivery(true) : setDelivery(false);
            deliveryMode.indexOf('TAKEAWAY') !== -1 ? setTakeaway(true) : setTakeaway(false);
            if (deliveryMode.indexOf('DELIVERY') === -1 && deliveryMode.indexOf('TAKEAWAY') === -1) {
                setOpenShopError(true);
            } else {
                setOpenShopError(false);    
            }
        } 
    }, [state.shop.info]);

    React.useEffect(() => {
        if(userAddressValid){
            scrollToRef(cdh);
        }
        if(hourValid){
            scrollToRef(pm);
        }
    }, [userAddressValid, hourValid]);

    React.useEffect(() => {
        Order.getShippingPrice(addressType, state.shop.info.id_catalog)
            .then((ship_price) => {
                setShipCost(ship_price);
                setShipPrice(ship_price);
                setOpenAlertError(false);
            })
            .catch((error) => {
                setOpenAlertError(true);
            });
    }, [addressType]);

    function setOrderData(o){
        setOrder(o);
    }

    function setUserAddress(isValid){
        setUserAddressValid(isValid);
        if(!isValid){
            setHourValid(false);
        }
    }

    function insertNote(e) {
        const { user } = state;
        user.insertNote(e.target.value);
    }

    function setHourIsValid(b){
        setHourValid(b);
    }

    function handleCloseAlertError() {
        setOpenAlertError(false);
    }
    function handleCloseShopError() {
        navigate('/');
        setOpenShopError(false);
    }

    function getTimeSlot(timeSlot) {
        setTimeSlot(timeSlot);
        setHourValid(true);
    }
    function setDeliveryAddress({ user }, deliveryAddress) {
        if(typeof user.id == 'undefined'){
            return;
        }

        try {
            user.setDeliveryAddress(deliveryAddress); 
            setOpenAlertError(false);
        } catch (error) {
            setOpenAlertError(true);
        }

        dispatch(setUser({ user }));
    }

    return (
        <>
        <Alert open={openShopError} onClose={handleCloseShopError} title="Attenzione" content={`L'attività non è al momento disponibile.`} />
        <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Si è verificato un problema nella creazione dell'ordine.`} />
        <div className="delivery-container">
            <span className="order-title">
                <MapIcon className="order-icon" />
                Luogo di consegna
            </span>
            {delivery && takeaway && (<span className="order-subtitle">Scegli tra la consegna a domicilio e il ritiro di persona.</span>)}
            <Container className="cart-buttons-container">
                <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                    {delivery && 
                    <Grid item xs={12} sm={6}>
                        <span className={addressType === HOME_DELIVERY ? "cart-buttons address-type active" : "cart-buttons address-type"} id={HOME_DELIVERY} onClick={() => setAddressType(HOME_DELIVERY)}>
                            <span>Consegna a domicilio</span><br className="mob" />
                            <NumberFormat value={state.shop.info.ship_cost} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} />
                        </span>
                    </Grid>
                    }
                    {/*<Grid item xs={12} sm={4}>
                        <span className="cart-buttons address-type" id={BEACH_DELIVERY} onClick={() => setAddressType(BEACH_DELIVERY)}>
                            <span>Consegna in spiaggia</span><br className="mob" />
                            <NumberFormat value={state.shop.info.ship_cost} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} />
                        </span>
                    </Grid>
                    */}
                    {takeaway && 
                    <Grid item xs={12} sm={6} onClick={() => setAddressType(RESTAURANT_DELIVERY)}>
                        <span className={addressType === RESTAURANT_DELIVERY ? "cart-buttons address-type active" : "cart-buttons address-type"} id={RESTAURANT_DELIVERY}>Ritiro di persona<br className="mob" /> gratis</span>
                    </Grid>
                    }
                </Grid>
            </Container>
            <ShowMapType 
                addressType={addressType} 
                setUserAddress={setUserAddress} 
                userAddressValid={userAddressValid} 
                onSelect={(deliveryAddress) => setDeliveryAddress(state, deliveryAddress)}
            />
            <Container className="cart-info-container" style={{ marginTop:'15px' }}>
                <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
                    <Grid item xs={12}>
                        <strong>Note</strong>
                        <div className="delivery-address">
                            <textarea name="note" onChange={(e) => { insertNote(e); }} placeholder="Invia richieste particolari, segnala intolleranze o allergie, aggiungi note utili per la consegna."></textarea>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <div ref={cdh}>
        {
            userAddressValid && 
                <ChooseDayHour 
                    order={order} 
                    addressType={addressType}
                    setOrderData={setOrderData} 
                    setHourIsValid={setHourIsValid}
                    onSelect={getTimeSlot} 
                />
            
        }
        </div>
        <div ref={pm}>
        {
            hourValid && 
                <PaymentMethod 
                    // order={order} 
                    // orderInfo={state}
                    addressType={addressType} 
                    cartSubTotal={cartSubTotal} 
                    shipPrice={shipPrice} 
                    maxOrderCost={maxOrderCost} 
                    user={state.user} 
                    cart={state.cart}
                    timeSlot={timeSlot} 
                />
            
        }
        </div>
        </>
    )
}

export default OrderCart;