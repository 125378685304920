import React, { useState, useEffect } from 'react';
import './docs.css';
import Zone from '../../api/Zone';
import Category from '../../api/Category';
import Shop from '../../api/Shop';
import console from './helpers';
// import Subcategory from '../../api/Subcategory';

const LocationDocs = (props) => {
    const { zoneCode } = props;
    const [categories, setCategories] = useState([]);
    const [zone, setZone] = useState();

    useEffect(() => {
        Zone.findByCode(zoneCode)
            .then((zone) => {
                console.pretty("zone", zone, "dir");
                setZone(zone);
                return Category.listForZone(zone)
            })
            .then((list) => {
                if (list.length === 0) {
                    return Promise.reject("Categorie non pervenute!");
                }

                console.pretty("categories", list, "table");
                setCategories(list);

                return list[0].loadSubcategories();
            })
            .then((category) => {
                console.pretty("category + subcategories", category, "dir");
                return Shop.listForCategory(category);
            })
            .then((shops) => {
                console.pretty("shop", shops[0], "dir");
            })
            .catch((error) => console.wtf(error));
    }, [zoneCode]);

    return (
        <div className={"code"}>
            <h1>Location docs</h1>
            <p>
                <span role="img" aria-label="wait">✋</span> 
                &nbsp;Apri la <strong>console</strong> per vedere alcuni esempi <em>live</em>&nbsp; 
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
            </p>
            <h3 id="api-zone">API Zone</h3>
            <p>Gli oggetti Zone hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>id</strong> id numerico della zona</li>
                <li><strong>name</strong> dicitura</li>
                <li><strong>code</strong> proprietà computata da usare negli URL</li>
            </ul>
            <p>e i seguenti metodi, tutti <strong>statici</strong>:</p>
            <ul>
                <li><strong>all()</strong> per l'elenco completo delle zone</li>
                <li><strong>findById(<em>id</em>)</strong> per cercare una zona a partire dall'id numerico</li>
                <li><strong>findByCode(<em>code</em>)</strong> per cercare una zona a partire dal codice che arriva dall'URL</li>
            </ul>
            <pre>
                <code>
                    {`
                        import Zone from '../../api/Zone';
                        ...
                        
                        // zoneCode arriva dalle props, ad es., bibione-spiaggia
                        const zone = Zone.findByCode(zoneCode);

                        // In questa pagina, ad es.
                        // ${JSON.stringify(zone)}
                    `}
                </code>
            </pre>
            <h3 id="api-category">API Category</h3>
            <p>Gli oggetti Category hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>title</strong> dicitura della categoria</li>
                <li><strong>imageUrl</strong> URL dell'immagine</li>
                <li><strong>info</strong> oggetto contenente vari dati da usare nelle comunicazione col server</li>
            </ul>
            <p>i seguenti metodi <strong>statici</strong>:</p>
            <ul>
                <li><strong>listForZone(<em>zone</em>)</strong> metodo <em>asincrono</em> per ottenere l'elenco completo delle categorie di prodotti presenti nella zona</li>
            </ul>
            <p>i seguenti metodi <strong>d'istanza</strong>:</p>
            <ul>
                <li><strong>getSubcategories()</strong> metodo <em>asincrono</em> per ottenere l'elenco completo delle sottocategorie</li>
                <li>
                    <strong>loadSubcategories() </strong> 
                    metodo <em>asincrono</em> per memorizzare l'elenco completo delle sottocategorie nella propriet&agrave; subcategories dell'oggetto
                </li>
            </ul>
            <pre>
                <code>
                    {`
                        import Category from '../../api/Category';
                        ...
                        
                        // posto che zone sia un oggetto di tipo Zone,
                        // categories sarà un array di oggetti Category
                        let categories;
                        Category.listForZone(zone)
                            .then((list) => {
                                categories = list;
                            });

                        // per ottenere le sottocategorie, ad es., della prima categoria
                        const subcategories = await categories[0].getSubcategories();

                        // ... oppure si possono caricare nella proprietà 'subcategories'
                        // dell'oggetto Category
                        await categories[0].loadSubcategories();
                        // ora sono accessibili in categories[0].subcategories

                        // Ad es., la prima categoria in questa pagina è
                        // ${JSON.stringify(categories[0])}

                        // le sue sottocategorie sono un array di oggetti Subcategory, ad es.:
                        // [
                        //    {
                        //        encodedTitle: "TWVudSBwcm9tbw==",
                        //        imageUrl: "http://rivemo-dev.dev.aleaweb.com/dati/img/clg/it/cat/menu-im729.jpg",
                        //        title: "Menu promo"
                        //    },
                        //    {
                        //        encodedTitle: "UGl6emU=",
                        //        imageUrl: "http://rivemo-dev.dev.aleaweb.com/dati/img/clg/it/cat/pizza-im391.jpg",
                        //        title: "Pizze"
                        //    },
                        //    ...
                        // ]
                        
                    `}
                </code>
            </pre>
            <h3 id="api-subcategory">API Subcategory</h3>
            <p>Gli oggetti Subcategory hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>title</strong> dicitura della sottocategoria</li>
                <li><strong>imageUrl</strong> URL dell'immagine</li>
                <li><strong>info</strong> oggetto contenente vari dati da usare nelle comunicazione col server</li>
            </ul>
            <p><strong>non</strong> prevede metodi</p>
            <h3 id="api-shop">API Shop</h3>
            <p>Gli oggetti Shop hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>id</strong> id numerico</li>
                <li><strong>code</strong> codice alfanumerico</li>
                <li><strong>name</strong> dicitura completa </li>
                <li><strong>info</strong> oggetto contenente ulteriori dati (ad es., gli orari d'apertura)</li>
            </ul>
            <p>i seguenti metodi <strong>statici</strong> utili in questo contesto:</p>
            <ul>
                <li><strong>listForCategory(<em>category</em>)</strong> metodo <em>asincrono</em> per ottenere l'elenco completo dei negozi di una certa categoria presenti nella zona</li>
                <li><strong>listForSubcategory(<em>subcategory</em>)</strong> metodo <em>asincrono</em> per ottenere l'elenco completo dei negozi di una certa sottocategoria presenti nella zona</li>
            </ul>
            <pre>
                <code>
                    {`
                        import Shop from '../../api/Shop';
                        ...
                        
                        // ponendo che category sia un oggetto di tipo Category,
                        // l'elenco di tutti gli shop della categoria nella zona
                        // si possono ottenere così:
                        let shops;
                        Shop.listForCategory(category)
                            .then((list) => {
                                shops = list;
                            });

                        // Si ottiene qualcosa del tipo:
                        // [
                        //    {
                        //        id: 16,
                        //        code: "PEPE",
                        //        name: "Peperoncino",
                        //        info: {...}
                        //    },
                        //    {
                        //        id: 29,
                        //        code: "VZSX9",
                        //        name: "Deliverando",
                        //        info: {...}
                        //    },
                        //    ...
                        // ]

                        // per cercare gli shop appartenenti ad una sottocategoria,
                        // occorre invocare il metodo listForSubcategory passandogli
                        // un oggetto Subcategory valido:
                        let shops;
                        Shop.listForSubcategory(subcategory)
                            .then((list) => {
                                shops = list;
                            });
                    `}
                </code>
            </pre>
        </div>
    );
};

export default LocationDocs;