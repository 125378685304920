import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link, Redirect } from '@reach/router';
import { ReactComponent as Onda } from '../../assets/onda.svg';

import { Store } from '../../contexts/Store/Store';

import ShopCart from '../../components/Cart/ShopCart';
import OpenStreetMap from '../../components/Map/OpenStreetMap';

import Order from '../../api/Order';

import './Cart.scss';

import Category from '../../api/Category';
import TagManager from 'react-gtm-module'

const pushGTM = (comune, business, shop, id, ship_price, cart_total, carrello) => TagManager.dataLayer({
    dataLayer: {
        'event': 'purchase',
        'categoryComune': comune,
        'businessCategory': business,
        'shopName': shop,
        'transactionId': id,
        'transactionAffiliation': shop,
        'transactionTotal': cart_total + ship_price, 
        'transactionTax': 0,
        'transactionShipping': ship_price,
        'transactionProducts': carrello
    },
    dataLayerName: 'pageview'
});

const LoginButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Gotham Bold',
    fontSize: '1.125em',
    padding: '10px 100px',
    border: '1px solid',
    lineHeight: 1.5,
    display: 'block',
    marginLeft:'auto',
    marginRight:'auto',
    backgroundColor: '#009fe2',
    borderColor: '#009fe2',
    borderRadius: '30px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&:active': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
  },
})(Button);

const YourOrder = (props) => {
    const { orderId } = props;
    const { state, dispatch } = React.useContext(Store);
    const user = state.user;
    const [markerPosition, setMarkerPosition] = React.useState([
        user.deliveryAddress ? parseFloat(user.deliveryAddress.latitude) : '0',
        user.deliveryAddress ? parseFloat(user.deliveryAddress.longitude) : '0'
    ]);
    const [shipCost, setShipCost] = React.useState(0);
    const [cartTotal, setCartTotal] = React.useState(0);
    const [cartSubTotal, setCartSubTotal] = React.useState(0);
    const [catRewrite, setCatRewrite] = React.useState("");
    const [catGTM, setCatGTM] = React.useState("");
    const [cartGTM, setCartGTM] = React.useState([]);

    const addressComposite = user.deliveryAddress ? user.deliveryAddress.address : '';
    const [deliveryPointProperties, setDeliveryPointProperties] = React.useState({
        latitude: user.deliveryAddress ? parseFloat(user.deliveryAddress.latitude) : '0',
        longitude: user.deliveryAddress ? parseFloat(user.deliveryAddress.longitude) : '0',
        address: addressComposite,
    });

    function getCartTotal({ total }) {
        setCartTotal(total);
    }
    function getCartSubTotal(subtotal) {
        setCartSubTotal(subtotal);
    }
    
    const addressType = state.user.deliveryAddress ? state.user.deliveryAddress.type : 'HOME';
    React.useEffect(() => {
        Order.getShippingPrice(addressType, state.shop.info.id_catalog)
            .then((ship_price) => {
                setShipCost(ship_price);

                const dep = JSON.parse(state.shop.info.departments);
                let currentCat = [];
                let cartForTagmanager = [];
                Category.departments()
                    .then((list) => {
                        currentCat = list.filter(function(cat) {
                            return cat.info.id_lang == dep[0];
                        });
                        state.cart.forEach((k) =>{
                            let optionsPrice = k.product.selectedOptions.reduce(
                                (sum, { price }) => price ? sum += price : sum,
                                0
                            );
                            let tot_price = k.product.price + optionsPrice;
                            cartForTagmanager.push({
                                'sku': k.product.id,
                                'name': k.product.title,
                                'category': k.product.info.subcategoryName,
                                'price': tot_price,
                                'quantity': k.counter 
                            });
                        });
                        setCatRewrite(currentCat[0].info.rewrite);
                        setCatGTM(currentCat[0].info.name);
                        setCartGTM(cartForTagmanager);
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, [addressType]);

    React.useEffect(() => {
        if(catGTM !== "" && cartGTM.length > 0 && cartSubTotal > 0) { 
            pushGTM(state.zone.name, catGTM, state.shop.info.company_name, orderId, shipCost, cartSubTotal, cartGTM);
        } 
    }, [cartSubTotal, catGTM, cartGTM]);

    if( typeof user.id !== 'undefined' && user.isLoggedIn() && state.cart.length > 0) {
        return(
            <>
                <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!">
                    <title>Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!</title>
                    <meta name="description" content="Sei a casa e non ti va di cucinare? Devi fare la spesa ma non vuoi perdere tempo facendo lunghe code? Ti sei dimenticato di passare in farmacia? Visita il sito Rivemo e ordina tutto ciò che vuoi! Puoi ricevere i tuoi prodotti comodamente a domiclio oppure ritirarli in negozio all’orario che preferisci" />
                    <meta name="format-detection" content="telephone=no" />
                </Helmet>
                <div className="top-location in-cart">
                    <Onda className="onda" />
                    <div className="shop-products thank-you">
                        <Container style={{ maxWidth: '600px', position: 'relative', zIndex:0, padding: '0 0 100px 0' }} fixed>
                            <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
                                <span className="thank-you-title">Grazie per il tuo acquisto!</span>
                                {(typeof user.isShop == 'undefined' || user.isShop === false) && <p>Il tuo ordine è già stato trasmesso all'attività.</p>}
                            </div>
                            <ShopCart
                                items={state.cart}
                                user={state.user}
                                shippingCost={shipCost}
                                dispatch={dispatch}
                                getCartSubTotal={getCartSubTotal}
                                onUpdate={getCartTotal}
                                position={'in-cart'}
                                thankYou={true}
                            />
                            <div style={{ textAlign: 'center', padding: '50px 0 35px' }}>
                                <p><strong>Appena il tuo ordine sarà pronto riceverai un’email e una notifica sull’app. </strong></p>
                            </div>
                            <Container className="cart-info-container typ">
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
                                    <Grid item xs={12}>
                                        <div className="info-beach-map-typ">Luogo di consegna: <strong>{deliveryPointProperties.address}</strong></div>
                                        <OpenStreetMap
                                            center={markerPosition}
                                            zoom={14}
                                            maxZoom={19}
                                            markers={[deliveryPointProperties]}
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                            {
                                (typeof user.isShop == 'undefined' || user.isShop === false) ? (
                                    <Link to="/"><LoginButton variant="contained" color="primary" disableRipple>EFFETTUA ALTRI ACQUISTI</LoginButton></Link>
                                ) : (
                                    catRewrite && <Link to={`/${state.shop.info['city'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w]/gi,' ').trim().replace(/\s+/g,'-')}/${catRewrite}/${state.shop.info['company_name'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w]/gi,' ').trim().replace(/\s+/g,'-').toLowerCase()}/${state.shop.id}/${state.user.email}%7C${state.user.pwd}`}><LoginButton variant="contained" color="primary" disableRipple>TORNA AL LISTINO</LoginButton></Link>
                                )
                            }
                        </Container>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                {window.history.length > 0 ? window.history.back() : <Redirect noThrow to="/" />}
            </>
        )
    }
}

export default YourOrder;