import React from 'react';
import Container from '@material-ui/core/Container';
import { Link } from '@reach/router';

import { Store } from '../../contexts/Store/Store';

import { ReactComponent as Onda } from '../../assets/onda.svg';
import Spesa from '../../assets/spesa.jpg';
import Casa from '../../assets/casa_giardino.jpg';
import Cibo from '../../assets/gastronomia.jpg';
import Riparazioni from '../../assets/manutenzioni.jpg';
import Salute from '../../assets/salute_benessere.jpg';
import Shopping from '../../assets/shopping.jpg';
import Phone from '../../assets/condizioni/phone.svg';

const TopShop = (props) => {
  const { state } = React.useContext(Store);
  const user = state.user;

  const { shop, zoneRewrite, zoneName, category } = props;
  const products_type = shop.info.tags.join(' / ');

  let imageTop = Spesa;
  switch(category.info.id_lang){
    default:
    case '81':
        imageTop = Spesa;
        break;
    case '66':
        imageTop = Casa;
        break;
    case '69':
        imageTop = Cibo;
        break;
    case '72':
        imageTop = Riparazioni;
        break;
    case '75':
        imageTop = Salute;
        break;
    case '78':
        imageTop = Shopping;
        break;
  }

  const shopInfoPhone = shop.info.phone.replace('_','');
  const shopInfoMobile = shop.info.mobile.replace('_','');
  const hasListino = shop.info.has_catalog != 1 && shop.info.product_list !== '' && shop.info.product_list != null;
  const hasPhone = shop.info.has_catalog != 1 && shopInfoPhone !== '' && shopInfoPhone !== null;
  const hasMobile = shop.info.has_catalog != 1 && shopInfoMobile !== '' && shopInfoMobile !== null;
  const shopInfoPhoneTemp = shopInfoPhone.split(',');
  const shopInfoMobileTemp = shopInfoMobile.split(',');
  const shopInfoPhoneFixed = shopInfoPhoneTemp[0];
  const shopInfoMobileFixed = shopInfoMobileTemp[0];

  return(
    <div>
      {(Array.isArray(shop.info.image) && shop.info.image[0] != null) ? (
        <div className="top-shop" style={{ backgroundImage: `url(${shop.info.image[0]})` }}>
          <Onda className="onda" />
          {
            (typeof user.isShop == 'undefined' || user.isShop === false) &&
            <div className="shop-back-container">
              <Link to="../../" className="shop-back">&lt; torna indietro</Link>
            </div>
          }
        </div>
      ) : (
        <div className="top-shop no-image" style={{ backgroundImage: `url(${imageTop})` }}>
          <Onda className="onda" />
          {
            (typeof user.isShop == 'undefined' || user.isShop === false) &&
            <div className="shop-back-container">
              <Link to="../../" className="shop-back">&lt; torna indietro</Link>
            </div>
          }
        </div>
      )}
      <div className="name-shop">
        <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0 }} fixed>
          <div className="name-shop-container">
            <div className="name-shop-desc">
              <h1>{shop.info.company_name}</h1>
              <h2>{products_type} che consegna a domicilio a {zoneName}</h2>
            </div>
            <div className="name-shop-link">
              {
                hasListino &&
                  <a href={shop.info.product_list} rel="noopener noreferrer" target="_blank" className="name-shop-url btn-listino">LISTINO</a>
              }
              {
                hasMobile ? (
                  <a href={`tel:${shopInfoMobileFixed}`} className="name-shop-url phone btn-shop-call"><img src={Phone} alt="Chiama" />&nbsp; CHIAMA</a>
                ) : (
                    hasPhone &&
                      <a href={`tel:${shopInfoPhoneFixed}`} className="name-shop-url phone btn-shop-call"><img src={Phone} alt="Chiama" />&nbsp; CHIAMA</a>
                )
              }
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default TopShop;