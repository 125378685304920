import React from 'react';

import OpenStreetMap from '../Map/OpenStreetMap';

const ShopMap = (props) => {
  const { shop, shopInfoUrlMaps } = props;

  return(
    <div>
    {
        ((shopInfoUrlMaps !== null && shopInfoUrlMaps.indexOf('embed')) === -1 && shop.info.georeferencing.lat !== null && shop.info.georeferencing.lng !== null) &&
            <OpenStreetMap
                center={[parseFloat(shop.info.georeferencing.lat), parseFloat(shop.info.georeferencing.lng)]}
                zoom={15}
                maxZoom={19}
                markers={[{
                latitude: shop.info.georeferencing.lat,
                longitude: shop.info.georeferencing.lng,
                address: `${shop.info.address}${(shop.info.city !== '' && shop.info.city !== null) ? ` - ${shop.info.city}` : null} ${(shop.info.province !== '' && shop.info.province !== null) ? `(${shop.info.province})` : null}`
                }]}
            />
    }
    {
        (shopInfoUrlMaps !== '' && shopInfoUrlMaps !== null) &&
            <>                     
            {shopInfoUrlMaps.indexOf('embed') !== -1 ? (
            <iframe src={shopInfoUrlMaps} width="100%" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" title="Mappa"></iframe>
            ) : (
            <p><a href={shopInfoUrlMaps} target="_blank" rel="noopener noreferrer"><strong>Visualizza su Google Maps</strong></a></p>
            )}
            </>
    }
    </div>
  )
}

export default ShopMap;