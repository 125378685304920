import { createReducer } from './helpers';
import { 
    addProduct, 
    removeProduct, 
    incrementProductCounter, 
    decrementProductCounter,
    resetCart,
    setShop,
    setZone,
    setUser,
    resetUser,
} from './actions';

export const stateReducer = createReducer({}, {
    [addProduct]: (state, action) => {
        const { payload: { counter = 1 } } = action;
        state.cart.push({ ...action.payload, counter });
    },
    [removeProduct]: (state, action) => {
        state.cart.splice(action.payload.index, 1);
    },
    [incrementProductCounter]: (state, action) => {
        const { payload: { index } } = action;
        if (state.cart[index]) {
            state.cart[index].counter += 1;
        }
    },
    [decrementProductCounter]: (state, action) => {
        const { payload: { index } } = action;
        if (state.cart[index] && state.cart[index].counter > 0) {
            state.cart[index].counter -= 1;
        }
    },
    [setShop]: (state, action) => {
        state.shop = action.payload.shop;
    },
    [resetCart]: (state) => {
        state.cart = [];
    },
    [setUser]: (state, action) => {
        state.user = action.payload.user;
    },
    [resetUser]: (state) => {
        state.user = {};
    },
    [setZone]: (state, action) => {
        state.zone = action.payload.zone;
    },
});
