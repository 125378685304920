import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Person from '@material-ui/icons/Person';
import PersonOutlined from '@material-ui/icons/PersonOutlined';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined';
import Email from '@material-ui/icons/EmailOutlined';
import Lock from '@material-ui/icons/LockOutlined';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link, Redirect } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'

import { ReactComponent as Onda } from '../../assets/onda.svg';

import Alert from '../../components/Utils/Alert';

import User from '../../api/User';

import './Login.scss';

const useStyles = makeStyles(theme => ({
  root : {
      marginBottom:'30px',
      paddingBottom:'0px',
      borderBottom:'1px solid #ccc'
  }
}));

const LoginButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Gotham Bold',
    fontSize: '1.125em',
    padding: '10px 50px',
    border: '1px solid',
    lineHeight: 1.5,
    display: 'block',
    marginTop:50,
    marginLeft:'auto',
    marginRight:'auto',
    backgroundColor: '#009fe2',
    borderColor: '#009fe2',
    borderRadius: '30px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&:active': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&:disabled': {
        backgroundColor: '#eee',
        borderColor: '#eee',
        color: '#999'
    },
  },
  
})(Button);

const Register = (props) => {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        showPassword: false,
        gdpr: 'no',
        formErrors: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            password: '',
        },
        firstNameValid: false,
        lastNameValid: false,
        phoneValid: false,
        emailValid: false,
        passwordValid: false,
        gdprValid: false,
        formValid: false,
        isRegistering: false,
        isRegistered: false,
    });
    
    const [openAlertRegistration, setOpenAlertRegistration] = React.useState(false);
    const [openAlertRegistrationError, setOpenAlertRegistrationError] = React.useState("");

    const handleChange = prop => event => {
        let fieldValidationErrors = values.formErrors;
        let firstNameValid = values.firstNameValid;
        let lastNameValid = values.lastNameValid;
        let emailValid = values.emailValid;
        let phoneValid = values.phoneValid;
        let passwordValid = values.passwordValid;
        let gdprValid = values.gdprValid;

        let eventValue = event.target.value;
        let eventChecked = event.target.checked;
        switch(prop) {
            case 'firstName':
                firstNameValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.firstName = firstNameValid ? '' : 'il campo nome è vuoto';
                break;
            case 'lastName':
                lastNameValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.lastName = lastNameValid ? '' : 'il campo cognome è vuoto';
                break;
            case 'phone':
                phoneValid = (/^\d+$/).test(eventValue);
                fieldValidationErrors.phone = phoneValid ? '' : 'il campo telefono non è valido (deve contenere solo numeri)';
                break;
            case 'email':
                emailValid = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(eventValue);
                fieldValidationErrors.email = emailValid ? '' : 'il campo email non è valido';
                break;
            case 'password':
                passwordValid = eventValue.length >= 6;
                fieldValidationErrors.password = passwordValid ? '': 'il campo password è troppo corto (almeno 6 caratteri)';
                break;
            case 'gdpr':
                eventValue = eventChecked ? 'si' : 'no';
                gdprValid = eventChecked;
                fieldValidationErrors.gdpr = gdprValid ? '': 'devi accettare i termini e le condizioni';
            default:
                break;
        }
        setValues({ 
            ...values, 
            [prop]: eventValue,
            formErrors: { ...fieldValidationErrors },
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            phoneValid: phoneValid,
            emailValid: emailValid,
            passwordValid: passwordValid,
            gdprValid: gdprValid,
            formValid: emailValid && passwordValid && firstNameValid && lastNameValid && phoneValid && gdprValid
        });
        
    };

    /* React.useEffect(() => {
        console.log(values.formErrors);
    }, [values]); */
    
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    function handleSubmit() {
        setValues({ ...values, formValid: false, isRegistering: true });

        User.create({
            firstName: values.firstName, 
            lastName: values.lastName, 
            email: values.email, 
            phone: values.phone, 
            password: values.password, 
        })
        .then((user) => {
            setValues({ ...values, isRegistered: true });
            setOpenAlertRegistration(false);
        })
        .catch(err => {
            setOpenAlertRegistrationError("L'email è già presente nel sistema");
            setOpenAlertRegistration(true);
            setValues({ ...values, formValid: true, isRegistering: false });
        });
    }

    function handleCloseAlert() {
        setOpenAlertRegistration(false);
    }

    return(
        <>
            <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!">
                <title>Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!</title>
                <meta name="description" content="Sei a casa e non ti va di cucinare? Devi fare la spesa ma non vuoi perdere tempo facendo lunghe code? Ti sei dimenticato di passare in farmacia? Visita il sito Rivemo e ordina tutto ciò che vuoi! Puoi ricevere i tuoi prodotti comodamente a domiclio oppure ritirarli in negozio all’orario che preferisci" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            {values.isRegistered && <Redirect noThrow to="/thankyou/registrazione" />}
            <Alert open={openAlertRegistration} onClose={handleCloseAlert} title="Attenzione" content={openAlertRegistrationError} />
            <div className="top-location in-cart">
                <Onda className="onda" />
                <div className="shop-products thank-you">
                    <Container style={{ maxWidth: '600px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                        <div style={{ textAlign: 'center', paddingBottom: '50px' }}>
                            <span className="thank-you-title">Registrati a Rivemo!</span>
                        </div>
                    </Container>
                    <form>
                        <Container style={{ maxWidth: '405px', position: 'relative', zIndex:0, padding: '0 0 100px 0' }} className="login-form" fixed>
                            <Grid container spacing={0} alignItems="flex-start" className={classes.root}>
                                <Grid item xs={1}>
                                    <Person style={{ opacity: 0.3, width:'1.3em', height:'1.3em' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <FormControl style={{ width:'100%', paddingLeft:15 }}>
                                        <InputLabel htmlFor="adornment-password" style={{ transform: 'translate(0, 8px) scale(1)' }}>Nome*</InputLabel>
                                        <Input 
                                            id="firstName"
                                            onChange={handleChange('firstName')}
                                            value={values.firstName}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} alignItems="flex-start" className={classes.root}>
                                <Grid item xs={1}>
                                    <PersonOutlined style={{ opacity: 0.3, width:'1.3em', height:'1.3em' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <FormControl style={{ width:'100%', paddingLeft:15 }}>
                                        <InputLabel htmlFor="adornment-password" style={{ transform: 'translate(0, 8px) scale(1)' }}>Cognome*</InputLabel>
                                        <Input 
                                            id="lastName"
                                            onChange={handleChange('lastName')}
                                            value={values.lastName}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} alignItems="flex-start" className={classes.root}>
                                <Grid item xs={1}>
                                    <PhoneOutlined style={{ opacity: 0.3, width:'1.3em', height:'1.3em' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <FormControl style={{ width:'100%', paddingLeft:15 }}>
                                        <InputLabel htmlFor="adornment-password" style={{ transform: 'translate(0, 8px) scale(1)' }}>Telefono*</InputLabel>
                                        <Input 
                                            id="Phone"
                                            onChange={handleChange('phone')}
                                            value={values.phone}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} alignItems="flex-start" className={classes.root}>
                                <Grid item xs={1}>
                                    <Email style={{ opacity: 0.3, width:'1.3em', height:'1.3em' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <FormControl style={{ width:'100%', paddingLeft:15 }}>
                                        <InputLabel htmlFor="adornment-password" style={{ transform: 'translate(0, 8px) scale(1)' }}>Email*</InputLabel>
                                        <Input 
                                            id="Email"
                                            onChange={handleChange('email')}
                                            value={values.email}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} alignItems="flex-start" className={classes.root}>
                                <Grid item xs={1}>
                                    <Lock style={{ opacity: 0.3, width:'1.3em', height:'1.3em' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <FormControl style={{ width:'100%', paddingLeft:15 }}>
                                        <InputLabel htmlFor="adornment-password" style={{ transform: 'translate(0, 8px) scale(1)' }}>Password*</InputLabel>
                                        <Input 
                                            id="Lock"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {values.showPassword ? <Visibility style={{ opacity: 0.5 }} /> : <VisibilityOff style={{ opacity: 0.5 }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl className="gdpr">
                                <input type="checkbox"
                                    id="gdpr"
                                    onChange={handleChange('gdpr')}
                                    value={values.gdpr}
                                />
                                <label htmlFor="gdpr"><span></span>Accetto i termini e le condizioni d’uso e dichiaro di aver letto <a href="/privacy-policy" target="_blank">l’informativa sul trattamento dei miei dati personali</a>.</label>
                            </FormControl>
                            <div className="form-errors">
                                {
                                    (values.gdprValid && values.phoneValid && values.emailValid && values.passwordValid && !values.formValid) && <p>Compila tutti i campi</p>
                                }
                                {
                                    !values.phoneValid && <p>{values.formErrors.phone}</p>
                                }
                                {
                                    !values.emailValid && <p>{values.formErrors.email}</p>
                                }
                                {
                                    !values.passwordValid && <p>{values.formErrors.password}</p>
                                }
                                {
                                    !values.gdprValid && <p>{values.formErrors.gdpr}</p>
                                }
                            </div>
                            {values.formValid ? <LoginButton variant="contained" color="primary" disableRipple onClick={handleSubmit}>REGISTRATI</LoginButton> : <LoginButton variant="contained" color="primary" disabled disableRipple>REGISTRATI</LoginButton>}
                            {values.isRegistering && <p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>}
                            <p style={{ textAlign: 'center', margin: '50px' }}>Hai già un account? <Link to="/login" className="login-link">Login</Link></p>
                        </Container>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Register;