class TimeSlot {

    /**
     * Costruttore 
     * @param {object} data 
     * @param {number} data.id 
     * @param {string} data.start 
     * @param {string} data.end 
     * @param {boolean} data.enabled 
     */
    constructor({ title, id, day, start, end, enabled }) {
        this.title = title;
        this.id = id;
        this.day = day;
        this.start = start;
        this.end = end;
        this.enabled = enabled;
    }

    /**
     * Metodo statico per creare un'istanza di TimeSlot dai dati provenienti
     * dal server
     * @param {object} data 
     * @param {number} data.id 
     * @param {string} data.time_start 
     * @param {string} data.time_end 
     * @param {boolean} data.enabled 
     * @returns {TimeSlot}
     */
    static parse(Title, { id, enabled, day, time_start, time_end }) {
        return new TimeSlot({
            title: Title,
            id,
            enabled,
            day,
            start: time_start,
            end: time_end,
        });
    }
}

export default TimeSlot;