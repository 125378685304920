import 'react-app-polyfill/ie11';

import React from 'react';
import { render } from 'react-dom';
import './index.scss';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';

/* import PiwikPro from '@piwikpro/react-piwik-pro';
PiwikPro.initialize('2f24ee86-53bb-4b16-a52e-12590fc59129', 'container-url'); */


render(
    <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
