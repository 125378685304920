import React from 'react'
import {Helmet} from "react-helmet"
import Container from '@material-ui/core/Container';

import { ReactComponent as Onda } from '../../assets/onda.svg';

import './Home.scss';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const Copyright = () => {

	React.useEffect(() => {
        pushGTM();
    }, []);;

    return (
        <div>
            <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!">
                <title>Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!</title>
                <meta name="description" content="Sei a casa e non ti va di cucinare? Devi fare la spesa ma non vuoi perdere tempo facendo lunghe code? Ti sei dimenticato di passare in farmacia? Visita il sito Rivemo e ordina tutto ciò che vuoi! Puoi ricevere i tuoi prodotti comodamente a domiclio oppure ritirarli in negozio all’orario che preferisci" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-location in-cart">
                <Onda className="onda" />
                <div className="shop-products thank-you">
                    <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                        <br className="mob" /><br className="mob" />Il presente documento contiene le condizioni legali per l'accesso e l'uso del sito internet www.rivemo.it e dell’applicazione mobile Rivemo. Accedendo al sito web e all’app, utilizzandoli o utilizzando i materiali in essi contenuti ci si impegna a rispettare le condizioni legali di seguito esposte.
			<br className="mob" /><br className="mob" />
			<b>1. Proprietà del sito web e dell’applicazione mobile</b><br className="mob" />
			Questo sito web e la relativa applicazione mobile sono di proprietà di RIVEMO SRLS, con sede legale in Via Dante n. 28 - 33085 Maniago (PN) - Italia, P.IVA P.IVA 01846830931.
			<br className="mob" /><br className="mob" />
			<b>2. Diritto d'Autore e diritto sul Marchio</b><br className="mob" />
			Questo sito web e la relativa applicazione mobile sono protetti dal diritto d'Autore come opera singola e/o composta e/o collettiva ai sensi delle leggi italiane e dell'Unione Europea in materia di diritto d'Autore, delle convenzioni internazionali e delle altre normative in materia di diritto d'Autore. Qualsivoglia uso non autorizzato dei materiali contenuti nel sito web e nell’applicazione mobile sono assolutamente proibiti. Il sito web e l’applicazione mobile potrebbero contenere documenti, immagini, software, informazioni e altri materiali di proprietà di terzi, come fotografie, pubblicità, clip filmati e musica. Qualsivoglia uso non autorizzato dei materiali contenuti nel sito web e nell’applicazione mobile sono assolutamente vietati, a meno che non sia stato ottenuto il consenso preventivo scritto da parte del proprietario dei materiali in oggetto. Tutti i diritti sono riservati.
			<br className="mob" /><br className="mob" />
			<b>3. Uso e accesso al sito web e all’applicazione mobile</b><br className="mob" />
			I contenuti e i materiali del sito web e dell’applicazione mobile sono destinati ad esclusivo uso personale e non commerciale. L'utente potrà scaricare o copiare i contenuti e altri materiali scaricabili visualizzati su questo sito web e sull’app solo per suo uso personale non commerciale, a condizione che si riconosca che i materiali coperti dal Diritto d'Autore presenti in essi sono protetti e a condizione che l'utente si impegni a rispettare tutti i diritti d'autore e gli altri diritti contenuti in questo sito web e nell’app. La copia o la memorizzazione di qualsivoglia dei contenuti per usi diversi da quelli personali e non commerciali è rigorosamente vietata a meno che non sia stato ottenuto il consenso preventivo scritto da parte di RIVEMO SRLS. RIVEMO SRLS potrà modificare, annullare o sospendere la totalità o qualsivoglia delle caratteristiche del sito web e dell’app in qualsivoglia momento, compresa la disponibilità della totalità o di qualsivoglia parte del sito web, dell’app, delle funzioni e dei relativi database. RIVEMO SRLS potrà inoltre correggere eventuali errori od omissioni in qualsivoglia parte del sito web e dell’app o imporre limitazioni su determinate funzioni e servizi, o limitare l'accesso degli utenti a parti o alla totalità del sito web e dell’app senza obbligo di notifica e senza che questo configuri alcuna responsabilità.
			<br className="mob" /><br className="mob" />
			<b>4. Privacy Policy</b><br className="mob" />
			I dati dell’utente raccolti attraverso il sito web e l’applicazione mobile sono trattati da RIVEMO SRLS nel rispetto della vigente normativa in materia di protezione dei dati personali secondo quanto specificato nell’apposita pagina Privacy.
			<br className="mob" /><br className="mob" />
			<b>5. Diniego di garanzia e limitazione di responsabilità</b><br className="mob" />
			L'utente riconosce di utilizzare il sito web e l’applicazione mobile a proprio rischio. Il servizio viene fornito nello stato in cui si trova e RIVEMO SRLS nega esplicitamente qualsivoglia garanzia, esplicita e implicita comprese, a mero titolo esemplificativo, garanzie di accuratezza, affidabilità, proprietà, non violazione, idoneità per fini specifici o qualsivoglia altra garanzia condizione, assicurazione o dichiarazione, orale, scritta o in formato elettronico, compresa, a mero titolo esemplificativo, qualsivoglia garanzia sull'accuratezza o la completezza di qualsivoglia informazione contenuta nel sito web e nell’app o fornita dal sito/app. RIVEMO SRLS non dichiara e non garantisce che l'accesso al sito web e all’app non sarà interrotto o che il sito web e l’app non conterranno malfunzionamenti, errori o omissioni o perdite di informazioni trasmesse, o che nessun virus sarà trasmesso sul sito web/app. RIVEMO SRLS non sarà responsabile nei confronti degli utenti del sito/dell’app o di qualsivoglia terza parte per eventuali danni diretti, indiretti, speciali, consequenziali o punitivi presumibilmente sostenuti a seguito dell'accesso o dell'impossibilità di accedere al sito web e all’app, compreso il caso di virus presumibilmente ricevuti dal sito web, dall’app, l'uso o l'affidamento fatto sul sito web e sull’applicazione stessa o su qualsivoglia delle informazioni o dei materiali disponibili sul sito web e sull’app.
			<br className="mob" /><br className="mob" />
			<b>6. Assistenza clienti</b><br className="mob" />
			Per maggiori informazioni, suggerimenti, reclami e/o assistenza sui prodotti offerti nell’applicazione mobile Rivemo, il Cliente può contattare in ogni momento il servizio clienti di RIVEMO SRLS attraverso i seguenti canali:<br className="mob" />
			- modulo contatti disponibile nel sito web www.rivemo.it<br className="mob" />
			-  via e-mail all’indirizzo info@rivemo.app.
			<br className="mob" /><br className="mob" />
			<b>7. Condizioni generali di vendita</b><br className="mob" />
			<u>Prezzo dei prodotti e modalità di pagamento</u><br className="mob" />
			- Sul Sito viene indicato per ogni Prodotto il prezzo unitario di vendita espresso in Euro e comprensivo di tutte le imposte e tasse applicabili, al netto delle spese di spedizione che saranno evidenziate separatamente durante la procedura di acquisto.<br className="mob" />
			- I prezzi dei Prodotti indicati sul Sito potrebbero essere soggetti a variazioni nel corso del tempo. Il Cliente si impegna, pertanto, a verificare il prezzo finale di vendita di ciascun Prodotto prima di inviare il relativo modulo d'ordine.<br className="mob" />
			- Per il pagamento dei Prodotti acquistati e delle relative spese di spedizione, il Cliente può utilizzare le seguenti modalità:<br className="mob" />
			<em>·  Circuito PayPal;</em><br className="mob" />
			<em>·  Carte di credito o debito (bancomat) Discover, Maestro, Maestro UK, Mastercard, Visa, American Express;</em><br className="mob" />
			<em>·  In contanti alla consegna.</em><br className="mob" />
			<br className="mob" />
			<u>Esercizio del diritto di recesso</u><br className="mob" />
			- Il Cliente ha il diritto di recedere dal contratto concluso, gratuitamente e senza doverne giustificare i motivi, entro un termine di 14 giorni lavorativi a decorrere dal giorno dal ricevimento dei Prodotti.<br className="mob" />
			- Per esercitare il diritto di recesso, il Cliente può inviare comunicazione scritta ed esplicita della decisione di recedere a RIVEMO SRLS (all’indirizzo email info@rivemo.app), fornendo i suoi riferimenti (nome, cognome, indirizzo e-mail), le date d’ordine e di ricezione dei Prodotti, il numero d’ordine e i relativi Prodotti acquistati.<br className="mob" />
			- Il diritto di recesso non può essere esercitato nel caso in cui:<br className="mob" />
			<em>·  i Prodotti sigillati non si prestino ad essere restituiti per motivi igienici o connessi alla protezione della salute e che siano stati aperti dopo la consegna;</em><br className="mob" />
			<em>·  i Prodotti rischino di deteriorarsi o scadere rapidamente;</em><br className="mob" />
			<em>·  i Prodotti siano stati confezionati su misura o personalizzati.</em><br className="mob" />
			<br className="mob" />
			<u>Tempi e modalità di restituzione</u><br className="mob" />
			- I Prodotti oggetto di recesso devono essere restituiti a RIVEMO SRLS (indirizzo Piazzale Po 8, 30020 Bibione VE) con l’imballaggio originale e il relativo cartellino identificativo.<br className="mob" />
			- Per procedere con la restituzione, il Cliente deve rispedire i Prodotti oggetto di recesso, entro 14 giorni decorrenti dalla data in cui RIVEMO SRLS ha accettato la richiesta di recesso/restituzione.<br className="mob" />
			- Il pagamento dei costi diretti della restituzione dei Prodotti è a carico del Cliente.<br className="mob" />
			<br className="mob" />
			<u>Tempi e modalità di rimborso</u><br className="mob" />
			- Ricevuti i Prodotti, RIVEMO SRLS provvede alla verifica del rispetto delle condizioni e dei termini indicati nei precedenti punti (relativamente al diritto di recesso e a tempi e modalità di restituzione).<br className="mob" />
			- Nel caso in cui tali verifiche diano esito positivo, RIVEMO SRLS provvede ad inviare al Cliente, tramite e-mail, la conferma dell'accettazione dei Prodotti oggetto di recesso e procede al rimborso del prezzo di acquisto dei beni ricevuti dal Cliente.<br className="mob" />
			- Qualunque sia la modalità di pagamento utilizzata dal Cliente, il rimborso è attivato da RIVEMO SRLS, entro 14 giorni dalla data in cui il Venditore ha ricevuto la comunicazione di recesso. Il rimborso può essere sospeso da parte del Venditore fino al ricevimento dei Prodotti.<br className="mob" />
			- Il Venditore esegue il rimborso utilizzando lo stesso mezzo di pagamento usato dal Cliente per l’acquisto iniziale, salvo quest’ultimo non abbia espressamente convenuto altrimenti. Qualora non vi sia corrispondenza tra il destinatario dei Prodotti indicato nel modulo d'ordine e chi ha eseguito il pagamento delle somme dovute per il loro acquisto, il rimborso delle predette somme, sarà eseguito da RIVEMO SRLS, salvo diverso accordo, in favore del soggetto che ha eseguito il pagamento.<br className="mob" />
			- In ogni caso, il Cliente non dovrà sostenere alcun costo quale conseguenza del predetto rimborso.<br className="mob" />
			<br className="mob" />
			<b>8. Leggi applicabili e giurisdizione</b><br className="mob" />
			L'utente riconosce e a tutti i fini concorda che eventuali controversie, che dovessero derivare dall'accesso e/o dall'uso del sito web e dell’applicazione mobile, saranno soggette alle Leggi Italiane e dovranno essere sottoposte alla giurisdizione esclusiva di un Tribunale Italiano.
			<br className="mob" /><br className="mob" />
			<b>9. Modifiche</b><br className="mob" />
			Le norme precedenti potranno essere modificate da RIVEMO SRLS di volta in volta; le notifiche di eventuali revisioni di tali norme saranno annunciate su questa pagina. L'uso continuato del sito web e dell’applicazione mobile da parte dell'utente equivarrà all'accettazione da parte dell'utente stesso di tali modifiche o revisioni di tali norme; per questo motivo RIVEMO SRLS consiglia all'utente di visitare questa pagina regolarmente.
                        <br className="mob" /><br className="mob" />
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Copyright