import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
    removeProduct, 
    incrementProductCounter, 
    decrementProductCounter, 
} from '../../contexts/Store/actions';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 465,
        minHeight: 100,
    },
    // product: {
    //     verticalAlign: 'top',
    // },
}));

/**
 * Decora l'oggetto di tipo Product passato con il metodo getOptionsList()
 * che consente di visualizzare l'elenco delle opzioni selezionate
 * @param {Product} product
 * @returns {Product} 
 */
function withOptions(product) {

    product.getOptionsList = () => {
        
        if (product.selectedOptions.length === 0) {
            return;
        }

        return (
            <List>
                { 
                    product.selectedOptions.map((option, index) => (
                        <ListItem key={`${product.id}.${option.id}.${index}`}>
                            <ListItemText secondary={`+ ${option.title.toLowerCase()}`} />
                        </ListItem>
                    ))
                }
                { 
                    product.removedStandardOptions.map((option, index) => (
                        <ListItem key={`${product.id}.${option.id}.${index}`}>
                            <ListItemText secondary={`- ${option.title.toLowerCase()}`} />
                        </ListItem>
                    ))
                }
            </List>
        )
    };

    return product;
}

const Cart = (props) => {
    const { items, shippingCost, dispatch } = props;
    const [subtotal, setSubtotal] = useState(0);
    const classes = useStyles();

    useEffect(() => {
        const newSubtotal = props.items.reduce((result, item) => {
            const { counter, product } = item;
            result += counter * product.getTotalPrice();
            return result;
        }, 0);

        setSubtotal(newSubtotal);

        props.onUpdate({
            total: newSubtotal + props.shippingCost,
        });

        props.items.forEach((item, index) => {
            if (item.counter === 0) {
                props.dispatch(removeProduct({ index }));
            }
        });
    }, [props]);

    return (
        <Card className={classes.root}>
            <CardContent>
                <Table>
                    <TableBody>
                    {
                        items.length > 0 ? items.map((item, index) => {
                            
                            const product = withOptions(item.product);

                            return (
                                <TableRow key={`${product.id}.${index}`}>
                                    <TableCell>
                                        <IconButton 
                                            onClick={() => dispatch(decrementProductCounter({ index }))}
                                        >
                                            <RemoveCircleOutlineIcon />
                                        </IconButton>
                                        {item.counter}
                                        <IconButton 
                                            onClick={() => dispatch(incrementProductCounter({ index }))}
                                        >
                                            <AddCircleOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{product.title}{product.getOptionsList()}</TableCell>
                                    <TableCell align="right">{product.getTotalPrice()} &euro;</TableCell>
                                </TableRow>
                            );
                        }) : (
                            <TableRow>
                                <TableCell>Nessun prodotto selezionato!</TableCell>
                            </TableRow>
                        )
                    }
                        <TableRow>
                            <TableCell colSpan={2}>Subtotale</TableCell>
                            <TableCell align="right">{subtotal} &euro;</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>Costi di consegna</TableCell>
                            <TableCell align="right">{shippingCost} &euro;</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>Totale</TableCell>
                            <TableCell align="right">{subtotal + shippingCost} &euro;</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

Cart.propTypes = {
    items: PropTypes.array.isRequired,
    shippingCost: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

Cart.defaultProps = {
    items: [],
    shippingCost: 0,
    dispatch: () => undefined,
    onUpdate: () => undefined,
};

export default Cart;