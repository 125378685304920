import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from '@reach/router';

import { Store } from '../../contexts/Store/Store';

import appStore from '../../assets/app-store.png';
import playStore from '../../assets/play-store.png';
import appStore2x from '../../assets/app-store@2x.png';
import playStore2x from '../../assets/play-store@2x.png';

import FB from '../../assets/social/fb.svg';
import TW from '../../assets/social/tw.svg';
import WAP from '../../assets/social/wap.svg';

import Porfesr from '../../assets/azienda/logo_porfesr.png';
import PDF from '../../assets/azienda/Poster_rivemo_v3.pdf';

import './Footer.scss';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  transparent: {
    textAlign: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
  },
}));

const Footer = () => {
    const classes = useStyles();

    const { state } = React.useContext(Store);
    const user = state ? state.user : {};

    return (
        <>
        {(typeof user.isShop == 'undefined' || user.isShop === false) ? (
        <>
        <div className="prefooter">
            <Container style={{ maxWidth: '1200px', position: 'relative', zIndex:0, padding:0 }} fixed>
                <Grid container direction="row" justify="center" alignItems="stretch" spacing={0}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Paper className={classes.transparent}>
                            <div className="prefooter-text">
                                <span className="prefooter-title">Scarica la nostra app</span>
                                <a href="https://itunes.apple.com/us/app/rivemo/id1410851454?l=it&ls=1&mt=8" target="_blank" rel="noopener noreferrer"><img src={appStore} srcSet={`${appStore2x} 2x`} alt="App Store" className="app-icons ios" /></a>
                                <a href="https://play.google.com/store/apps/details?id=it.wedigital.rivemo" target="_blank" rel="noopener noreferrer"><img src={playStore} srcSet={`${playStore2x} 2x`} alt="Google Play Store" className="app-icons android" /></a>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className="share">
                        <Paper className={classes.transparent}>
                            <div className="prefooter-text">
                                <span className="prefooter-title">Fai conoscere Rivemo ai tuoi amici</span>
                                <a rel="noopener noreferrer" href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.rivemo.it%2F%3Futm_source%3DSitoweb_Rivemo%26utm_medium%3Dshared_link_SitowebRivemo%26utm_campaign%3DFai_conoscere_Rivemo%26utm_term%3Dit%26utm_content%3DFooter_icon_facebook" target="_blank" className="share-icon fb"><img src={FB} alt="facebook" height="23" /></a>
                                <a rel="noopener noreferrer" href="whatsapp://send?text=https%3A%2F%2Fwww.rivemo.it%2F%3Futm_source%3DSitoweb_Rivemo%26utm_medium%3Dshared_link_SitowebRivemo%26utm_campaign%3DFai_conoscere_Rivemo%26utm_term%3Dit%26utm_content%3DFooter_icon_whatsapp%20%0ASpesa%20online%2C%20negozi%20e%20ristoranti%20con%20consegna%20a%20domicilio%20-%20Rivemo%21" target="_blank" className="share-icon wap"><img src={WAP} alt="whatsapp" height="23" /></a>
                                <a rel="noopener noreferrer" href="https://twitter.com/share?url=https%3A%2F%2Fwww.rivemo.it%2F%3Futm_source%3DSitoweb_Rivemo%26utm_medium%3Dshared_link_SitowebRivemo%26utm_campaign%3DFai_conoscere_Rivemo%26utm_term%3Dit%26utm_content%3DFooter_icon_twitter&amp;text=Spesa%20online%2C%20negozi%20e%20ristoranti%20con%20consegna%20a%20domicilio%20-%20Rivemo%21" target="_blank" className="share-icon tw"><img src={TW} alt="twitter" height="23" /></a>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <footer>
            <Container style={{ maxWidth: '1200px', position: 'relative', zIndex:0, padding:0 }} fixed>
                {/*
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={0}>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Paper className={classes.transparent}>
                            <div className="footer-text">
                                <strong>Consegne domicilio Pordenone</strong><br className="mob" />
                                <span className="altre-voci">
                                    Gelato a domicilio Pordenone<br className="mob" />
                                    Pizzeria a domicilio Pordenone<br className="mob" />
                                    Ristoranti a domicilio Pordenone<br className="mob" />
                                    Spesa a domicilio Pordenone<br className="mob" />
                                    Supermercato a domicilio Pordenone<br className="mob" />
                                    Farmaci a domicilio Pordenone<br className="mob" />
                                    Fiori a domicilio Pordenone
                                </span>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Paper className={classes.transparent}>
                            <div className="footer-text">
                                <strong>Consegne domicilio Udine</strong><br className="mob" />
                                <span className="altre-voci">
                                    Gelato a domicilio Udine<br className="mob" />
                                    Pizzeria a domicilio Udine<br className="mob" />
                                    Ristoranti a domicilio Udine<br className="mob" />
                                    Spesa a domicilio Udine<br className="mob" />
                                    Supermercato a domicilio Udine<br className="mob" />
                                    Farmaci a domicilio Udine<br className="mob" />
                                    Fiori a domicilio Udine
                                </span>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Paper className={classes.transparent}>
                            <div className="footer-text">
                                <strong>Consegne domicilio Gorizia</strong><br className="mob" />
                                <span className="altre-voci">
                                    Gelato a domicilio Gorizia<br className="mob" />
                                    Pizzeria a domicilio Gorizia<br className="mob" />
                                    Ristoranti a domicilio Gorizia<br className="mob" />
                                    Spesa a domicilio Gorizia<br className="mob" />
                                    Supermercato a domicilio Gorizia<br className="mob" />
                                    Farmaci a domicilio Gorizia<br className="mob" />
                                    Fiori a domicilio Gorizia
                                </span>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Paper className={classes.transparent}>
                            <div className="footer-text">
                                <strong>Consegne domicilio Trieste</strong><br className="mob" />
                                <span className="altre-voci">
                                    Gelato a domicilio Trieste<br className="mob" />
                                    Pizzeria a domicilio Trieste<br className="mob" />
                                    Ristoranti a domicilio Trieste<br className="mob" />
                                    Spesa a domicilio Trieste<br className="mob" />
                                    Supermercato a domicilio Trieste<br className="mob" />
                                    Farmaci a domicilio Trieste<br className="mob" />
                                    Fiori a domicilio Trieste
                                </span>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                
                <div className="follow">
                    <strong>SEGUICI</strong> 
                    <a href="https://www.instagram.com/rivemoapp/" target="_blank" rel="noopener noreferrer" className="seguici instagram"><FontAwesomeIcon icon={faInstagram} size="2x" /> <span>Instagram</span></a>
                    <a href="https://www.facebook.com/rivemoapp/" target="_blank" rel="noopener noreferrer" className="seguici facebook"><FontAwesomeIcon icon={faFacebookSquare} size="2x" /> <span>Facebook</span></a>
                </div>
                */}
                <div className="footer-cols">
                    <div className="footer-col">
                        <strong>SEGUICI</strong>
                        <a href="https://www.instagram.com/rivemoapp/" target="_blank" rel="noopener noreferrer" className="seguici instagram"><FontAwesomeIcon icon={faInstagram} size="2x" /> <span>Instagram</span></a>
                        <a href="https://www.facebook.com/rivemoapp/" target="_blank" rel="noopener noreferrer" className="seguici facebook"><FontAwesomeIcon icon={faFacebookSquare} size="2x" /> <span>Facebook</span></a>
                    </div>
                    <div className="footer-col">
                        <strong>COLLABORA CON NOI</strong>
                        <Link to="/azienda">Iscrivi la tua attività</Link>
                        <Link to="/rider">Diventa rider</Link>
                    </div>
                    <div className="footer-col">
                        <strong>AIUTO</strong>
                        <Link to="/supporto">FAQ</Link>
                        <Link to="/contatti">Contattaci</Link>
                    </div>
                    <div className="footer-col">
                        <strong>NOTE LEGALI</strong>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/copyright">Termini d’uso</Link>
                        <Link to="/cookie-policy">Cookie Policy</Link>
                    </div>
                </div>
            </Container>
            <div className="copy">
                © Rivemo srls - Via Dante n. 28 - 33085 Maniago (PN) - P.IVA 01846830931<br className="mob" />
                <a href="https://www.neiko.it/" rel="noopener noreferrer" target="_blank">Digital Agency: <strong>Neiko</strong></a>
            </div>
            <div className="copy">
                <a href={PDF} rel="noopener noreferrer" target="_blank"><img src={Porfesr} /></a>
            </div>
        </footer>
        </>
        ) : (
            null
        )}
        </>
    )
}

export default Footer;