import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Onda } from '../../assets/onda.svg';

import { Store } from '../../contexts/Store/Store';
import CitiesMenu from '../../components/Location/CitiesMenu';
import FoodCategories from '../../components/Location/FoodCategories';

import './Location.scss';
import { setZone } from '../../contexts/Store/actions';
import Zone from '../../api/Zone';
import Category from '../../api/Category';
import withZone from '../../api/lib/withZone';

const Location = (props) => {
  const { zoneCode, catCode } = props;
  const [selectedZone, setSelectedZone] = useState();
  const [category, setCategory] = useState(null);
  const { dispatch } = useContext(Store);

  useEffect(() => {
      let currentCat = [];
      setCategory(null);
      if(selectedZone){
        Category.departments()
        .then((list) => {
            currentCat = list.filter(function(cat) {
              return cat.info.rewrite.toLowerCase() === catCode.toLowerCase();
            });
            setCategory(withZone(selectedZone)(new Category(currentCat[0].info)));
        })
        .catch((error) => {
            console.log(error)
        });
      }
  }, [catCode, selectedZone]);
  
  useEffect(() => {
    Zone.findByCode(zoneCode)
      .then((zone) => {
        setSelectedZone(zone);
        dispatch(setZone({ zone }));
      })
      .catch((error) => {
        console.error(error);
      })
  }, [zoneCode, dispatch]);

  return(
    <div>
      <div className="top-location">
        <Onda className="onda" />
        {selectedZone && category !== null && 
          <>
          <CitiesMenu currentZone={selectedZone} currentCat={category} />
          <FoodCategories zone={selectedZone} activeCategory={category} />
          </>
        }
      </div>
    </div>
  )
}

export default Location;