class Option {
    
    /**
     * Costruttore
     * @param {Object} data
     * @param {number} data.id          id numerico 
     * @param {string} data.title       dicitura
     * @param {number} data.price       prezzo
     * @param {...*}   info             dati restanti
     */
    constructor({ id, title, price = 0, ...info }) {
        this.id = id;
        this.title = title;
        this.price = price;
        this.info = info;
    }

    /**
     * Metodo statico per creare un'istanza di Option da un'altra istanza
     * @param {object} data
     * @param {number} data.id
     * @param {string} data.title
     * @param {number} data.price
     * @param {object} data.info
     * @returns {Option}
     */
    static make({ id, title, price, info }) {
        return new Option({
            id,
            title,
            price,
            ...info
        });
    }

}

export default Option;