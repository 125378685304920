import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Alert from '../Utils/Alert';
import ShopTabs from './ShopTabs';

const ShopInfo = (props) => {
  const { shop } = props;
  
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const [tabShown, setTabShown] = React.useState('info');

  function handleCloseAlertError(){
      setOpenAlertError(false);
  }
  function handleTab(tab){
      setTabShown(tab);
  }

  return(
    <div>
      <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Si è verificato un errore nel caricamento dei dati.`} />
      <div className="shop-info">
        <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0 }} fixed>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
            <Grid item xs={12} lg={12}>
                <ul>
                    <li className={tabShown === "info" ? "active" : ""} onClick={() => handleTab('info')}>INFO &amp; CONTATTI</li>
                    <li className={tabShown === "condizioni" ? "active" : ""} onClick={() => handleTab('condizioni')}>CONSEGNA</li>
                </ul>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="shop-products" style={{ position: 'relative' }}>
        {tabShown === "condizioni" &&
            <div className="fascia-consegna"></div>
        }
        <Container style={{ maxWidth: '1180px', position: 'relative' }} fixed>
          <ShopTabs shop={shop} tabShown={tabShown} />
        </Container>
      </div>
    </div>
  )
}

export default ShopInfo;