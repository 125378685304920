import React from 'react'
import {Helmet} from "react-helmet"
import Container from '@material-ui/core/Container';

import { ReactComponent as Onda } from '../../assets/onda.svg';

import './Home.scss';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const Cookie = () => {

	React.useEffect(() => {
        pushGTM();
    }, []);;

    return (
        <div>
            <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!">
                <title>Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!</title>
                <meta name="description" content="Sei a casa e non ti va di cucinare? Devi fare la spesa ma non vuoi perdere tempo facendo lunghe code? Ti sei dimenticato di passare in farmacia? Visita il sito Rivemo e ordina tutto ciò che vuoi! Puoi ricevere i tuoi prodotti comodamente a domiclio oppure ritirarli in negozio all’orario che preferisci" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-location in-cart">
                <Onda className="onda" />
                <div className="shop-products thank-you">
                    <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                        <br className="mob" /><br className="mob" /><p><strong>COOKIE POLICY</strong><br className="mob" />
			I cookie sono stringhe di testo di piccole dimensioni che i siti visitati dall'utente inviano al suo terminale (solitamente al browser), dove vengono memorizzati per essere poi ritrasmessi agli stessi siti alla successiva visita del medesimo utente. Si distinguono due macro-categorie di cookie, quelli "tecnici" e quelli "di profilazione".</p>
			<p><i>a. Cookie tecnici.</i><br className="mob" />
			I cookie tecnici possono essere suddivisi in cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web (permettendo, ad esempio, di autenticarsi per accedere ad aree riservate); cookie analytics, utilizzati per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso; cookie di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati (ad esempio la lingua) al fine di migliorare il servizio reso all’utente stesso.</p>
			<p><i>b. Cookie di profilazione.</i><br className="mob" />
			I cookie di profilazione sono volti a creare profili relativi all'utente e vengono utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dall’utente nell'ambito della navigazione in rete. </p>
			<p><strong>Nel presente sito web vengono utilizzati solo cookie tecnici.</strong><br className="mob" /> 
			L’utilizzo dei cookie tecnici non richiede il preventivo consenso dell’Utente ai sensi dell’art. 122 comma 1 del D.Lgs. 196/2003. Questi cookie possono abilitare alcune funzioni, senza le quali non sarebbe possibile utilizzare appieno il sito web e vengono trasferiti sul dispositivo dell’utente solo durante la sessione di utilizzo del browser.<br className="mob" />
			Questi cookie possono essere disattivati e/o eliminati attraverso le impostazioni del browser.<br className="mob" />
			Navigando il presente sito web l’utente potrebbe ricevere anche cookie di <strong>“terze parti”</strong> impostati direttamente da gestori di siti o da web server diversi dal presente, ed alla cui gestione si rimanda di seguito. Se l’Utente decide di non concedere l’autorizzazione per l’impiego di cookie di terze parti, potranno essere utilizzate esclusivamente le funzioni del sito che non richiedono tali cookie.</p>
			<p><strong>Tipologie di cookie</strong><br className="mob" />
			I cookie possono essere di differenti tipologie.<br className="mob" />Nel presente sito web vengono utilizzati cookie tecnici. L’utilizzo dei cookie tecnici non richiede il consenso dell’Utente ai sensi dell’art. 122 comma 1 del D.Lgs. 196/2003. Questi cookie possono abilitare alcune funzioni, senza le quali non sarebbe possibile utilizzare appieno il sito web e vengono trasferiti sul dispositivo dell’utente solo durante la sessione attuale del browser.<br className="mob" />Questi cookie possono essere disattivati e/o eliminati attraverso le impostazioni del browser. <br className="mob" />Navigando il presente sito web l’utente potrebbe ricevere anche cookie di “terze parti” impostati direttamente da gestori di siti web o server diversi dal presente Sito ed alla cui gestione si rimanda di seguito. Se l’Utente decide di non concedere l’autorizzazione per l’impiego di cookie di terze parti, potranno essere utilizzare esclusivamente le funzioni del Sito, che non richiedono tali cookie.</p>
			<p><strong>GESTIONE DEI COOKIE NEL BROWSER</strong><br className="mob" />
			Ciascun browser presenta procedure diverse per la gestione delle impostazioni, dei cookie e dei dati dei siti web. Maggiori informazioni sono disponibili ai link riportati di seguito:<br className="mob" />
			<strong>Chrome</strong>: <a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=it</a><br className="mob" />
			<strong>Firefox</strong>: <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a><br className="mob" />
			<strong>Internet Explorer</strong>: <a href="http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9" target="_blank" rel="noopener noreferrer">http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9</a><br className="mob" />
			<strong>Opera</strong>: <a href="http://help.opera.com/Windows/10.00/it/cookies.html" target="_blank" rel="noopener noreferrer">http://help.opera.com/Windows/10.00/it/cookies.html</a><br className="mob" />
			<strong>Safari</strong>: <a href="http://support.apple.com/kb/HT1677?viewlocale=it_IT" target="_blank" rel="noopener noreferrer">http://support.apple.com/kb/HT1677?viewlocale=it_IT</a></p>
			<p><strong>GESTIONE DEI COOKIE SOCIAL E DI TERZE PARTI</strong><br className="mob" />
			I social “plugin”, il cui utilizzo più comune è finalizzato alla condivisione dei contenuti di un sito web sui social network oppure all’autenticazione tramite il proprio account social, comportano la trasmissione di cookie di terze parti (quali Facebook, Twitter, Google plus, Linkedin, Pinterest, e simili). L’utente può visionare le informative relative alla gestione degli stessi dai seguenti link:<br className="mob" />
			<strong>Facebook</strong>: <a href="https://www.facebook.com/help/cookies/" target="_blank" rel="noopener noreferrer">https://www.facebook.com/help/cookies/</a><br className="mob" />
			<strong>Twitter</strong> : <a href="https://twitter.com/settings/security" target="_blank" rel="noopener noreferrer">https://twitter.com/settings/security</a><br className="mob" />
			<strong>Linkedin</strong>: <a href="https://www.linkedin.com/legal/cookie-policy" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/legal/cookie-policy</a><br className="mob" />
			<strong>Google+</strong>: <a href="http://www.google.it/intl/it/policies/technologies/cookies/" target="_blank" rel="noopener noreferrer">http://www.google.it/intl/it/policies/technologies/cookies/</a><br className="mob" />
			<strong>Pinterest</strong> <a href="https://about.pinterest.com/it/privacy-policy" target="_blank" rel="noopener noreferrer">https://about.pinterest.com/it/privacy-policy</a></p>
			<p><strong>GOOGLE ANALYTICS</strong><br className="mob" />
			Il presente sito web utilizza il sistema di monitoraggio dei dati di navigazione Google Analytics, un servizio di analisi del traffico web fornito da Google Inc., tramite il quale vengono trasmessi cookie di terze parti, raccolti e gestiti in forma aggregata per monitorare e migliorare le prestazioni del sito web.<br className="mob" />
			I dati generati da Google Analytics sono conservati da Google così come indicato nell’informativa reperibile al seguente link: <a href="https://support.google.com/analytics/answer/6004245?hl=it" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=it</a> </p>
                        <br className="mob" /><br className="mob" />
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Cookie