import React, { useState, useEffect, useContext } from 'react';
import './docs.css';
import { Store } from '../../contexts/Store/Store';
import { addProduct } from '../../contexts/Store/actions';
import productsData from '../../api/__tests__/fixtures/restaurant-products.json';
import accessoriesData from '../../api/__tests__/fixtures/product-accessories.json';
import Product from '../../api/Product';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Cart from '../../components/Cart/Cart';
import console from './helpers';
import Option from '../../api/Option';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 100,
    },
    card: {
        minWidth: 275,
        minHeight: 100,
    }
}));

function makeProducts(products, options) {
    return products.map((data, index) => {
        const product = new Product(data);
        if (index === 2) {
            [
                new Option(options.accessories[0]),
                new Option(options.accessories[1]),
            ].forEach((option) => {
                product.selectOption(option);
            });
            product.removeStandardOption(new Option(data.accessories[0]));
        }
        return withFullDescription(product);
    });
}

function withFullDescription(product) {
    if (product.selectedOptions.length > 0) {
        product.selectedOptions.forEach((option) => {
            product.description += ` + ${option.title.toLowerCase()}`;
        });
        product.removedStandardOptions.forEach((option) => {
            product.description += ` - ${option.title.toLowerCase()}`;
        });
    }

    return product;
}

const CartDocs = () => {
    const [cartTotal, setCartTotal] = useState(0);
    const [products] = useState(makeProducts(productsData.info, accessoriesData.info));
    const { state, dispatch } = useContext(Store);
    const classes = useStyles();

    useEffect(() => {
        if (state.cart && state.cart.length > 0) {
            console.pretty("cart", state.cart, "table");
        }
    }, [state.cart]);

    function getCartTotal({ total }) {
        setCartTotal(total);
    }

    return (
        <>
        <div className={"code"}>
            <h1>Cart docs</h1>
            <h3>API Cart</h3>
            <p>La gestione del carrello &egrave; implementata nel componente Cart.</p>
            <p>Cart si aspetta 4 props:</p>
            <ul>
                <li><strong>items</strong> array di oggetti strutturati come spiegato pi&ugrave; sotto nella sezione <a href="#api-store">API Store</a></li>
                <li><strong>shippingCost</strong> il costo di consegna richiesto dallo <a href="/docs/bibione-spiaggia/shop/peperoncino/#api-shop">Shop</a></li>
                <li><strong>dispatch</strong> metodo stile Redux per eseguire modifiche al contenuto del carrello</li>
                <li><strong>onUpdate</strong> callback con cui <em>estrarre</em> dati dal carrello. Permette al componente parent di ottenere il totale complessivo. La callback deve passare un oggetto con la propriet&agrave; <strong>total</strong> che verr&agrave; valorizzato da Cart.</li>
            </ul>
            <p>Un esempio di utlizzo del componente &egrave; il seguente:</p>
            <pre>
                <code>
                    {`
                        import { Store } from '../../contexts/Store/Store';
                        import { addProduct } from '../../contexts/Store/actions';
                        import Cart from '../../components/Cart/Cart';

                        const Restaurant = (props) => {
                            const { state, dispatch } = useContext(Store);
                            ...

                            function getCartTotal({ total }) {
                                // fai qualcosa con il totale
                            }

                            return (
                                <>
                                    <List>
                                        // Posto che product sia un oggetto di tipo Product,
                                        // un prodotto da aggiungere al carrello può essere
                                        // renderizzato come segue:
                                        <ListItem
                                            onClick={() => dispatch(addProduct({ product }))}
                                        >
                                            ...
                                        </ListItem>

                                        // Il click sul prodotto esegue il dispatching dell'azione
                                        // addProduct che di fatto aggiunge il relativo oggetto Product
                                        // a state.cart che verrà poi renderizzato da Cart
                                    </List>
                                    // Se shop è un oggetto di tipo Shop, il costo
                                    // di consegna è memorizzato nel campo ship_cost
                                    // della sua proprietà info
                                    <Cart
                                        items={state.cart}
                                        shippingCost={shop.info.ship_cost}
                                        dispatch={dispatch}
                                        onUpdate={getCartTotal}
                                    />
                                </>
                            );
                        };
                    `}
                </code>
            </pre>
            <h3 id="api-store">API Store</h3>
            <p>Il contenuto del carrello &egrave; memorizzato e condiviso fra componenti facendo uso delle API <a href="https://it.reactjs.org/docs/context.html" target="_blank" rel="noopener noreferrer">Context</a> di React.</p>
            <p>Per accedere al carrello si pu&ograve; far uso dell'apposito hook <a href="https://it.reactjs.org/docs/hooks-reference.html#usecontext" target="_blank" rel="noopener noreferrer">useContext</a> in questo modo:</p>
            <pre>
                <code>
                    {`
                        import { Store } from '../../contexts/Store/Store';

                        const Restaurant = (props) => {
                            const store = useContext(Store);
                            ...
                        };
                    `}
                </code>
            </pre>
            <p>L'oggetto store ha due propriet&agrave;:</p>
            <ul>
                <li><strong>state</strong> oggetto contenente lo stato dell'applicazione</li>
                <li><strong>dispatch</strong> metodo da invocare per modificare il contenuto del carrello</li>
            </ul>
            <p>state ha a sua volta le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>user</strong> oggetto <a href="/docs/user/#api-user">User</a> rappresentante l'utente correntemente loggato</li>
                <li><strong>zone</strong> oggetto <a href="/docs/bibione-spiaggia/#api-zone">Zone</a> rappresentante la zona correntemente selezionata</li>
                <li><strong>shop</strong> oggetto <a href="/docs/bibione-spiaggia/shop/peperoncino/#api-shop">Shop</a> rappresentante l'esercizio commerciale correntemente selezionato</li>
                <li><strong>cart</strong> array di oggetti con queste propriet&agrave;:
                    <ul>
                        <li><strong>product</strong> oggetto <a href="/docs/bibione-spiaggia/shop/peperoncino/#api-product">Product</a> rappresentante il prodotto ordinato</li>
                        <li><strong>counter</strong> quantit&agrave; ordinata</li>
                    </ul>
                </li>
            </ul>
            <p>Per modificare il contenuto del carrello, si invoca il metodo <i>dispatch</i> dello store passandogli una delle seguenti azioni:</p>
            <ul>
                <li><strong>addProduct(&#123; product &#125;)</strong> aggiunge un prodotto all'array <i>cart</i></li>
                <li><strong>removeProduct(&#123; index &#125;)</strong> rimuove dall'array <i>cart</i> il prodotto associato all'indice numerico passato</li>
                <li><strong>incrementProductCounter(&#123; index &#125;)</strong> incrementa di un'unit&agrave; la quantit&agrave; del prodotto associato all'indice numerico passato</li>
                <li><strong>decrementProductCounter(&#123; index &#125;)</strong> decrementa di un'unit&agrave; la quantit&agrave; del prodotto associato all'indice numerico passato</li>
                <li><strong>resetCart()</strong> svuota il carrello</li>
            </ul>
            <p>Ulteriori azioni possibili per lo store sono:</p>
            <ul>
                <li><strong>setShop(&#123; shop &#125;)</strong> per memorizzare l'esercizio commerciale</li>
                <li><strong>setUser(&#123; user &#125;)</strong> per memorizzare l'utente loggato</li>
                <li><strong>resetUser()</strong> per rimuovere l'utente</li>
            </ul>
            <pre>
                <code>
                    {`
                        import { Store } from '../../contexts/Store/Store';
                        import { 
                            addProduct, 
                            removeProduct, 
                            incrementProductCounter, 
                            decrementProductCounter,
                            resetCart, 
                        } from '../../contexts/Store/actions';

                        const Order = (props) => {
                            const store = useContext(Store);
                            
                            // posto che product sia un oggetto di tipo Product,
                            // si può aggiungerlo al cart in questo modo:
                            store.dispatch(addProduct({ product }));

                            // per rimuovere un prodotto dal carrello, occorre
                            // indicare la sua posizione nell'array:
                            store.dispatch(removeProduct({ index: 0 }));

                            // sempre attraverso l'indice numerico, è possibile
                            // aumentare o diminuire la quantità ordinata di un prodotto
                            store.dispatch(incrementProductCounter({ index: 2 }));
                            store.dispatch(decrementProductCounter({ index: 1 }));

                            // PS: decrementProductCounter() è implementato in modo
                            // da non consentire decrementi se la quantità è 0

                            // Per svuotare il carrello, si esegue l'azione resetCart:
                            store.dispatch(resetCart());
                        };
                    `}
                </code>
            </pre>
        </div>
        <div className={"code"}>
            <h2>Demo</h2>
            <p>
                <span role="img" aria-label="wait">✋</span> 
                &nbsp;Apri la <strong>console</strong> per vedere alcuni esempi <em>live</em>&nbsp; 
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
            </p>
        </div>
        <Grid container className={classes.root} spacing={2}>
            <Grid item>
                <List component="nav">
                    {
                        products && products.map((product) => (
                            <ListItem 
                                key={product.id}
                                onClick={() => dispatch(addProduct({ product }))}
                            >
                                <ListItemText 
                                    primary={product.title} 
                                    secondary={product.description}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </Grid>
            <Grid item>
                <Cart
                    items={state.cart}
                    shippingCost={3.5}
                    dispatch={dispatch}
                    onUpdate={getCartTotal} 
                />
            </Grid>
            <Grid item>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography color="textSecondary">
                            Totale <em>"esportato"</em> del cart 
                        </Typography>
                        <Typography variant="h1" component="h2">
                            {cartTotal} &euro;
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        </>
    );
};

export default CartDocs;