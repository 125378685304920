import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import HubspotForm from 'react-hubspot-form'
import smoothscroll from 'smoothscroll-polyfill';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'

import { ReactComponent as Onda } from '../../assets/onda.svg';
import aziendaImg from '../../assets/azienda.jpg';
import chisiamoImg from '../../assets/azienda/chi-siamo.svg';
import offriamoImg from '../../assets/azienda/cosa-offriamo.jpg';
import perche1 from '../../assets/azienda/perche-1.svg';
import perche2 from '../../assets/azienda/perche-2.svg';
import perche3 from '../../assets/azienda/perche-3.svg';
import perche4 from '../../assets/azienda/perche-4.svg';
import perche5 from '../../assets/azienda/perche-5.svg';
import perche6 from '../../assets/azienda/perche-6.svg';

import './hubspot-form.css';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const scrollToRef = (ref) => ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });


const Azienda = (props) => {

    smoothscroll.polyfill();

    const myRef = React.useRef(null)
    const executeScroll = () => scrollToRef(myRef)

    React.useEffect(() => {
        pushGTM();
    }, []);

    return(
        <>
            <Helmet defaultTitle="Iscrivi gratuitamente la tua azienda a Rivemo e apri il tuo canale di vendita online.">
                <title>Iscrivi gratuitamente la tua azienda a Rivemo e apri il tuo canale di vendita online.</title>
                <meta name="description" content="Entra in Rivemo! Iscrivi gratuitamente la tua attività e aumenta i tuoi incassi in modo rapido e semplice. Contattaci subito e scopri di più." />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-shop normal-page" style={{ backgroundImage: `url(${aziendaImg})` }}>
                <Onda className="onda" />
                <div className="top-text">
                    <h1>Apri il tuo canale di vendita online</h1>
                    <h2>Iscrivi gratuitamente la tua attività a Rivemo e aumenta i tuoi incassi.</h2>
                    <span className="top-btn" onClick={executeScroll}>UNISCITI A NOI</span>
                </div>
            </div>
            <div className="generic">
                <Container style={{ maxWidth: '1200px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                    <div className="chisiamo">
                        <img src={chisiamoImg} alt="Chi siamo" />
                        <div className="chisiamo-text">
                            <h3>Chi siamo</h3>
                            <p>Siamo un’impresa pordenonese specializzata nel fornire soluzioni e-commerce per varie categorie merceologiche. L’esperienza da noi accumulata in 2 anni di attività a Bibione e Lignano Sabbiadoro ci ha permesso di mettere a punto ogni aspetto relativo alle ordinazioni online a alle consegne a domicilio.</p>
                            <p>Vogliamo aiutare le piccole e medie imprese ad innovare le loro modalità di vendita grazie alle tecnologie, senza il bisogno di sostenere investimenti e costi di abbonamento.</p>
                        </div>
                    </div>
                </Container>
                <Container style={{ maxWidth: '100%', position: 'relative', zIndex:0, padding: '0' }} fixed>
                    <div className="numeri">
                        <div className="numeri-text">
                            <h3>6</h3>
                            <p>aree di prodotti e servizi</p>
                        </div>
                        <div className="numeri-text">
                            <h3>50+</h3>
                            <p>categorie merceologiche</p>
                        </div>
                        <div className="numeri-text">
                            <h3>1200+</h3>
                            <p>imprese locali</p>
                        </div>
                    </div>
                </Container>
                <Container style={{ maxWidth: '1200px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                    <div className="chisiamo">
                        <div className="chisiamo-text offriamo">
                            <h3 className="offriamo">Cosa offriamo</h3>
                            <p>Ti offriamo consulenza, promozione, servizi e tecnologie per aprire un nuovo canale di vendita attraverso internet e le app.</p>
                            <p>Tutti gli utenti della piattaforma, oltre ai tuoi clienti di sempre, potranno facilmente trovare la tua attività, scoprire la tua offerta e ordinare ciò che vogliono. Tu potrai gestire gli ordini e le consegna in modo semplice e organizzato grazie all’app Rivemo dedicata agli esercenti.</p>
                            <p>Tutti i pagamenti sono sicuri e trasparenti: i tuoi clienti pagheranno online con Paypal o carta di credito, oppure in contanti al ritiro in negozio. Sarai tu a scegliere la modalità che preferisci.</p>
                        </div>
                        <img src={offriamoImg} alt="Cosa offriamo" className="offriamo" />
                        <span className="top-btn marg-top" onClick={executeScroll}>ISCRIVI LA TUA ATTIVITÀ</span>
                    </div>
                </Container>
                <Container style={{ maxWidth: '100%', position: 'relative', zIndex:0, padding: '0' }} fixed>
                    <div className="perche-rivemo-container">
                        <h3>Perché scegliere Rivemo</h3>
                        <div className="perche-rivemo">
                            <div className="perche-rivemo-text">
                                <img src={perche1} alt="" />
                                <p>Maggior visibilità per la tua attività</p>
                            </div>
                            <div className="perche-rivemo-text">
                                <img src={perche2} alt="" />
                                <p>Tagli ai costi di pubblicità</p>
                            </div>
                            <div className="perche-rivemo-text">
                                <img src={perche3} alt="" />
                                <p>Semplificazione della gestione di ordini e consegne</p>
                            </div>
                            <div className="perche-rivemo-text">
                                <img src={perche4} alt="" />
                                <p>Maggiori garanzie per il tuo incasso</p>
                            </div>
                            <div className="perche-rivemo-text">
                                <img src={perche5} alt="" />
                                <p>Innovativo servizio “Prenota&amp;Ritira”</p>
                            </div>
                            <div className="perche-rivemo-text">
                                <img src={perche6} alt="" />
                                <p>Supporto logistico con i nostri fattorini</p>
                            </div>
                        </div>
                    </div>
                </Container>
                <Container ref={myRef} style={{ maxWidth: '600px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                    <div className="chisiamo cnt" id="section-form">
                        <div className="chisiamo-text full">
                            <h3 className="offriamo">Compila il form</h3>
                            <p>Inserisci i tuoi dati, ti contatteremo noi al più presto.</p><br className="mob" />
                        </div>
                        <div className="hbs-container">
                            <HubspotForm
                                portalId='6208112'
                                formId='9d096759-eff9-475b-b928-37bd4b98168c'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Azienda;