import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';

import { ReactComponent as Onda } from '../../assets/onda.svg';
import contactsImg from '../../assets/contatti.jpg';



const NotFound = () => {


    return(
        <>
            <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!">
                <title>Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!</title>
                <meta name="description" content="Sei a casa e non ti va di cucinare? Devi fare la spesa ma non vuoi perdere tempo facendo lunghe code? Ti sei dimenticato di passare in farmacia? Visita il sito Rivemo e ordina tutto ciò che vuoi! Puoi ricevere i tuoi prodotti comodamente a domiclio oppure ritirarli in negozio all’orario che preferisci" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-shop normal-page" style={{ backgroundImage: `url(${contactsImg})`, backgroundPosition: `center center` }}>
                <Onda className="onda" />
            </div>
            <div className="generic">
                <Container style={{ maxWidth: '1200px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                    <div style={{ textAlign: 'center', padding: '100px 0 200px' }}>
                        <span className="thank-you-text1">Siamo Spiacenti!</span>
                        <span className="thank-you-text2">Questa pagina non esiste</span>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default NotFound;