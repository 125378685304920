import React from 'react'
import {Helmet} from "react-helmet"
import Container from '@material-ui/core/Container';

import { ReactComponent as Onda } from '../../assets/onda.svg';

import './Home.scss';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const Privacy = () => {

    React.useEffect(() => {
        pushGTM();
    }, []);

    return (
        <div>
            <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!">
                <title>Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!</title>
                <meta name="description" content="Sei a casa e non ti va di cucinare? Devi fare la spesa ma non vuoi perdere tempo facendo lunghe code? Ti sei dimenticato di passare in farmacia? Visita il sito Rivemo e ordina tutto ciò che vuoi! Puoi ricevere i tuoi prodotti comodamente a domiclio oppure ritirarli in negozio all’orario che preferisci" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-location in-cart">
                <Onda className="onda" />
                <div className="shop-products thank-you">
                    <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                        <br className="mob" /><br className="mob" /><p><strong>PERCHÉ QUESTO AVVISO</strong></p>
                        <p>L’informativa è resa solo per il sito <em>www.rivemo.it</em> (di seguito anche semplicemente “sito”) e non anche per altri
                            siti web eventualmente consultati dall’utente tramite link presenti nel sito. L’informativa si ispira anche alla
                            Raccomandazione n. 2/2001 del 17 maggio 2001, alla Opinion n° 04/2012 on Cookie Consent Exemption del 7 giugno 2012 e
                            nel Working Document 02/2013 providing guidance on obtaining consent for cookies del 2 ottobre 2013 che le autorità
                            europee per la protezione dei dati personali, riunite nel Gruppo istituito ex art. 29 della direttiva n. 95/46/CE, hanno adottato per individuare alcuni requisiti minimi per la
                            raccolta di dati personali on-line, e, in particolare, le modalità, i tempi e la natura delle informazioni che i Titolari del trattamento devono
                            fornire agli utenti quando questi si collegano a pagine web, indipendentemente dagli scopi del collegamento.
                        </p>

                        <p><strong>IL TITOLARE DEL TRATTAMENTO</strong></p>
                        <p>A seguito della consultazione di questo sito possono essere trattati dati relativi a persone fisiche identificate o identificabili (<em>cd. interessati</em>).<br className="mob" />Il Titolare del loro trattamento è RIVEMO SRLS con sede legale in Via Dante n. 28 - 33085 Maniago (PN) - Italia, P.IVA 01846830931, nella persona del suo legale rappresentante.</p>

                        <p><strong>MODALITA’ E FINALITA’ DEL TRATTAMENTO</strong></p>
                        <p>Il trattamento dei dati sarà effettuato nel rispetto delle norme in vigore e dei principi di correttezza, liceità, trasparenza, pertinenza,
                        completezza e non eccedenza delle informazioni; inoltre i suddetti dati saranno raccolti e registrati solamente per le finalità di seguito indicate e conservati per un periodo strettamente necessario agli scopi medesimi.</p>

                        <p>Il trattamento potrà consistere nella raccolta, la registrazione, l’organizzazione, la conservazione, l’adattamento o la modifica,
                            l’estrazione, la consultazione, l’uso, la comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a
                            disposizione, la limitazione, la cancellazione o la distruzione dei dati e sarà effettuato con l’ausilio di strumenti elettronici idonei a
                            garantire l’integrità e la riservatezza dei dati stessi.</p>

                        <p>I soggetti o le categorie di soggetti che, nell’ambito delle finalità illustrate di seguito, potrebbero venire a conoscenza dei dati o a cui
                            potranno essere comunicati sono, in quanto coinvolti nell’organizzazione del sito, il personale interno ed anche soggetti esterni (fornitori, studi
                            e altri soggetti che forniscono assistenza e consulenza, ad esempio, legale, contabile, economico-finanziaria, tecnico-organizzativa, di
                            elaborazione dati; soggetti che forniscono servizi bancari, finanziari, assicurativi e di recupero crediti; società controllate, controllanti,
                            partecipate e collegate) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare.</p>

                        <p><em>I dati potranno altresì essere comunicati a Enti Pubblici, Forze di Polizia o altri Soggetti Pubblici e Privati, ma esclusivamente al fine di adempiere ad obblighi di legge, regolamento o normativa comunitaria.</em></p>

                        <p>Salvo diverse modalità esercitabili attraverso altre pagine del sito e per le quali valgono specifiche informative, i dati raccolti tramite il sito web vengono trattati per le seguenti finalità:</p>

                        <ul>
                            <li>a. dar corso alla prestazione dei servizi richiesti dagli utenti e l’evasione delle richieste di informazioni dell’utente;</li>
                            <li>b. fornire supporto agli utenti;</li>
                            <li>c. effettuare analisi statistiche (su base anonima), ai fini di migliorare l'offerta dei servizi;</li>
                            <li>d. per l’eventuale registrazione e gestione degli accessi al sito;</li>
                            <li>e. ottemperare agli obblighi di legge conseguenti ai predetti scopi;</li>
                            <li>f. rilevare l’indice di soddisfazione degli utenti in forma anonima.</li>
                        </ul>

                        <p>I dati potranno essere diffusi, ma solo in forma aggregata, anonima e per finalità statistiche.</p>

                        <p>Qualora il trattamento dovesse riguardare anche dati personali rientranti nel novero dei dati
                            "sensibili" (vale a dire dati idonei a rivelare
                            l'origine razziale ed etnica, le convinzioni religiose, filosofiche o di altro genere, le opinioni politiche, l'adesione a partiti, sindacati,
                            associazioni od organizzazioni a carattere religioso, filosofico, politico o sindacale, nonché i dati personali idonei a rivelare lo stato di salute,
                            la vita e l’orientamento sessuale) o “giudiziari” (dati relativi a condanne penali e reati) il trattamento sarà effettuato nei limiti indicati dalle
                            Autorizzazioni Generali del Garante e dai successivi provvedimenti equipollenti, secondo le modalità previste dal Regolamento UE 679/2016 e
                            per le finalità strettamente necessarie al regolare svolgimento dell’attività, delle operazioni relative all’erogazione di prodotti/servizi
                            ed all’adempimento di obblighi contrattuali e/o di legge/regolamento.</p>

                        <p><u>Si invita l’utenza ad evitare l’inserimento di dati sensibili e giudiziari superflui poiché ciò potrebbe comportare la distruzione del messaggio</u>.</p>

                        <p>I trattamenti connessi al servizio web (“contatti”) di questo sito hanno luogo presso la predetta sede e sono curati solo dal titolare.</p>

                        <p>A parte quanto specificato in seguito per i dati di navigazione, l’utente è libero di fornire i dati personali riportati negli eventuali moduli di
                            richiesta informazioni/candidatura/registrazione. Il loro mancato conferimento può comportare l’impossibilità di ottenere quanto richiesto.</p>

                        <p>I dati personali sono trattati con strumenti automatizzati per il tempo strettamente necessario a conseguire gli scopi per cui sono stati raccolti.<br className="mob" />
                            Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.</p>

                        <p><strong>PERIODO DI CONSERVAZIONE</strong></p>

                        <p>I dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati raccolti. Pertanto:</p>
                        <ul>
                            <li>I dati personali raccolti per scopi collegati all’esecuzione di un
                            contratto tra il Titolare e l’Utente saranno conservati sino alla completa
                            esecuzione di tale contratto ed anche successivamente per obbligo di legge
                            o per tutelare o far valere o difendere in sede giudiziaria un diritto del
                            titolare.</li>
                            <li>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può
                            conservare i dati personali più a lungo sino a quando detto consenso non
                            venga revocato. Inoltre il Titolare potrebbe essere obbligato a conservare
                            i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di
                            legge o per ordine di un’autorità.</li>
                            <li>Al termine del periodo di conservazione i dati personali saranno
                            cancellati. Pertanto, allo spirare di tale termine il diritto di accesso,
                            cancellazione, rettificazione ed il diritto alla portabilità dei dati non
                            potranno più essere esercitati.</li>
                        </ul><br className="mob" />

                        <p><strong>DIRITTI DEGLI INTERESSATI</strong></p>

                        <p>I soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento di ottenere la conferma dal titolare dell’esistenza o
                            meno di un trattamento che li riguarda, di chiedere l’accesso ai dati personali, di verificarne l’esattezza o chiederne la rettificazione, la
                            limitazione, la portabilità, di opporsi al trattamento(<a href="http://www.garanteprivacy.it/garante/doc.jsp?ID=1311248#art_7" rel="noopener noreferrer" target="_blank">Regolamento</a> UE 679/2016).</p>

                        <p>Le richieste vanno rivolte alla sede operativa di RIVEMO SRLS (Via Dante n. 28 - 33085 Maniago (PN) - Italia), o scrivendo a info@rivemo.it.</p>

                        <p><strong>LUOGO DI TRATTAMENTO DEI DATI</strong></p>

                        <p>I trattamenti connessi ai servizi <em>web</em> di questo sito hanno luogo principalmente presso la sede del titolare, nonché in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate.</p>

                        <p><strong>TIPI DI DATI TRATTATI</strong></p>

                        <p>Informazioni raccolte automaticamente</p>

                        <p>I sistemi informatici e le procedure <em>software</em> preposte al funzionamento di questo sito <em>web</em> acquisiscono, nel corso del loro
                            normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.</p>

                        <p>Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero,
                            attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti, i computer utilizzati dagli utenti
                            che si connettono al sito, gli indirizzi in notazione    <em>URI (Uniform Resource Identifier)</em> delle risorse richieste,
                            l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico
                            indicante lo stato della risposta data dal <em>server</em> (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e
                            all’ambiente informatico dell’utente.</p>

                        <p>Visitando il sito vengono raccolte automaticamente le seguenti informazioni:</p>
                        <ul>
                            <li>1. Hostname dell'utente. L'hostname o l'indirizzo di Internet Protocol
                            dell'utente che chiede accesso al sito.</li>
                            <li>2. Intestazione HTTP, e la stringa "<em>user agent</em>" che comprende: il
                            tipo e la versione di browser utilizzato e il sistema operativo con il
                            quale il browser stesso funziona.</li>
                            <li>3. System date. La data e il momento della visita dell'utente.</li>
                            <li>4. Richiesta completa. L'esatta richiesta formulata dall'utente.</li>
                            <li>5. Content lenght. La consistenza, in bytes, di ogni documento inviato
                            all'utente.</li>
                            <li>6. Metodo. La modalità di richiesta utilizzata.</li>
                            <li>7. Universal Resource Identifier (URI). La collocazione delle risorse nel
                            server.</li>
                            <li>8. La stringa di Richiesta della URI, ovvero tutto quello che si trova dopo
                            il punto di domanda nella URI.</li>
                            <li>9. Tipologia di dispositivo utilizzato per la consultazione del sito.</li>
                            <li>10. Protocollo. Il protocollo di trasmissione e la versione utilizzata.</li>
                        </ul><br className="mob" />

                        <p>
                            Le informazioni raccolte automaticamente da RIVEMO SRLS, tramite l'accesso al sito web, vengono utilizzate al fine di migliorare la qualità del
                            servizio offerto agli utenti del sito. Tali informazioni sono raccolte ai fini della realizzazione di indagini statistiche (anonime), per determinare
                            quali dati/contenuti siano più o meno utili/interessanti per gli utenti e poter quindi migliorare l'efficacia del materiale disponibile nel sito.</p>

                        <p><em>Dati forniti volontariamente dall’utente</em></p>

                        <p>L’invio facoltativo, esplicito e volontario di messaggi di posta elettronica agli indirizzi indicati su questo sito comporta la successiva
                            acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella
                            missiva.</p>

                        <p>A parte quanto specificato per le informazioni raccolte automaticamente, l’utente è libero di fornire i dati personali riportati nei moduli di
                            richiesta informazioni/candidatura/registrazione. Alcune informazioni sono indicate come obbligatorie, in quanto necessarie per soddisfare le
                            richieste espresse. Il loro mancato conferimento può comportare l’impossibilità di ottenere quanto richiesto.</p>

                        <p>Per la raccolta diretta di tali dati, sarà il visitatore a rilasciare i  propri dati personali e il relativo consenso al trattamento
                            in ordine ai singoli servizi e/o prestazioni richiesti ad RIVEMO SRLS
                            quali ad esempio: registrazione al sito web, invio di candidature, registrazioni per aree riservate, richiesta di informazioni, iscrizione a corsi ed eventi, newsletter o mailing list.<br className="mob" />
                                Il consenso prestato potrà essere in seguito revocato in qualsiasi momento.</p>

                        <p>Specifiche informative verranno progressivamente riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.</p>

                        <p><strong>COOKIES</strong></p>
                        <p><strong>1. Che cosa sono i Cookies?</strong></p>
                        <p>I cookie sono stringhe di testo di piccole dimensioni che i siti visitati dall'utente inviano al suo terminale (solitamente al browser), dove vengono memorizzati per essere poi ritrasmessi agli stessi siti alla successiva
                            visita del medesimo utente. Nel corso della navigazione su un sito, l'utente può ricevere sul suo terminale anche cookie che vengono inviati da
                            siti o da web server diversi (c.d. "terze parti"), sui quali possono risiedere alcuni
                            elementi (quali, ad esempio, immagini, mappe, suoni,
                            specifici link a pagine di altri domini) presenti sul sito che lo stesso sta visitando. I cookie, solitamente presenti nei browser degli utenti in
                            numero molto elevato e a volte anche con caratteristiche di ampia persistenza temporale, sono usati per differenti finalità: esecuzione di
                            autenticazioni informatiche, monitoraggio di sessioni, memorizzazione di informazioni su specifiche configurazioni riguardanti gli utenti che
                            accedono al server, ecc.</p>

                        <p><strong>2. Quali sono le principali tipologie di Cookies?</strong></p>
                        <p>Al riguardo, e ai fini del provvedimento n° 229/2014 del Garante Privacy, si individuano
                        pertanto due macro-categorie: cookie "tecnici" e cookie "di profilazione".</p>

                        <p><strong><em>a. Cookie tecnici. </em></strong><br className="mob" />
                            I cookie tecnici sono quelli utilizzati al solo fine di "effettuare la
                            trasmissione di una comunicazione su una rete di comunicazione elettronica,
                            o nella misura strettamente necessaria al fornitore di un servizio della
                            società dell'informazione esplicitamente richiesto dall'abbonato o
                            dall'utente a erogare tale servizio" (cfr. art. 122, comma 1, del Codice
                            Privacy). Essi non vengono utilizzati per scopi ulteriori e sono
                            normalmente installati direttamente dal titolare o gestore del sito web.
                            Possono essere suddivisi in <u>cookie di navigazione o di sessione</u>, che
                            garantiscono la normale navigazione e fruizione del sito web (permettendo,
                        ad esempio, di autenticarsi per accedere ad aree riservate);    <u>cookie analytics</u>, assimilati ai cookie tecnici laddove utilizzati
                            direttamente dal gestore del sito per raccogliere informazioni, in forma
                            aggregata, sul numero degli utenti e su come questi visitano il sito
                            stesso; <u>cookie di funzionalità</u>, che permettono all'utente la
                            navigazione in funzione di una serie di criteri selezionati (ad esempio, la
                            lingua) al fine di migliorare il servizio reso allo stesso. Per
                            l'installazione di tali cookie non è richiesto il preventivo consenso degli utenti.</p>

                        <p><strong><em>b. Cookie di profilazione. </em></strong><br className="mob" />
                            I cookie di profilazione sono volti a creare profili relativi all'utente e vengono utilizzati al fine di inviare messaggi pubblicitari in linea con le
                            preferenze manifestate dallo stesso nell'ambito della navigazione in rete.<br className="mob" />
                            In ragione della particolare invasività che tali dispositivi possono avere nell'ambito della sfera privata degli utenti, la normativa europea e
                            italiana prevede che l'utente debba essere adeguatamente informato sull'uso degli stessi ed esprimere così il proprio valido consenso. Il nostro sito
                            non utilizza cookie specifici volti a profilare l’utente.</p>

                        <p><strong><em>Session e Persistent Cookies. </em></strong></p>
                        <p>I <u>Session Cookies</u>, che contengono l’informazione che viene utilizzata nella tua sessione corrente del browser. Questi cookies vengono
                            automaticamente cancellati quando chiudi il browser. Nulla viene conservato sul tuo computer oltre il tempo di utilizzazione del sito.</p>

                        <p>I <u>Persistent Cookies</u>, che vengono utilizzati per mantenere
                            l’informazione che viene utilizzata nel periodo tra un accesso e l’altro al
                            sito web, o utilizzati per finalità tecniche e per migliorare la
                            navigazione sul sito. Questo dato permette ai siti di riconoscere che sei
                            utente o visitatore già noto e si adatta di conseguenza. I cookies
                            “persistenti” hanno una durata che viene fissata dal sito web e che può
                            variare da pochi minuti a diversi anni. Il nostro sito non utilizza questo
                            tipo di cookies. L’impostazione antitracciamento di qualunque browser non
                            inficia comunque la consultazione del nostro sito.</p>

                        <p><strong><em>Cookies di prima parte e di terze parti </em></strong><br className="mob" />
                            Occorre tenere conto del differente soggetto che installa i cookie sul
                            terminale dell'utente, a seconda che si tratti dello stesso gestore del
                            sito che l'utente sta visitando (c.d. “prima parte”) o di un sito diverso
                            che installa cookie per il tramite del primo (c.d. "terze parti"). I
                            Cookies di prima parte sono creati e leggibili dal sito che li ha creati. I
                            Cookies di terze parti, invece, sono creati e leggibili da domini esterni
                            al sito ed i cui dati sono conservati presso il soggetto terzo. Questo sito
                            utilizza cookie di prima parte di natura tecnica, per cui, come detto, non
                            è previsto il consenso, ma solo la presente informativa.</p>

                        <p>Per maggiori informazioni riguardo a questo tipo di pubblicità basata sui
                            gusti degli utenti, derivante dai cookies di terze parti, potete visitare i
                        siti dei terzi ai link sotto riportati. Agli indirizzi <a href="http://www.youronlinechoices.com/">www.youronlinechoices.com</a> e    <a href="http://www.aboutads.info/choices/">www
                        .aboutads.info/choices/</a>
                            troverete inoltre informazioni su come funziona la pubblicità
                            comportamentale e molte informazioni sui cookie oltre ai passi da seguire
                            per proteggere la Vostra privacy su internet.</p>

                        <p>Se volete saperne di più sui cookies e su come gestirli potete visitare anche il sito <a href="http://www.aboutcookies.org">www.aboutcookies
                        .org</a>.</p>

                        <p><em><u>Cookies di terze parti</u></em></p>

                        <p>Questo Sito utilizza <strong>Google Analytics</strong>, un servizio di analisi web fornito da
                            Google, Inc. ("Google"). Per maggiori informazioni è
                            possibile visitare la pagina di Google relativa all’utilizzo dei cookies per Analytics (<a href="https://support.google.com/analytics/answer/6004245">Link</a>).</p>

                        <p>Google Analytics utilizza i cookies per consentire di raccogliere e
                            analizzare in forma anonima le informazioni sui comportamenti di utilizzo
                            del sito da parte vostra (compreso il vostro indirizzo IP).
                            <strong>Il gestore del sito ha impostato Google Analytics in modo tale che sia anonimizzato parzialmente il Vostro indirizzo IP</strong>
                            . Tali informazioni vengono trasmesse a Google il quale le elabora allo
                            scopo di redigere report riguardanti le vostre attività sul sito web
                            stesso. Google comunque non associa il vostro indirizzo IP a nessun altro
                            dato posseduto da Google né cerca di collegare un indirizzo IP con
                            l'identità di un utente di computer. Ad ogni modo Google, per offrire ai
                            visitatori dei siti web la possibilità di impedire l'utilizzo dei propri
                            dati da parte di Google Analytics, ha sviluppato il componente aggiuntivo
                            del browser per la disattivazione del JavaScript di Google Analytics (
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=it">https://tools.google.com/dlpage/gaoptout?hl=it</a>).</p>

                        <p>Il Sito utilizza anche cookie di terze parti che consentono all’utente di interagire con il relativo social network, ad esempio, per la condivisione di contenuti sui social network.</p>

                        <p>Nel Sito potrebbero trovarsi infatti anche social buttons/widgets. Si
                            tratta in particolare delle icone dei social network, quali, ad esempio,
                            Facebook, Twitter, Instagram, Google+, che consentono agli Utenti di
                            raggiungere - per il tramite di un "click" sull’icona – i relativi social
                            network. Con il supporto di questi strumenti l’Utente può, ad esempio,
                            autenticarsi al proprio account social, condividere dei contenuti o
                            consigliare dei contenuti del Sito nei social network.</p>

                        <p>A seguito di click sui social buttons/widgets, il social network potrebbe
                            raccogliere i dati relativi alla visita dell’Utente sul Sito. Come
                            anticipato in premessa, la presente informativa privacy non inerisce il
                            trattamento dei dati dell’Utente da parte del social network per il quale
                            l’Utente dovrà fare riferimento esclusivamente all’informativa privacy
                            fornita dal social network.</p>

                        <p>Al di fuori dei casi in cui l’Utente condivida spontaneamente i propri dati
                            di navigazione con i social network prescelti per il tramite di click sui
                            social button/widgets, il Titolare non diffonde o condivide alcun dato
                            personale dell’Utente con il social network.</p>

                        <p>Per l’informativa sui cookie utilizzati dai social network e per maggiori
                            informazioni su come gestirli e disattivarli, è possibile consultare le
                            rispettive privacy policy per il tramite dei seguenti link:</p>
                        <p>- Facebook: <a href="https://www.facebook.com/help/cookies/" rel="noopener noreferrer" target="_blank">https://www.facebook.com/help/cookies/</a></p>
                        <p>- Twitter: <a href="https://twitter.com/settings/security" rel="noopener noreferrer" target="_blank">https://twitter.com/settings/security</a></p>
                        <p>- Google+: <a href="http://www.google.it/intl/it/policies/technologies/cookies/" rel="noopener noreferrer" target="_blank">http://www.google.it/intl/it/policies/technologies/cookies/</a></p>
                        <p>- Instagram: <a href="https://www.instagram.com/legal/cookies/" rel="noopener noreferrer" target="_blank">https://www.instagram.com/legal/cookies/</a></p>

                        <p>Il titolare non garantisce che i link alle predette policy siano sempre
                            funzionanti, e ciò a causa di eventuali aggiornamenti che non dipendono dal
                            titolare, pertanto l’utente è tenuto a consultare e verificare
                            personalmente il testo vigente. Il titolare non è responsabile per
                            eventuali malfunzionamenti o condizioni che non consentano la consultazione
                            delle policy di predette applicazioni.</p>

                        <p>La durata dei cookies, va dalla singola sessione a due anni dalla visita della pagina.</p>

                        <p><strong>GESTIONE DEI COOKIES NELLA CONFIGURAZIONE DEI MAGGIORI BROWSER UTILIZZATI</strong></p>
                        <p><a href="https://support.google.com/chrome/answer/95647?hl=it">Gestisci i cookies in Google
                            Chrome</a></p>

                        <p><a href="https://support.microsoft.com/it-it/kb/196955">Gestisci i cookies in Internet Explorer</a></p>

                        <p><a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie?redirectlocale=it-IT&redirectslug=Cookies">Gestisci i cookies in Mozilla Firefox</a></p>

                        <p><a href="https://support.apple.com/kb/PH5042?locale=it_IT">Gestisci i cookies in Safari</a></p>

                        <p><a href="https://support.apple.com/it-it/HT201265">Gestisci i cookies in IOS</a></p>

                        <p><strong>Si indica nuovamente il seguente sito per analizzare, più in generale, e gestire le
                        proprie preferenze in tema di “<em>online behavioural advertising</em>” (pubblicità
                        comportamentale online)</strong> <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com</a>.</p>

                        <p><strong> Si ricorda che la disattivazione o il blocco di alcuni cookies può pregiudicare la completa fruizione del sito o, più in generale, la sua
                            consultazione. Comunque la maggior parte dei browser disponibili in rete consente la funzione “antitracciamento”.</strong></p>
                        <br className="mob" /><br className="mob" />
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Privacy