import http, { BASE_URL, BASE_URL_1_3 } from './lib/http';
import Subcategory from './Subcategory';
import Product from './Product';

class Shop {
    
    /**
     * Costruttore
     * @param {Object} data
     * @param {number} data.id   id numerico 
     * @param {string} data.code codice alfanumerico
     * @param {string} data.name dicitura completa
     * @param {...*}   info      dati restanti
     */
    constructor({ id, code, name, ...info }) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.info = info;
    }

    /**
     * Metodo statico per clonare un'istanza di Shop
     * @param {object} data 
     * @param {number} data.id 
     * @param {string} data.code 
     * @param {string} data.name 
     * @param {object} data.info
     * @returns {Shop} 
     */
    static make({ id, code, name, info }) {
        return new Shop({
            id,
            code,
            name,
            ...info,
        });
    }

    /**
     * Metodo statico per creare un'istanza di Shop dai dati forniti dal server
     * @param {number} id 
     * @returns {Shop}
     * @throws {Error}
     */
    static async load(id) {

        try {
            const shopInfo = await http.get(`${BASE_URL_1_3}/get-business-by-id/${id}`);
            return new Shop(shopInfo);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Restituisce l'elenco degli shop situati nella zona indicata
     * e appartenenti alla categoria specificata
     * @param {Category} category
     * @param {Object}   category.info oggetto contenente l'encoded title
     * @param {Zone}     category.zone 
     * @returns {Array<Shop>}
     * @throws {Error}
     */
    static async listForCategory({ info = {}, zone = {} }) {
        try {
            const data = await Shop.fetchShopData({
                //path: 'get-restaurants-by-macrocat-and-zone',
                path: 'get-businesses-by-zone',
                info,
                zone,
            });
            return data;
            
        } catch (error) {
            if (error.message === 'Data is missing!') {
                throw new Error("Category data is missing!");
            }
            
            throw error;
        }

    }

    /**
     * Restituisce l'elenco degli shop situati nella zona indicata
     * e appartenenti alla sottocategoria specificata
     * @param {Subcategory} subcategory
     * @param {Object}   subcategory.info oggetto contenente l'encoded title
     * @param {Zone}     subcategory.zone 
     * @returns {Array<Shop>}
     * @throws {Error}
     */
    static async listForSubcategory({ info = {}, zone = {} }) {

        try {
            const data = await Shop.fetchShopData({
                path: 'get-restaurants-by-category-and-zone',
                info,
                zone,
            });
            return data;
        } catch (error) {
            if (error.message === 'Data is missing!') {
                throw new Error("Subcategory data is missing!");
            }

            throw error;
        }

    }

    /**
     * Esegue l'opportuna chiamata al server per ottenere i dati degli shop
     * @param {Object}  options 
     * @param {string}  options.path    parte dell'URL dell'endpoint che precede i parametri
     * @param {Object}  options.info    oggetto contenente l'encoded title
     * @param {Zone}    options.zone
     * @returns {Array<Shop>}
     * @throws {Error}
     */
    static async fetchShopData({ path = '', info = {}, zone = {} }) {
        if (!path || !zone.id) {
            throw new Error("Data is missing!");
        }
        try {
            const data = await http.get(`${BASE_URL_1_3}/${path}/${zone.id}/${info.id_lang}`);
            return data.map((shopInfo) => new Shop(shopInfo));
        } catch (error) {
            throw error;
        }

    }

    /**
     * Restituisce le sottocategorie disponibili per un ristorante
     * @returns {Array<Subcategory>}
     * @throws {Error}
     */
    async getSubcategories() {

        try {
            //const data = await http.get(`${BASE_URL}/get-categories-for-restaurant/${this.id}`);
            const data = await http.get(`${BASE_URL}/get-categories-for-restaurant/${this.info.info.id_catalog}`);
            return data.map((subcategoryInfo) => new Subcategory(subcategoryInfo));
        } catch (error) {
            throw error;
        }

    }

    /**
     * Restituisce i prodotti appartenenti alla sottocategoria indicata
     * @param {Subcategory} subcategory 
     * @returns {Array<Product>}
     * @throws {Error}
     */
    async getProductsFor(subcategory) {

        const { title } = subcategory;

        if (!title) {
            throw new Error("Subcategory data is missing!");
        }

        if (this.products && this.products[title] && this.products[title].length > 0) {
            return this.products[title];
        }

        try {
            await this.loadProductsFor(subcategory);
        } catch (error) {
            throw error;
        }

        return this.products[title];
    }

    /**
     * Popola la proprietà 'products' dell'istanza 
     * @param {Subcategory} subcategory 
     * @param {string}      subcategory.title 
     * @param {object}      subcategory.info    vari dati sulla sottocategoria. DEVE contenerne l'id.
     * @throws {Error}
     */
    async loadProductsFor({ title = '', info = {} }) {

        if (!title || !info.id) {
            throw new Error("Subcategory data is missing!");
        }

        try {
            //const data = await http.get(`${BASE_URL}/get-items-for-category-and-restaurant/${info.id}/${this.id}`);
            const data = await http.get(`${BASE_URL}/get-items-for-category-and-restaurant/${info.id}/${this.info.info.id_catalog}`);
            this.products = {
                ...this.products,
                [title]: data.map((productInfo) => new Product({ 
                    ...productInfo, 
                    shopId: this.info.info.id_catalog, 
                    subcategoryId: info.id, 
                    subcategoryName: info.code.split('_')[1] || "", 
                })),
            };
        } catch (error) {
            throw error;
        }

    }
}

export default Shop;