import produce from 'immer';
import { Base64 } from 'js-base64';

/**
 * Restituisce un action creator
 * Il metodo toString() dell'action creator restituisce il type
 * @param {string} type 
 * @returns {Function}
 */
export function createAction(type) {
    function actionCreator(payload) {
        return {
            type,
            payload,
        }
    }

    actionCreator.toString = () => `${type}`;

    actionCreator.type = type;

    return actionCreator;
}

/**
 * Restituisce un riduttore che utilizza [Immer](https://github.com/immerjs/immer) 
 * per mutare lo stato
 * @param {Object} initialState 
 * @param {Object} handlers 
 * @returns {Function}
 */
export function createReducer(initialState, handlers) {
    return produce(
        (draft, action) => {
            handlers[action.type](draft, action);
            dehydrate("store", draft);
        }, 
        initialState
    );
}

/**
 * Memorizza l'oggetto specificato in local storage associandolo alla chiave indicata
 * @param {string} key 
 * @param {object} value 
 */
export function dehydrate(key, value) {
    const v = Base64.encode(JSON.stringify(value));
    sessionStorage.setItem(key, v);
}

/**
 * Ripristina l'oggetto salvato in local storage in associazione alla chiave indicata
 * @param {string} key 
 * @returns {object}
 */
export function hydrate(key) {
    const savedValue = sessionStorage.getItem(key);
    if (!savedValue) {
        return null;
    }

    try {
        const v = Base64.decode(savedValue);
        return JSON.parse(v);
    } catch(error) {
        return null;
    }
}
