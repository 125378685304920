import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Email from '@material-ui/icons/EmailOutlined';
import Lock from '@material-ui/icons/LockOutlined';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link, Redirect } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'

import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';


import Token from '../../api/Token';
import User from '../../api/User';

import { Store } from '../../contexts/Store/Store';
import { setUser } from '../../contexts/Store/actions';

import Alert from '../../components/Utils/Alert';

import { ReactComponent as Onda } from '../../assets/onda.svg';

import './Login.scss';

const useStyles = makeStyles(theme => ({
  root : {
      marginBottom:'30px',
      paddingBottom:'0px',
      borderBottom:'1px solid #ccc'
  }
}));

const LoginButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Gotham Bold',
    fontSize: '1.125em',
    padding: '10px 50px',
    border: '1px solid',
    lineHeight: 1.5,
    display: 'block',
    marginTop:50,
    marginLeft:'auto',
    marginRight:'auto',
    backgroundColor: '#009fe2',
    borderColor: '#009fe2',
    borderRadius: '30px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&:active': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
  },
})(Button);

const Login = (props) => {
    const classes = useStyles();
    const { state, dispatch } = React.useContext(Store);
    const [values, setValues] = React.useState({
        email: '',
        email_rec: '',
        password: '',
        showPassword: false,
        isRegistering: false,
        isRegistered: false,
    });

    const DEV = (process.env.REACT_APP_IS_DEV == "1" | process.env.REACT_APP_IS_DEV == "2") ? true : false;

    const [openAlertLogin, setOpenAlertLogin] = React.useState(false);
    
    const [pwd, setPwd] = React.useState(true);
    function handleChangePwd(b) {
        setPwd(b);
    };

    const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    function handleSubmit() {
        setValues({ ...values, isRegistering: true });
        Token.request({
            email: values.email,
            password: values.password,
        })
        .then((token) => {
            token.store();
            const user = User.login({
                email: values.email,
                password: values.password,
            }, token);
            return user;
        })
        .then((user) => {
            dispatch(setUser({ user }));
            setValues({ ...values, isRegistered: true });
            setOpenAlertLogin(false);
        })
        .catch(err => {
            setOpenAlertLogin(true);
            setValues({ ...values, isRegistering: false });
        });
    }

    function handleCloseAlert() {
        setOpenAlertLogin(false);
    }

    const responseFacebook = (r) => {
      console.log(r);
      User.loginSocial({
            email: r.email,
            token: r.accessToken,
            user_id: r.userID,
            social: "token_fb"
        })
        .then((res) => {
            console.log(res);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const responseGoogle = (r) => {
      console.log(r);
      User.loginSocial({
            email: r.profileObj.email,
            token: r.tokenId,
            social: "token_google"
        })
        .then((res) => {
            console.log(res);
        })
        .catch(err => {
            console.log(err);
        });
    }

    const prevURL = sessionStorage.getItem('prevURL') || '/';
    let urlArray = prevURL.split('/');
    urlArray.splice(0,3);
    urlArray = urlArray.join('/'); 
    
    return(
        <>
            <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!">
                <title>Spesa online, negozi e ristoranti con consegna a domicilio - Rivemo!</title>
                <meta name="description" content="Sei a casa e non ti va di cucinare? Devi fare la spesa ma non vuoi perdere tempo facendo lunghe code? Ti sei dimenticato di passare in farmacia? Visita il sito Rivemo e ordina tutto ciò che vuoi! Puoi ricevere i tuoi prodotti comodamente a domiclio oppure ritirarli in negozio all’orario che preferisci" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            {
                values.isRegistered &&
                <>
                {
                    state.cart.length > 0 ? <Redirect noThrow to="/cart" /> : <Redirect noThrow to={`/${urlArray}`} />
                }
                </>
            }
            <Alert open={openAlertLogin} onClose={handleCloseAlert} title="Attenzione" content={`Nome utente o password errati`} />
            <div className="top-location in-cart">
                <Onda className="onda" />
                <div className="shop-products thank-you">
                    <Container style={{ maxWidth: '600px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                        <div style={{ textAlign: 'center', paddingBottom: '50px' }}>
                            <span className="thank-you-title">Login a Rivemo!</span>
                        </div>
                    </Container>
                    <Container style={{ maxWidth: '405px', position: 'relative', zIndex:0, padding: '0 0 100px 0' }} className="login-form" fixed>
                        {pwd ? (
                            <>
                            {
                                DEV && 
                                <div className="other-logins">
                                    <FacebookLogin
                                        appId="635054177086352"
                                        fields="email"
                                        callback={responseFacebook}
                                    />
                                    <br />
                                    <br />
                                    <GoogleLogin
                                        clientId="334738201033-pgg1io1lkuu5ok2muem39vo79qc9bion.apps.googleusercontent.com"
                                        buttonText="LOGIN WITH GOOGLE"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                    />
                                </div>
                            }

                            <form>
                                <Grid container spacing={0} alignItems="flex-start" className={classes.root}>
                                    <Grid item xs={1}>
                                        <Email style={{ opacity: 0.3, width:'1.3em', height:'1.3em' }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <FormControl style={{ width:'100%', paddingLeft:15 }}>
                                            <InputLabel htmlFor="adornment-password" style={{ transform: 'translate(0, 8px) scale(1)' }}>Email</InputLabel>
                                            <Input 
                                                id="Email"
                                                onChange={handleChange('email')}
                                                value={values.email}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} alignItems="flex-start" className={classes.root}>
                                    <Grid item xs={1}>
                                        <Lock style={{ opacity: 0.3, width:'1.3em', height:'1.3em' }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <FormControl style={{ width:'100%', paddingLeft:15 }}>
                                            <InputLabel htmlFor="adornment-password" style={{ transform: 'translate(0, 8px) scale(1)' }}>Password</InputLabel>
                                            <Input 
                                                id="Lock"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={handleChange('password')}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {values.showPassword ? <Visibility style={{ opacity: 0.5 }} /> : <VisibilityOff style={{ opacity: 0.5 }} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/*<p className="login-link" style={{ textAlign: 'right', margin: '0 0 30px 0' }} onClick={() => handleChangePwd(false)}>Password dimenticata?</p>*/}
                                <LoginButton variant="contained" color="primary" disableRipple onClick={handleSubmit}>LOGIN</LoginButton>
                                {values.isRegistering && <p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>}
                            </form>
                            </>
                        ) : (
                            <form>
                                <Grid container spacing={0} alignItems="flex-start" className={classes.root}>
                                    <Grid item xs={1}>
                                        <Email style={{ opacity: 0.3, width:'1.3em', height:'1.3em' }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <FormControl style={{ width:'100%', paddingLeft:15 }}>
                                            <InputLabel htmlFor="adornment-password" style={{ transform: 'translate(0, 8px) scale(1)' }}>Email</InputLabel>
                                            <Input 
                                                id="EmailRec"
                                                onChange={handleChange('email_rec')}
                                                value={values.email_rec}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <p className="login-link" style={{ textAlign: 'right', margin: '0 0 30px 0' }} onClick={() => handleChangePwd(true)}>Torna al login</p>
                                <LoginButton variant="contained" color="primary" disableRipple>RECUPERA PASSWORD</LoginButton>
                                {values.isRegistering && <p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>}
                            </form>
                        )}
                        <p style={{ textAlign: 'center', margin: '50px' }}>Nuovo su Rivemo? <Link to="/register" className="login-link">Registrati</Link></p>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Login;