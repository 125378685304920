import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';

import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as AddAgain } from '../../assets/addagain.svg';
import { ReactComponent as Remove } from '../../assets/remove-minus.svg';
import { ReactComponent as Add } from '../../assets/add.svg';
import { ReactComponent as Minus } from '../../assets/minus.svg';
import { ReactComponent as Plus } from '../../assets/plus.svg';
import piatto from '../../assets/spesa_miniatura.jpg';

import { ReactComponent as MetroCart } from '../../assets/condizioni/metro-cart-white.svg';
import { ReactComponent as EditIcon } from '../../assets/condizioni/edit-white.svg';
import Edit from '../../assets/condizioni/edit.svg';

import SPICY from '../../assets/ingredienti/chili.svg';
import VEGAN from '../../assets/ingredienti/vegan.svg';
import FROZE from '../../assets/ingredienti/froze.svg';
import VEGET from '../../assets/ingredienti/vegetarian.svg';

import CELERY from '../../assets/ingredienti/celery.svg';
import CRUSTACEANS from '../../assets/ingredienti/crustaceans.svg';
import EGGS from '../../assets/ingredienti/eggs.svg';
import FISH from '../../assets/ingredienti/fish.svg';
import GLUTEN from '../../assets/ingredienti/gluten.svg';
import LUPIN from '../../assets/ingredienti/lupin.svg';
import MILK from '../../assets/ingredienti/milk.svg';
import MOLLUSCHI from '../../assets/ingredienti/molluschi.svg';
import MUSTARD from '../../assets/ingredienti/mustard.svg';
import NUTS from '../../assets/ingredienti/nuts.svg';
import PEANUTS from '../../assets/ingredienti/peanuts.svg';
import SESAME from '../../assets/ingredienti/sesame.svg';
import SOYA from '../../assets/ingredienti/soya.svg';
import SULPHITES from '../../assets/ingredienti/sulphites.svg';

import Product from '../../api/Product';
import { Store } from '../../contexts/Store/Store';
import { addProduct } from '../../contexts/Store/actions';


import Alert from '../../components/Utils/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: '8px 0px',
  },
  dialogActions: {
    padding: '25px 20px',
    backgroundColor: '#ebebeb',
    display:'block',
  },
}));

const ShopProducts = (props) => {
    const { menuItems, shop, width } = props;
    const classes = useStyles();

    const { state, dispatch } = React.useContext(Store);
  
    const [open, setOpen] = React.useState(false);
    const [openZoom, setOpenZoom] = React.useState(false);
    const [zoomTitle, setZoomTitle] = React.useState('');
    const [zoomURL, setZoomURL] = React.useState('');
    const [openAlertMaxOptions, setOpenAlertMaxOptions] = React.useState(false);
    const [openAlertMinOptions, setOpenAlertMinOptions] = React.useState(false);
    const [product, setProduct] = React.useState({});
    const [options, setOptions] = React.useState([]);
    const [maxOptions, setMaxOptions] = React.useState(0);
    const [minOptions, setMinOptions] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [numSelectedOptions, setNumSelectedOptions] = React.useState(0);
    const [numRemovedStandardOptions, setNumRemovedStandardOptions] = React.useState(0);
    const [counter, setCounter] = React.useState(1);
    
    React.useEffect(() => {
      setProduct(menuItems[0]);
      setTotal(menuItems[0].getTotalPrice());
    }, [menuItems]);

    function getSpec(code){
      switch(code){
        case 'SPICY':
          return SPICY;
        case 'VEGAN':
          return VEGAN;
        case 'FROZE':
          return FROZE;
        case 'VEGET':
          return VEGET;
      }
    }

    function getAllergen(code){
      switch(code){
        case 'CELED':
          return { img: CELERY, width: 16 };
        case 'CRUSD':
          return { img: CRUSTACEANS, width: 19 };
        case 'EGGSD':
          return { img: EGGS, width: 23 };
        case 'FISHES':
          return { img: FISH, width: 31 };
        case 'GLUTE':
          return { img: GLUTEN, width: 11 };
        case 'LUPID':
          return { img: LUPIN, width: 25 };
        case 'MILKD':
          return { img: MILK, width: 14 };
        case 'MOLLD':
          return { img: MOLLUSCHI, width: 28 };
        case 'MUSTD':
          return { img: MUSTARD, width: 14 };
        case 'NUTSD':
          return { img: NUTS, width: 22 };
        case 'PEAND':
          return { img: PEANUTS, width: 26 };
        case 'SESAD':
          return { img: SESAME, width: 19 };
        case 'SOYDE':
          return { img: SOYA, width: 15 };
        case 'SULFU':
          return { img: SULPHITES, width: 23 };
      }
    }

    function handleClickOpen(menuItem) {
        const product = Product.make(menuItem);
        product.getAllOptions()
        .then(([list, number_max, number_min]) => {
            if(list !== null) {
                setOptions(list);
            } else {
                setOptions([]);
            }
            if(number_max !== null) {
                setMaxOptions(number_max);
            } else {
                setMaxOptions(0);
            }
            if(number_min !== null) {
                setMinOptions(number_min);
            } else {
                setMinOptions(0);
            }
            
            setProduct(product);
            setTotal(product.getTotalPrice());
            setOpen(true);
        });
    }

    function handleClickOpenDirectAdd(menuItem) {
        const product = Product.make(menuItem);
        setProduct(product);
        setTotal(product.getTotalPrice());
        dispatch(addProduct({ product, counter }));
    }

    function handleClose() {
        setCounter(1);
        setOpen(false);
    }

    function handleCloseZoom() {
        setOpenZoom(false);
    }
    function zoomPhoto(title, url){
      setZoomTitle(title);
      setZoomURL(url);
      setOpenZoom(true);
    }

    function handleCloseAlert() {
        setOpenAlertMaxOptions(false);
    }
    function handleCloseMinAlert() {
        setOpenAlertMinOptions(false);
    }

    function toggleOption(e, product, option) {
        let el = e.currentTarget.querySelector('.dialog-ingredient-add');
        if(el) el.classList.toggle('active');
        if (product.hasOption(option)) {
            product.removeOption(option);
            if(el) el.classList.remove('active');
        } else {
            try {
                product.selectOption(option);
                if(el) el.classList.add('active');
                setOpenAlertMaxOptions(false);
            } catch (error) {
                setOpenAlertMaxOptions(true);
                if(el) el.classList.remove('active');
            }
        }

        setTotal(product.getTotalPrice());
        setNumSelectedOptions(product.selectedOptions.length)
    }

    function toggleStandardOption(e, product, standardOption) {
        let el = e.currentTarget;
        if (product.isStandardOptionRemoved(standardOption)) {
            product.restoreStandardOption(standardOption);
            if(el) el.classList.remove('removed');
        } else {
            product.removeStandardOption(standardOption);
            if(el) el.classList.add('removed');
        }

        setNumRemovedStandardOptions(product.removedStandardOptions.length);
    }

    function insertNote(e) {
        product.insertNote(e.target.value);
    }

    function moveCounter(qty) {
        if(qty < 1) qty = 1;
        setCounter(qty);
        setTotal(product.getTotalPrice() * qty);
    }

    function addProductToCart(product, counter) {
        const minimo = typeof parseInt(product.info.min_accessories) == 'number' ? parseInt(product.info.min_accessories) : 0;
        if(product.selectedOptions.length < minimo){
          setOpenAlertMinOptions(true);
        } else {
          setOpenAlertMinOptions(false);
          dispatch(addProduct({ product, counter }));
          handleClose();
        }
    }
    


  return(
        <>
        {
          menuItems.map((menuItem, index) => 
            <Card key={`menuItem-${index}`} className="product-card-container">
              <div className="product-card">
                <CardContent className={menuItem.imageUrl ? "product-card-text" : "product-card-text no-foto"}>
                  <h2 className="product-name">
                    {menuItem.title}
                    {menuItem.info.specifications && menuItem.info.specifications.length > 0 ? (
                      menuItem.info.specifications.map((spec, index) => 
                        <Tooltip title={spec.title} placement="top" arrow disableFocusListener enterTouchDelay={0} key={`spec-${index}`}><img className="specification" src={getSpec(spec.code)} width="23" /></Tooltip>
                      ) 
                    ) : ( null )}
                  </h2>
                  <div className="product-ingredients">
                    <div>
                      {
                        (Array.isArray(menuItem.standardOptions) && menuItem.standardOptions.length > 0) ? (
                            menuItem.standardOptions.map((ingrediente, index) => 
                              <span className="singolo-ingrediente" key={`singolo-${index}`}>{ingrediente.title}</span>
                            )
                        ) : (
                          <>{menuItem.description}</>
                        )
                      }
                    </div>
                    {
                      (
                        (
                          (
                            Array.isArray(menuItem.standardOptions) && menuItem.standardOptions.length > 0
                          ) 
                          || menuItem.info.max_accessories != '0'
                        ) 
                        && 
                        (
                         menuItem.info.min_accessories == null || menuItem.info.min_accessories == '0'
                        )
                      ) &&
                          <div className="actions-block nopad"><span className="actions-btn" onClick={() => handleClickOpen(menuItem)}><img src={Edit} width="16" /> personalizza</span></div>
                    }
                  </div>
                </CardContent>
                {menuItem.imageUrl && menuItem.imageUrl !== "" && 
                  <CardMedia
                    onClick={() => zoomPhoto(menuItem.title, menuItem.imageUrl)}
                    className={menuItem.imageUrl ? "img-piatto" : "img-piatto no-foto"}
                    image={menuItem.imageUrl ? menuItem.imageUrl : piatto}
                    title={menuItem.title}
                  />
                }
              </div>
              <div className="actions-container">
                <div className="actions-all">
                  {menuItem.info.allergens && menuItem.info.allergens.length > 0 ? (
                    menuItem.info.allergens.map((allergen, index) => 
                      <Tooltip title={allergen.title} placement="top" arrow disableFocusListener enterTouchDelay={0} key={`allergen-${index}`}><img className="allergen" src={getAllergen(allergen.code).img} width={getAllergen(allergen.code).width * 0.75} /></Tooltip>
                    )
                  ) : ( null )}
                </div>
                <div className="actions-act">
                  <div className="actions-block">
                    <NumberFormat value={menuItem.price} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} />
                  </div>
                  {
                    (menuItem.info.min_accessories == "" || menuItem.info.min_accessories === null || parseInt(menuItem.info.min_accessories) === 0) ? (
                      <div className="actions-block"><span className="actions-btn-full" onClick={() => handleClickOpenDirectAdd(menuItem)}>AGGIUNGI</span></div>
                    ) : (
                      <div className="actions-block"><span className="actions-btn-full" onClick={() => handleClickOpen(menuItem)}><EditIcon width="16" /> PERSONALIZZA</span></div>
                    )
                  }
                </div>
              </div>
            </Card>
          )
        }
        <Alert open={openAlertMinOptions} onClose={handleCloseMinAlert} title="Attenzione" content={`Devi aggiungere almeno ${minOptions} ingredienti`} />
        <Alert open={openAlertMaxOptions} onClose={handleCloseAlert} title="Attenzione" content={`Puoi aggiungere al massimo ${maxOptions} ingredienti`} />
        <Dialog
          open={openZoom}
          onClose={handleCloseZoom}
          aria-labelledby="scroll-dialog-title"
          className="only-zoom"
        >
          <DialogTitle id="scroll-dialog-title">
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseZoom}>
              <CloseIcon />
            </IconButton>
            <p className="zoom-product-name"><strong>{zoomTitle}</strong></p>
          </DialogTitle>
          <DialogContent className="zoom-img"><img src={zoomURL} /></DialogContent>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            {product.imageUrl && <p className="dialog-product-image"><img alt={product.title} src={product.imageUrl} /></p>}
            <p className="dialog-product-name"><strong>{product.title}</strong></p>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {(typeof product.id !== 'undefined' && product['standardOptions'].length > 0) &&
              <div>
                <div className="dialog-subtitle">Modifica ingredienti</div>
                <div className="dialog-ingredients">
                  {
                    product['standardOptions'].map((standardOption, index) => 
                      <div key={`standardOption-${index}`} className="dialog-row-ingredient" style={{ cursor:'pointer' }} onClick={(e) => toggleStandardOption(e, product, standardOption)}>
                        <span className="dialog-ingredient-name">{standardOption.title}</span>
                        <span className="dialog-ingredient-remove"><span className="addagain"><AddAgain /></span><span className="remove"><Remove /></span></span>
                      </div>
                    )
                  }
                </div>
              </div>
            }

            {options.length > 0 &&
              <>
              <div className="dialog-subtitle">Aggiungi ingredienti {(maxOptions > 0 && maxOptions !== null) ? `(max ${maxOptions})` : ``}</div>
              <div className="dialog-ingredients">
                {options.map((option, index) =>
                    <Grid key={`option-${index}`} container direction="row" justify="center" alignItems="center" className="dialog-row-ingredient" style={{ cursor:'pointer' }} onClick={(e) => toggleOption(e, product, option)}>
                      <Grid item xs={2} sm={1} style={{ display:'flex' }}><span className="dialog-ingredient-add"><Add /></span></Grid>
                      <Grid item xs={7} sm={9}>{option.title}</Grid>
                      <Grid item xs={3} sm={2} style={{ textAlign:'right' }}>{option.price > 0 && <span className="dialog-ingredient-price"><NumberFormat value={option.price} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'+ € '} /></span>}</Grid>
                    </Grid>
                  )
                }
              </div>
              </>
            }
            <div className="dialog-note">
              <span className="note-title">Note <em>(non inserire aggiunte)</em></span>
              <textarea name="note" onChange={(e) => { insertNote(e); }} placeholder="Istruzioni speciali (es. pizza tagliata a spicchi, poco pomodoro, etc.)"></textarea>
            </div>
          </DialogContent>
          <DialogActions className={`dialog-actions-container ${classes.dialogActions}`}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={3}>
                <Grid container direction="row" justify="center" alignItems="center" className="dialog-actions">
                  <Grid item xs={3} style={{ cursor:'pointer' }} className={counter === 1 ? "deactivate" : ""} onClick={() => moveCounter(counter-1)}><Minus className="add-or-remove" /></Grid>
                  <Grid item xs={4}>{counter}</Grid>
                  <Grid item xs={3} style={{ cursor:'pointer' }} onClick={() => moveCounter(counter+1)}><Plus className="add-or-remove" /></Grid>
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <div className="dialog-confirm" onClick={() => { addProductToCart(product, counter); }}>
                    <strong>AGGIUNGI AL TUO ORDINE</strong><br className="mob" />
                    <strong><NumberFormat value={total} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} /></strong>
                </div>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
  )
}

ShopProducts.propTypes = {
  width: PropTypes.string,
};

export default withWidth()(ShopProducts);