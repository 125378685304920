class MapPoint {

    /**
     * Costruttore
     * @param {object} data
     * @param {number} data.latitude
     * @param {number} data.longitude
     */
    constructor({ latitude, longitude }) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    /**
     * Restituisce le coordinate sotto forma di array con la latitudine come
     * primo elemento e la longitudine come secondo
     * @returns {Array<number>}
     * @throws {Error}
     */
    getPosition() {

        if (!this.latitude || !this.longitude) {
            throw new Error("Data is missing!");
        }

        return [
            this.latitude,
            this.longitude,
        ];
    }

}

export default MapPoint;