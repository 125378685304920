class Rule {

    /**
     * Costruttore
     * @param {*} subject 
     */
    constructor(subject) {
        this.subject = subject;
        this.validate = () => undefined;
    }

    /**
     * Metodo statico per creare un'istanza
     * @param {*} subject 
     */
    static checkIf(subject) {
        return new Rule(subject);
    }

    /**
     * Imposta la funzione per verificare se l'oggetto da testare è un'istanza 
     * del costruttore indicato 
     * @param {Function} constructorFunction 
     * @returns {this}
     */
    isA(constructorFunction) {
        this.validate = () => this.subject instanceof constructorFunction;
        return this;
    }

    /**
     * Syntactic sugar per leggibilità, ma è identica a isA() 
     * @param {Function} constructorFunction 
     * @returns {this}
     */
    isAn(constructorFunction) {
        return this.isA(constructorFunction);
    }

    /**
     * Imposta la funzione per verificare se tutti i parametri obbligatori
     * sono presenti nell'oggetto
     * @param {Array} requiredParams
     * @returns {this} 
     */
    hasAll(requiredParams) {
        this.validate = () => requiredParams.every((param) => Boolean(this.subject[param]));
        return this;
    }
}

export default Rule;