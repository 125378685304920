import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import HubspotForm from 'react-hubspot-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'

import { ReactComponent as Onda } from '../../assets/onda.svg';
import riderImg from '../../assets/rider.jpg';

import './hubspot-form.css';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const Rider = (props) => {
    
    React.useEffect(() => {
        pushGTM();
    }, []);

    return(
        <>
            <Helmet defaultTitle="Vuoi lavorare in modo flessibile? Diventa un rider di Rivemo!">
                <title>Vuoi lavorare in modo flessibile? Diventa un rider di Rivemo!</title>
                <meta name="description" content="Entrando nel team dei rider di Rivemo potrai gestire i tuoi orari in autonomia e lavorare anche nel tuo tempo libero. Effettua consegne a domicilio con noi!" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-shop normal-page" style={{ backgroundImage: `url(${riderImg})`, backgroundPosition: 'center top' }}>
                <Onda className="onda" />
                <div className="top-text">
                    <h1>Vuoi lavorare in modo flessibile?</h1>
                    <h2>Diventando un rider di Rivemo potrai gestire i tuoi orari in autonomia e lavorare anche nel tuo tempo libero.</h2>
                </div>
            </div>
            <div className="generic">
                <Container style={{ maxWidth: '600px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                    <div className="chisiamo cnt">
                        <div className="chisiamo-text full">
                            <h3 className="offriamo">Compila il form</h3>
                            <p>Inserisci i tuoi dati, ti contatteremo noi al più presto.</p><br className="mob" />
                        </div>
                        <div className="hbs-container">
                            <HubspotForm
                                portalId='6208112'
                                formId='6d298832-d303-4989-ba2f-412daefc0902'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Rider;