import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Link } from '@reach/router';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';

import PropTypes from 'prop-types';
import { 
    removeProduct, 
    incrementProductCounter, 
    decrementProductCounter, 
} from '../../contexts/Store/actions';

const LoginButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Gotham Bold',
    fontSize: '1.125em',
    padding: '10px 55px',
    border: '1px solid',
    lineHeight: 1.5,
    display: 'block',
    marginLeft:'auto',
    marginRight:'auto',
    backgroundColor: '#009fe2',
    borderColor: '#009fe2',
    borderRadius: '30px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&:active': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
  },
})(Button);

/**
 * Decora l'oggetto di tipo Product passato con il metodo getOptionsList()
 * che consente di visualizzare l'elenco delle opzioni selezionate
 * @param {Product} product
 * @returns {Product} 
 */
function withOptions(product) {

    product.getOptionsList = () => {
        
        if (product.selectedOptions.length === 0 && product.removedStandardOptions.length === 0) {
            return;
        }

        return (
            <div>
                { 
                    product.removedStandardOptions.map((option, index) => (
                        <span key={`${product.id}.${option.id}.${index}`}>
                            {`- ${option.title.toLowerCase()}`}<br />
                        </span>
                    ))
                }
                { 
                    product.selectedOptions.map((option, index) => (
                        <span key={`${product.id}.${option.id}.${index}`}>
                            {`+ ${option.title.toLowerCase()}`}<br />
                        </span>
                    ))
                }
            </div>
        )
    };

    return product;
}

const ShopCart = (props) => {
    const { items, shippingCost, dispatch, user, shopData } = props;
    const [subtotal, setSubtotal] = React.useState(0);
    const [delivery, setDelivery] = React.useState(false);
    const [takeaway, setTakeaway] = React.useState(false);
    
    React.useEffect(() => {
        const newSubtotal = props.items.reduce((result, item) => {
            const { counter, product } = item;
            result += counter * product.getTotalPrice();
            return result;
        }, 0);

        setSubtotal(newSubtotal);
        
        props.onUpdate({
            total: newSubtotal + props.shippingCost,
        });

        props.getCartSubTotal(newSubtotal);

        props.items.forEach((item, index) => {
            if (item.counter === 0) {
                props.dispatch(removeProduct({ index }));
            }
        });
    }, [props.items]);

    React.useEffect(() => {
        const deliveryMode =  (shopData && typeof shopData.info != 'undefined') ? shopData.info.delivery_mode : null;
        if(deliveryMode && deliveryMode !== null && deliveryMode !== '' ){
            deliveryMode.indexOf('DELIVERY') !== -1 ? setDelivery(true) : setDelivery(false);
            deliveryMode.indexOf('TAKEAWAY') !== -1 ? setTakeaway(true) : setTakeaway(false);
        } 
    }, [shopData]);


  return(
    <Paper className={props.position === 'in-cart' ? "product-cart in-cart" : "product-cart"}>
        <span className="cart-title">IL TUO ORDINE</span>
        {
            shopData && shopData.info.first_slot && shopData.info.first_slot.day !== null && shopData.info.first_slot.day != "" &&
            <div className="shop-first-slot-container">
                { delivery && <div className="shop-first-slot">Prima consegna<br className="mob" /> {shopData.info.first_slot.day.toLowerCase()} {(shopData.info.first_slot.hour && shopData.info.first_slot.hour !== "" && shopData.info.is_autoslot !== 1) ? `ore ${shopData.info.first_slot.hour}` : ''}</div> }
                { takeaway && <div className="shop-first-slot">Primo ritiro<br className="mob" /> {shopData.info.first_slot.day.toLowerCase()} {(shopData.info.first_slot.hour && shopData.info.first_slot.hour !== "" && shopData.info.is_autoslot !== 1) ? `ore ${shopData.info.first_slot.hour}` : ''}</div> }
            </div>
        }
        {items.length > 0 ? (
            <Container className="cart-container" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                {
                    items.map((item, index) => {
                        const product = withOptions(item.product);
                        return (
                            <Grid key={`product-${index}`} className="cart-row" container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
                                <Grid item xs={3}>
                                    {!props.thankYou && <span className="circle-btn" onClick={() => dispatch(decrementProductCounter({ index }))}>-</span>}
                                    <span className="quantity">{item.counter}</span>
                                    {!props.thankYou && <span className="circle-btn" onClick={() => dispatch(incrementProductCounter({ index }))}>+</span>}
                                </Grid>
                                <Grid item xs={6}>
                                    <strong className="main-item">{product.title}</strong>
                                    <span className="ingredients">{product.getOptionsList()}</span>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: 'right' }}><NumberFormat value={product.getTotalPrice()} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} /></Grid>
                            </Grid>
                        )
                    })
                }

                <Grid className="cart-row" container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
                    <Grid item xs={9}>Subtotale</Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}><NumberFormat value={subtotal} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} /></Grid>
                    {(props.position === 'in-cart' || shippingCost > 0) && 
                        <>
                        <Grid item xs={9}>Costi di consegna</Grid>
                        <Grid item xs={3} style={{ textAlign: 'right' }}><NumberFormat value={shippingCost} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} /></Grid>
                        </>
                    }
                    {/* <Grid item xs={9}>Sconto</Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}><NumberFormat value={0} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} /></Grid> */}
                </Grid>
                <Grid className="cart-row total" container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
                    <Grid item xs={9}><strong>Totale</strong></Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}><strong><NumberFormat value={subtotal + shippingCost} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} /></strong></Grid>
                </Grid>
                {
                    props.position === 'in-cart' ? (
                        null
                    ) : (
                        <>
                        {
                            (typeof user.id === 'undefined' || !user.isLoggedIn()) ? (
                                <Link to="/login" ><LoginButton variant="contained" color="primary" disableRipple>LOGIN</LoginButton></Link>
                            ) : (
                                <Link to="/cart" ><LoginButton variant="contained" color="primary" disableRipple>VAI ALLA CASSA</LoginButton></Link>
                            )
                        }
                        </>
                    )
                }
            </Container>
        ) : (
            <Container className="cart-container" style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                {
                    props.position === 'in-cart' ? (
                        <div className="no-products">Il carrello è vuoto, <strong style={{ cursor: 'pointer' }} onClick={() => window.history.back()}>torna al negozio</strong></div>
                    ) : (
                        <div className="no-products">Il carrello è vuoto, aggiungi un prodotto!</div>
                    )
                }
            </Container>
        )}
    </Paper>
  )
}

ShopCart.propTypes = {
    items: PropTypes.array.isRequired,
    shippingCost: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    getCartSubTotal: PropTypes.func.isRequired,
};

ShopCart.defaultProps = {
    items: [],
    shippingCost: 0,
    dispatch: () => undefined,
    onUpdate: () => undefined,
    getCartSubTotal: () => undefined,
};

export default ShopCart;