import React from 'react';
import { Link } from '@reach/router';
import { Store } from '../../contexts/Store/Store';
import { setShop } from '../../contexts/Store/actions';

import Spesa from '../../assets/spesa_miniatura.jpg';
import Casa from '../../assets/casa_giardino_miniatura.jpg';
import Cibo from '../../assets/gastronomia_miniatura.jpg';
import Riparazioni from '../../assets/manutenzioni_miniatura.jpg';
import Salute from '../../assets/salute_benessere_miniatura.jpg';
import Shopping from '../../assets/shopping_miniatura.jpg';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as NextArrow } from '../../assets/arrow-sx.svg';

import Scooter from '../../assets/condizioni/scooter-blu.svg';
import Take from '../../assets/condizioni/store-blu.svg';
import MetroCart from '../../assets/condizioni/metro-cart.svg';

import './Shop.scss';

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <NextArrow
      className={className}
      onClick={onClick}
    />
  );
}

const ShopGrid = (props) => {
  const { dispatch } = React.useContext(Store);
  const { activeCategory, shops, isOut, isPortal } = props;

  const categoryRewrite = isPortal === 1 ? `${activeCategory.info.rewrite}/`: '';

  let imageTop = Spesa;

  switch(activeCategory.info.id_lang){
    default:
    case '81':
        imageTop = Spesa;
        break;
    case '66':
        imageTop = Casa;
        break;
    case '69':
        imageTop = Cibo;
        break;
    case '72':
        imageTop = Riparazioni;
        break;
    case '75':
        imageTop = Salute;
        break;
    case '78':
        imageTop = Shopping;
        break;
  }

  const settings = {
      dots: false,
      infinite: true,
      arrows: !("ontouchstart" in document.documentElement),
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <SampleNextArrow />,
      nextArrow: <SampleNextArrow />
    };

  return(
      
        shops.map((shop, index) => 
          <div className="shop-card" key={`card-${index}`}>
            {(typeof shop.info != 'undefined' && Array.isArray(shop.info.image) && shop.info.image[0] != null) ? (
              <div className="shop-card-media">
                {
                  (shop.info.image.length > 1 && (isPortal !== 1)) ? (
                  <Slider {...settings}>
                    {shop.info.image.map((image, index) => 
                      <div key={`img-${index}`}><Link to={`./${shop.info['company_name'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w]/gi,' ').trim().replace(/\s+/g,'-').toLowerCase()}/${shop.id}`} title={shop.info.company_name} onClick={() => dispatch(setShop({ shop }))}><div className="shop-card-media-img" style={{ backgroundImage:`url(${image})`}}></div></Link></div>
                    )}
                  </Slider>
                  ) : (
                    <Link to={`./${categoryRewrite}${shop.info['company_name'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w]/gi,' ').trim().replace(/\s+/g,'-').toLowerCase()}/${shop.id}`} title={shop.info.company_name} onClick={() => dispatch(setShop({ shop }))}><div style={{ overflow: 'hidden' }}><div className="shop-card-media-img lonely" style={{ backgroundImage:`url(${shop.info.image[0]})`}}></div></div></Link>
                  )
                }
                <div className="shop-alerts">
                  {shop.info.tags.map((tag, index) => 
                    <div className="shop-first" key={`tag-${index}`}>{tag}</div>
                  )}
                </div>
              </div>
            ) : (
            <Link to={`./${categoryRewrite}${shop.info['company_name'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w]/gi,' ').trim().replace(/\s+/g,'-').toLowerCase()}/${shop.id}`} className="link_scheda" title={shop.info.company_name} onClick={() => dispatch(setShop({ shop }))}>
              <div className="shop-card-media">
                <div className="shop-card-media-img no-img" style={{ backgroundImage:`url(${imageTop})`}}></div>
                <div className="shop-alerts">
                  {shop.info.tags.map((tag, index) => 
                    <div className="shop-first" key={`tag-${index}`}>{tag}</div>
                  )}
                </div>
              </div>
            </Link>
            )}
            <Link to={`./${categoryRewrite}${shop.info['company_name'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w]/gi,' ').trim().replace(/\s+/g,'-').toLowerCase()}/${shop.id}`} className="link_scheda" title={shop.info.company_name} onClick={() => dispatch(setShop({ shop }))}>
              <div className="shop-card-content">
                {isOut === 1 ? (
                  <h3 className="shop-name">{shop.info.company_name} <span className="shop-city">{`(${shop.info.city})`}</span></h3>
                ) : (
                  <h3 className="shop-name">{shop.info.company_name}</h3>
                )}
                {
                  shop.info.delivery_free && shop.info.delivery_free == 1 &&
                    <><span className="consegna-gratuita">Consegna gratuita</span></>
                }
                <div className="buy-type-container">
                  {
                    (shop.info.delivery_mode && shop.info.delivery_mode !== null && shop.info.delivery_mode !== '' && shop.info.delivery_mode.indexOf('DELIVERY') !== -1) &&
                      <div className="delivery-type">
                        <img src={Scooter} alt="consegna a domicilio" width="21" />
                        <span>
                          <strong>DOMICILIO</strong>
                          {
                            shop.info.has_catalog == 1 && ((shop.info.first_slot && shop.info.first_slot.day !== null && shop.info.first_slot.day != "")) &&
                              <><br className="mob" /> Ordina ora, ricevi <strong>{shop.info.first_slot.day.toLowerCase()} {(shop.info.first_slot.hour && shop.info.first_slot.hour !== "" && shop.info.is_autoslot !== 1) ? `ore ${shop.info.first_slot.hour}` : ''}</strong></>
                          }
                        </span>
                      </div>
                  }
                  {
                    (shop.info.delivery_mode && shop.info.delivery_mode !== null && shop.info.delivery_mode !== '' && shop.info.delivery_mode.indexOf('TAKEAWAY') !== -1) &&
                      <div className="delivery-type">
                        <img src={Take} alt="ritiro per asporto" width="17" />
                        <span>
                          <strong>ASPORTO</strong>
                          {
                            shop.info.has_catalog == 1 && ((shop.info.first_slot && shop.info.first_slot.day !== null && shop.info.first_slot.day != "")) &&
                              <><br className="mob" /> Ordina ora, ritiri <strong>{shop.info.first_slot.day.toLowerCase()} {(shop.info.first_slot.hour && shop.info.first_slot.hour !== "" && shop.info.is_autoslot !== 1) ? `ore ${shop.info.first_slot.hour}` : ''}</strong></>
                          }
                        </span>
                      </div>
                  }
                </div>
                {/*
                <div>
                  consegna <NumberFormat value={shop.info.ship_cost} displayType={'text'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} prefix={'€ '} />
                  {typeof shop.info['slot_size_(min)'] !== "undefined" && shop.info['slot_size_(min)'] !== "" && `  ·  minimo d’ordine € ${shop.info['slot_size_(min)']}`}
                </div>
                {shop.info.next_slot.length > 0 &&
                  <div className="shop-alerts">
                    <div className="shop-first">Prima consegna<br className="mob" />{shop.info.next_slot[0].timing}</div>
                    <div className="shop-first">Primo ritiro<br className="mob" />{shop.info.next_slot[1].timing}</div>
                  </div>
                }
                */}
              </div>
            </Link>
          </div>
        )
  ) 
}

export default ShopGrid;