import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { Store } from '../../contexts/Store/Store';
import DeliveryPoint from '../../api/DeliveryPoint';
import DeliveryAddress, { BEACH_DELIVERY, HOME_DELIVERY, RESTAURANT_DELIVERY } from '../../api/DeliveryAddress';

import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/index.min.css';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'

import OpenStreetMap from '../Map/OpenStreetMap';
import Alert from '../Utils/Alert';

const CheckButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Gotham Bold',
    fontSize: '1em',
    padding: '5px 20px',
    border: '1px solid',
    lineHeight: 1.5,
    display: 'block',
    marginTop:'30px',
    backgroundColor: '#009fe2',
    borderColor: '#009fe2',
    borderRadius: '30px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&.active': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&.deactive': {
        backgroundColor: '#ccc',
        borderColor: '#ccc',
        color: '#666',
        cursor: 'not-allowed'
    },
    '&.deactive&:hover': {
        boxShadow: 'none'
    },
  },
})(Button);

const ShowMapType = (props) => {
    const { addressType, setUserAddress, userAddressValid, onSelect } = props;
    const { state } = React.useContext(Store);

    const [markerPosition, setMarkerPosition] = React.useState([parseFloat(state.shop.info.georeferencing.lat), parseFloat(state.shop.info.georeferencing.lng)]);
    const addressComposite = state.shop.info.address+' '+state.shop.info.city;
    const [deliveryPointProperties, setDeliveryPointProperties] = React.useState({
        latitude: parseFloat(state.shop.info.georeferencing.lat),
        longitude: parseFloat(state.shop.info.georeferencing.lng),
        address: addressComposite,
    });
    const [beachMarkers, setBeachMarkers] = React.useState([]);
    //const [smsVerified, setSmsVerified] = React.useState(false);
    const [openSmsError, setOpenSmsError] = React.useState(false);
    const [openNumberError, setOpenNumberError] = React.useState(false);
    const [openAlertAddress, setOpenAlertAddress] = React.useState(false);
    const [openAlertError, setOpenAlertError] = React.useState(false);
    const [openAlertErrorBeach, setOpenAlertErrorBeach] = React.useState(false);
    const [openAlertNotCovered, setOpenAlertNotCovered] = React.useState(false);
    const [showErrors, setShowErrors] = React.useState(false);
    const [showErrorsTakeAway, setShowErrorsTakeAway] = React.useState(false);
    const [beachAddress, setBeachAddress] = React.useState({ latitude: "", longitude: ""});
    const [address, setAddress] = React.useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        latitude: 0,
        longitude: 0,
    });
    const [values, setValues] = React.useState({
        firstName: '',
        lastName: '',
        phone: '',
        phone2: '',
        email: '',
        address: '',
        formErrors: {
            firstName: '',
            lastName: '',
            phone: '',
            phone2: '',
            email: '',
            address: '',
        },
        firstNameValid: null,
        lastNameValid: null,
        phoneValid: null,
        phone2Valid: null,
        emailValid: null,
        addressValid: null,
        formValid: false,
        isRegistering: false,
        isRegistered: false,
    });
    const [valuesTakeAway, setValuesTakeAway] = React.useState({
        firstName: '',
        lastName: '',
        phone: '',
        phone2: '',
        email: '',
        formErrors: {
            firstName: '',
            lastName: '',
            phone: '',
            phone2: '',
            email: '',
        },
        firstNameValid: null,
        lastNameValid: null,
        phoneValid: null,
        phone2Valid: null,
        emailValid: null,
        formValid: false,
        isRegistering: false,
        isRegistered: false,
    });

    React.useEffect(() => {
        if(addressType === HOME_DELIVERY){
            if(!values.formValid){
                return;
            }
            setUserAddress(false);
            const id_catalog = state.shop.info.id_catalog
            const deliveryPoint = DeliveryPoint.withGoogle(address);
            deliveryPoint.isCovered(id_catalog)
            .then((is_covered) => {
                if(is_covered){
                    setDeliveryPointProperties(deliveryPoint.getProperties());
                    setMarkerPosition(deliveryPoint.getPosition());
                    onSelect(new DeliveryAddress({
                        ...deliveryPoint.getProperties(),
                        firstName: address.firstName,
                        lastName: address.lastName,
                        phone: address.phone,
                        email: address.email,
                        type: HOME_DELIVERY,
                    }));
                    setUserAddress(true);
                    setOpenAlertNotCovered(false);
                } else {
                    setOpenAlertNotCovered(true);
                    setUserAddress(false);
                }
            })
            .catch(err => {
                setOpenAlertNotCovered(true);
                setUserAddress(false);
            });
        } else if(addressType === RESTAURANT_DELIVERY){
            if(!valuesTakeAway.formValid){
                return;
            }
            setUserAddress(false);
            DeliveryPoint.fromAddress({
                street: state.shop.info.address,
                number: "",
                city: state.shop.info.city,
            })
            .then((deliverypoint) => {
                setMarkerPosition([deliverypoint.latitude, deliverypoint.longitude]);
            });
            onSelect(new DeliveryAddress({
                latitude: state.shop.info.georeferencing.lat,
                longitude: state.shop.info.georeferencing.lng,
                address: addressComposite,
                firstName: address.firstName,
                lastName: address.lastName,
                phone: address.phone,
                email: address.email,
                type: RESTAURANT_DELIVERY,
            }));
            setUserAddress(true);
        }
    }, [address]);

    React.useEffect(() => {
        if(beachAddress.latitude === '' || beachAddress.longitude === '' || beachAddress.address === ''){
            return;
        } else {
            onSelect(new DeliveryAddress({
                ...beachAddress,
                type: BEACH_DELIVERY,
            }));
            setUserAddress(true);
        }
    }, [beachAddress]);

    React.useEffect(() => {
        if(addressType === HOME_DELIVERY){
            setUserAddress(false);
            verifyAddress();
        }
        else if(addressType === BEACH_DELIVERY){
            setUserAddress(false);
            setBeachAddress({
                latitude: "",
                longitude: "",
                address: "",
            });
            DeliveryPoint.fromAddress({
                street: "",
                number: "",
                city: "Bibione",
            })
            .then((deliverypoint) => {
                setMarkerPosition([deliverypoint.latitude, deliverypoint.longitude]);
                getDeliveryPointOnTheBeach();
                setUserAddress(false);
            });
        } else if(addressType === RESTAURANT_DELIVERY){
            setUserAddress(false);
            if(state.user.isShop){
                return;
            }

            DeliveryPoint.fromAddress({
                street: state.shop.info.address,
                number: "",
                city: state.shop.info.city,
            })
            .then((deliverypoint) => {
                setMarkerPosition([deliverypoint.latitude, deliverypoint.longitude]);
            });
            onSelect(new DeliveryAddress({
                latitude: state.shop.info.georeferencing.lat,
                longitude: state.shop.info.georeferencing.lng,
                address: addressComposite,
                firstName: address.firstName,
                lastName: address.lastName,
                phone: address.phone,
                email: address.email,
                type: RESTAURANT_DELIVERY,
            }));
            setUserAddress(true);
        }
    }, [addressType]);

    function handleCloseAlertAddress() {
        setOpenAlertAddress(false);
    }
    function handleCloseAlertError() {
        setOpenAlertError(false);
    }
    function handleCloseSmsError() {
        setOpenSmsError(false);
    }
    function handleCloseNumberError() {
        setOpenNumberError(false);
    }
    function handleCloseAlertErrorBeach() {
        setOpenAlertErrorBeach(false);
    }
    function handleCloseAlertNotCovered() {
        setOpenAlertNotCovered(false);
    }

    /* function smsCode() {
        //chiama verifica sms
        setSmsVerified(true);
        setOpenSmsError(false);
    } */

    async function getDeliveryPointOnTheBeach(){
        try {
            const list = await DeliveryPoint.onTheBeach();
            setBeachMarkers(list);
            setOpenAlertErrorBeach(false);
        } catch(error) {
            setOpenAlertErrorBeach(true);
        }
    }

    function getMarkerPosition(position) {
        setBeachAddress({
            latitude: position.latitude,
            longitude: position.longitude,
            address: position.address,
        });
    }

    const handleChange = (prop, event, val, data, formattedValue) => {
        let fieldValidationErrors = values.formErrors;
        let firstNameValid = values.firstNameValid;
        let lastNameValid = values.lastNameValid;
        let emailValid = values.emailValid;
        let phoneValid = values.phoneValid;
        let phone2Valid = values.phone2Valid;
        let addressValid = values.addressValid;

        let eventValue = (event && event.target) ? event.target.value : val;

        setUserAddress(false);

        switch(prop) {
            case 'firstName':
                firstNameValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.firstName = firstNameValid ? '' : 'il campo nome è vuoto';
                break;
            case 'lastName':
                lastNameValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.lastName = lastNameValid ? '' : 'il campo cognome è vuoto';
                break;
            case 'phone':
                let p = eventValue.slice(data.dialCode.length + 1);
                phoneValid = !(/^ *$/).test(p);
                phone2Valid = eventValue === values.phone2;
                fieldValidationErrors.phone = phoneValid ? '' : 'il campo telefono è vuoto';
                fieldValidationErrors.phone2 = phone2Valid ? '' : 'il campo conferma telefono non corrisponde';
                break;
            case 'phone2':
                phone2Valid = eventValue === values.phone;
                fieldValidationErrors.phone2 = phone2Valid ? '' : 'il campo conferma telefono non corrisponde';
                break;
            case 'email':
                emailValid = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(eventValue);
                fieldValidationErrors.email = emailValid ? '' : 'il campo email non è valido';
                break;
            case 'address':
                addressValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.address = addressValid ? '' : 'il campo indirizzo è vuoto';
                break;
            default:
                break;
        }
        setValues({ 
            ...values, 
            [prop]: eventValue,
            formErrors: { ...fieldValidationErrors },
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            phoneValid: phoneValid,
            phone2Valid: phone2Valid,
            emailValid: emailValid,
            addressValid: addressValid,
            formValid: state.user.isShop ? (firstNameValid && phoneValid && phone2Valid && addressValid) : addressValid
        });
    };

    const handleChangeTakeAway = (prop, event, val, data, formattedValue) => {
        let fieldValidationErrors = valuesTakeAway.formErrors;
        let firstNameValid = valuesTakeAway.firstNameValid;
        let lastNameValid = valuesTakeAway.lastNameValid;
        let emailValid = valuesTakeAway.emailValid;
        let phoneValid = valuesTakeAway.phoneValid;
        let phone2Valid = valuesTakeAway.phone2Valid;

        let eventValue = (event && event.target) ? event.target.value : val;

        setUserAddress(false);

        switch(prop) {
            case 'firstName':
                firstNameValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.firstName = firstNameValid ? '' : 'il campo nome è vuoto';
                break;
            case 'lastName':
                lastNameValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.lastName = lastNameValid ? '' : 'il campo cognome è vuoto';
                break;
            case 'phone':
                let p = eventValue.slice(data.dialCode.length + 1);
                phoneValid = !(/^ *$/).test(p);
                phone2Valid = eventValue === valuesTakeAway.phone2;
                fieldValidationErrors.phone = phoneValid ? '' : 'il campo telefono è vuoto';
                fieldValidationErrors.phone2 = phone2Valid ? '' : 'il campo conferma telefono non corrisponde';
                break;
            case 'phone2':
                phone2Valid = eventValue === valuesTakeAway.phone;
                fieldValidationErrors.phone2 = phone2Valid ? '' : 'il campo conferma telefono non corrisponde';
                break;
            case 'email':
                emailValid = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(eventValue);
                fieldValidationErrors.email = emailValid ? '' : 'il campo email non è valido';
                break;
            default:
                break;
        }
        setValuesTakeAway({ 
            ...valuesTakeAway, 
            [prop]: eventValue,
            formErrors: { ...fieldValidationErrors },
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            phoneValid: phoneValid,
            phone2Valid: phone2Valid,
            emailValid: emailValid,
            formValid: firstNameValid && phoneValid && phone2Valid
        });
    };

    function verifyAddress(){
        if(values.formValid) {
            setAddress({
                ...address,
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
                email: values.email,
            });
        } else {
            setUserAddress(false);
            return;
        }
    }
    function verifyAddressTakeAway(){
        if(valuesTakeAway.formValid) {
            setAddress({
                ...address,
                firstName: valuesTakeAway.firstName,
                lastName: valuesTakeAway.lastName,
                phone: valuesTakeAway.phone,
                email: valuesTakeAway.email,
            });
        } else {
            setUserAddress(false);
            return;
        }
    }

    function resetAutocomplete(){
        const googleInput = document.getElementById('react-google-places-autocomplete-input');
        /* console.log(googleInput);
        console.log(googleInput.value); */
        googleInput.value = "";
    }

    function getLL(obj){
        let formatted_address = '';
        geocodeByPlaceId(obj.place_id)
        .then((results) => {
            const hasNumber = JSON.stringify(results[0].address_components).includes("street_number");
            if(hasNumber === false){
                setValues({ 
                    ...values, 
                    addressValid: false,
                    formValid: false
                });
                setOpenNumberError(true);
                setUserAddress(false);
                return;
            } else {
                setOpenNumberError(false);
            }
            formatted_address = results[0].formatted_address;
            setValues({ 
                ...values, 
                addressValid: true,
                formValid: state.user.isShop ? (values.firstNameValid && values.phoneValid && values.phone2Valid) : true
            });
            return getLatLng(results[0])
        })
        .then((obj) => {
            setAddress({
                ...address,
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
                email: values.email,
                address: formatted_address,
                latitude: obj.lat,
                longitude: obj.lng,
            });
        })
        .catch(err => {
            setValues({ 
                ...values, 
                addressValid: false,
                formValid: false
            });
            setUserAddress(false);
        });
    }


    if (addressType === HOME_DELIVERY) {
        return (
            <>
            <Alert open={openNumberError} onClose={handleCloseNumberError} title="Attenzione" content={`L'indirizzo inserito non contiene il numero civico`} />
            <Alert open={openSmsError} onClose={handleCloseSmsError} title="Attenzione" content={`Codice di verifica non riconosciuto`} />
            <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Compilare tutti i campi`} />
            <Alert open={openAlertAddress} onClose={handleCloseAlertAddress} title="Attenzione" content={`L'indirizzo inserito non è stato trovato`} />
            <Alert open={openAlertNotCovered} onClose={handleCloseAlertNotCovered} title="Attenzione" content={`L'indirizzo inserito non è raggiunto dal nostro servizio`} />
            <Container className="cart-info-container">
                <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
                    <Grid item xs={12} sm={userAddressValid ? 6 : 12}>
                        <strong>Indirizzo di consegna</strong><br className="mob" />
                        <span style={{ fontSize: '70%' }}>La via che hai indicato non viene riconosciuta dalla mappa? Inserisci una via vicina e segnala nelle note la via corretta</span>
                        <div className="delivery-address">
                            {state.user.isShop &&
                                <>
                                <input type="text" className={values.firstNameValid === false ? 'show-error' : ''} name="firstName" placeholder="nome *" onChange={(e) => handleChange('firstName', e)} value={values.firstName} />
                                <input type="text" name="lastName" placeholder="cognome" onChange={(e) => handleChange('lastName', e)} value={values.lastName} />
                                <label className="phone-label">telefono *</label>
                                <PhoneInput 
                                    autoFormat={false}
                                    inputClass={values.phoneValid === false ? 'form-control show-error' : 'form-control'}
                                    style={{ zIndex: 4 }}
                                    preferredCountries={['it']}
                                    countryCodeEditable={false}
                                    enableSearch={true}
                                    country={'it'}
                                    value={values.phone}
                                    onChange={(value, data, event, formattedValue) => handleChange('phone', event, value, data, formattedValue)}
                                />
                                <label className="phone-label">conferma telefono *</label>
                                <PhoneInput 
                                    autoFormat={false}
                                    inputClass={values.phone2Valid === false ? 'form-control show-error' : 'form-control'}
                                    preferredCountries={['it']}
                                    countryCodeEditable={false}
                                    enableSearch={true}
                                    country={'it'}
                                    value={values.phone2}
                                    onChange={(value, data, event, formattedValue) => handleChange('phone2', event, value, data, formattedValue)}
                                />
                                {/* <input type="text" className={values.phoneValid === false ? 'show-error' : ''} name="phone" placeholder="telefono *" onChange={(e) => handleChange('phone', e)} value={values.phone} />
                                <input type="text" className={values.phone2Valid === false ? 'show-error' : ''} name="phone2" placeholder="conferma telefono *" onChange={(e) => handleChange('phone2', e)} value={values.phone2} /> */}
                                <input type="text" name="email" placeholder="email" onChange={(e) => handleChange('email', e)} value={values.email} />
                                </>
                            }
                            <div className="GooglePlacesAutocomplete">
                                <GooglePlacesAutocomplete
                                    placeholder="inserisci indirizzo, hotel, luogo di interesse..."
                                    apiKey="AIzaSyDTIImnlq--3X3P_W5UDn1SP93-sC3qp38"
                                    onSelect={(obj) => getLL(obj)}
                                    autocompletionRequest = {{
                                        componentRestrictions: {
                                            country: ['it']
                                        }
                                    }}
                                />
                                <span className="reset" onClick={resetAutocomplete}>x</span>
                            </div>
                            {
                                values.formValid ? <CheckButton variant="contained" color="primary" disableRipple onClick={() => verifyAddress()}>verifica indirizzo</CheckButton>
                                : <CheckButton variant="contained" color="primary" onClick={() => setShowErrors(true)} disableRipple className="deactive">verifica indirizzo</CheckButton>
                            }
                            {!values.formValid && showErrors && values.firstNameValid === false && <span className="form-errors">{values.formErrors.firstName}</span>}
                            {!values.formValid && showErrors && values.phoneValid === false && <span className="form-errors">{values.formErrors.phone}</span>}
                            {!values.formValid && showErrors && values.phone2Valid === false && <span className="form-errors">{values.formErrors.phone2}</span>}
                            {!values.formValid && showErrors && values.addressValid === false && <span className="form-errors">Il campo indirizzo è vuoto</span>}
                        </div>
                    </Grid>
                    {
                        userAddressValid && 
                            <Grid item xs={12} sm={6}>
                                <OpenStreetMap
                                    center={markerPosition}
                                    zoom={14}
                                    maxZoom={19}
                                    markers={[deliveryPointProperties]}
                                    onMarkerClick={getMarkerPosition}
                                />
                            </Grid>
                    }
                </Grid>
            </Container>
            </>
        )
    } else if (addressType === BEACH_DELIVERY) {
        return (
            <>
            <Alert open={openAlertErrorBeach} onClose={handleCloseAlertErrorBeach} title="Attenzione" content={`Si è verificato un errore`} />
            <Container className="cart-info-container">
                <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
                    <Grid item xs={12}>
                        <OpenStreetMap
                            center={markerPosition}
                            zoom={14}
                            maxZoom={19}
                            markers={beachMarkers.map((deliveryPoint) => deliveryPoint.getProperties())}
                            onMarkerClick={getMarkerPosition}
                        />
                        {beachAddress.longitude !== "" && <div className="info-beach-map">Luogo di consegna: <strong>{beachAddress.address}</strong></div>}
                    </Grid>
                </Grid>
            </Container>
            </>
        )
    } else if (addressType === RESTAURANT_DELIVERY) {
        return (
            <>
            <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Compilare tutti i campi`} />
            <Alert open={openAlertAddress} onClose={handleCloseAlertAddress} title="Attenzione" content={`L'indirizzo inserito non è stato trovato`} />
            <Container className="cart-info-container">
                <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={1}>
                    {
                        state.user.isShop ? (
                            <>
                            <Grid item xs={12} sm={6}>
                                <strong>Nominativo</strong>
                                <div className="delivery-address">
                                    <input type="text" className={valuesTakeAway.firstNameValid === false ? 'show-error' : ''} name="firstName" placeholder="nome *" onChange={(e) => handleChangeTakeAway('firstName', e)} value={valuesTakeAway.firstName} />
                                    <input type="text" name="lastName" placeholder="cognome" onChange={(e) => handleChangeTakeAway('lastName', e)} value={valuesTakeAway.lastName} />
                                    <label className="phone-label">telefono *</label>
                                    <PhoneInput 
                                        autoFormat={false}
                                        inputClass={valuesTakeAway.phoneValid === false ? 'form-control show-error' : 'form-control'}
                                        style={{ zIndex: 4 }}
                                        preferredCountries={['it']}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        country={'it'}
                                        value={valuesTakeAway.phone}
                                        onChange={(value, data, event, formattedValue) => handleChangeTakeAway('phone', event, value, data, formattedValue)}
                                    />
                                    <label className="phone-label">conferma telefono *</label>
                                    <PhoneInput 
                                        autoFormat={false}
                                        inputClass={valuesTakeAway.phone2Valid === false ? 'form-control show-error' : 'form-control'}
                                        preferredCountries={['it']}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        country={'it'}
                                        value={valuesTakeAway.phone2}
                                        onChange={(value, data, event, formattedValue) => handleChangeTakeAway('phone2', event, value, data, formattedValue)}
                                    />
                                    <input type="text" name="email" placeholder="email" onChange={(e) => handleChangeTakeAway('email', e)} value={valuesTakeAway.email} />
                                    <br /><span className="obbligatori">* campi obbligatori</span><br />
                                    {
                                        valuesTakeAway.formValid ? <CheckButton variant="contained" color="primary" disableRipple onClick={() => verifyAddressTakeAway()}>invia</CheckButton>
                                        : <CheckButton variant="contained" color="primary" onClick={() => setShowErrorsTakeAway(true)} disableRipple className="deactive">invia</CheckButton>
                                    }
                                    {!valuesTakeAway.formValid && setShowErrorsTakeAway && valuesTakeAway.firstNameValid === false && <span className="form-errors">{valuesTakeAway.formErrors.firstName}</span>}
                                    {!valuesTakeAway.formValid && setShowErrorsTakeAway && valuesTakeAway.phoneValid === false && <span className="form-errors">{valuesTakeAway.formErrors.phone}</span>}
                                    {!valuesTakeAway.formValid && setShowErrorsTakeAway && valuesTakeAway.phone2Valid === false && <span className="form-errors">{valuesTakeAway.formErrors.phone2}</span>}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className="info-beach-map">
                                    <strong>{state.shop.info.company_name}</strong><br />
                                    {state.shop.info.address+' - '+state.shop.info.city}
                                </div>
                                <OpenStreetMap
                                    center={markerPosition}
                                    zoom={14}
                                    maxZoom={19}
                                    markers={[deliveryPointProperties]}
                                    onMarkerClick={getMarkerPosition}
                                />
                            </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <div className="info-beach-map">
                                    <strong>{state.shop.info.company_name}</strong><br />
                                    {state.shop.info.address+' - '+state.shop.info.city}
                                </div>
                                <OpenStreetMap
                                    center={markerPosition}
                                    zoom={14}
                                    maxZoom={19}
                                    markers={[deliveryPointProperties]}
                                    onMarkerClick={getMarkerPosition}
                                />
                            </Grid>
                        )
                    }
                </Grid>
                
            </Container>
            </>
        )
    }
}

export default ShowMapType;