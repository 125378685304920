import 'whatwg-fetch';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL_1_3 = process.env.REACT_APP_API_URL_1_3;

export default {
    get,
    post,
    put,
    auth,
};

/**
 * Invia una richiesta GET al server e ne restituisce la risposta
 * @param {string} url 
 * @returns {object}
 * @throws {Error}
 */
async function get(url, headers = {}) {
    url = process.env.REACT_APP_IS_DEV == 1 ? `${url}?dev=1` : url;
    try {
        const response = await request({ url, headers });
        return response;
    } catch (error) {
        throw error;
    }
}

/**
 * Invia una richiesta POST al server e ne restituisce la risposta
 * @param {string} url 
 * @param {object} data 
 * @returns {object}
 * @throws {Error}
 */
async function post(url, data, headers = {}) {
    url = process.env.REACT_APP_IS_DEV == 1 ? `${url}?dev=1` : url;
    try {
        const response = await request({ 
            url, 
            method: "POST", 
            body: data,
            headers,
        });
        return response;
    } catch (error) {
        throw error;
    }
}

/**
 * Invia una richiesta PUT al server e ne restituisce la risposta
 * @param {string} url 
 * @param {object} data 
 * @returns {object}
 * @throws {Error}
 */
async function put(url, data) {
    try {
        const response = await request({ 
            url, 
            method: "PUT", 
            body: data,
        });
        return response;
    } catch (error) {
        throw error;
    }
}

async function auth(url, credentials) {
    try {
        const response = await fetch(url, { 
            method: "POST",
            headers: new Headers({
                "Content-Type": "text/plain",
            }),
            body: JSON.stringify({
                grant_type: "client_credentials",
                ...credentials
            }),
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        return data;
    } catch (error) {
        throw error;
    }
}

/**
 * Effettua il parsing della risposta del server
 * @param {object} data 
 * @returns {object}
 * @throws {Error}
 */
function parseResponseBody(data) {
    if (!data.success) {
        const { error } = data;
        const message = typeof error === "string" ? error: JSON.stringify(error);
        throw new Error(message);
    }

    return data.info;
}

/**
 * Esegue una richiesta al server
 * @param {string} url 
 * @param {string} method 
 * @param {object} body
 * @returns {object} la risposta del server
 * @throws {Error} 
 */
async function request({ url, method = "GET", body = null, headers = {} }) {
    const options = {
        mode: 'cors',
        credentials: process.env.REACT_APP_FETCH_OPTION_CREDENTIALS,
        method,
        headers: new Headers({
            "Content-Type": "application/json",
            //"Content-Type": "text/plain",
            ...headers,
        }),
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    try {
        //console.log(url, options);
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("text/html")) {
            const text = await response.text();
            return parseResponseBody(JSON.parse(text));
        }

        const data = await response.json();

        return parseResponseBody(data);
    } catch (error) {
        throw error;
    }
}
