import React from 'react';
import './docs.css';
import userData from '../../api/__tests__/fixtures/user.json';
import tokenData from '../../api/__tests__/fixtures/token.json';
import User from '../../api/User';
import Token from '../../api/Token';
import console from './helpers';

const UserDocs = (props) => {

    const token = Token.load(tokenData);
    token.store();
    console.pretty("token", token, "dir");

    const user = new User({
        ...userData,
        token,
    });
    console.pretty("user", user, "dir");

    return (
        <div className={"code"}>
            <h1>User docs</h1>
            <p>
                <span role="img" aria-label="wait">✋</span> 
                &nbsp;Apri la <strong>console</strong> per vedere alcuni esempi <em>live</em>&nbsp; 
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
            </p>
            <h3 id="api-user">API User</h3>
            <p>Gli oggetti User hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>id</strong> id numerico dell'utente</li>
                <li><strong>firstName</strong> nome dell'utente</li>
                <li><strong>lastName</strong> cognome dell'utente</li>
                <li><strong>email</strong> email dell'utente</li>
                <li><strong>phone</strong> numero mobile dell'utente</li>
                <li><strong>token</strong> oggetto Token che gestisce info sull'autenticazione</li>
                <li><strong>deliveryAddress</strong> oggetto DeliveryAddress per memorizzare l'indirizzo di consegna</li>
            </ul>
            <p>i seguenti metodi <strong>statici</strong> che restituiscono un'istanza di User:</p>
            <ul>
                <li><strong>create(&#123;<em>firstName</em>,<em>lastName</em>,<em>email</em>,<em>phone</em>,<em>password</em>&#125;)</strong> per registrare un nuovo utente</li>
                <li><strong>update(&#123;<em>email</em>,<em>password</em>&#125;)</strong> per aggiornare l'account di un utente mobile con la password</li>
                <li><strong>login(&#123;<em>email</em>,<em>password</em>&#125;, <em>token</em>)</strong> per effettuare il login</li>
            </ul>
            <p>e i seguenti metodi d'istanza:</p>
            <ul>
                <li><strong>logout()</strong> per effettuare il logout</li>
                <li><strong>isLoggedIn()</strong> per verificare lo stato dell'utente</li>
                <li><strong>setDeliveryAddress(<em>deliveryAddress</em>)</strong> per impostare l'indirizzo di spedizione contenuto in un oggetto DeliveryAddress</li>
            </ul>
            <pre>
                <code>
                    {`
                        import User from '../../api/User';
                        ...
                        
                        // Per registrare un nuovo utente (niente app)
                        try {
                            const user = User.create({
                                firstName: "Pino",
                                lastName: "Silvestre",
                                email: "pino@silvestre.it",
                                phone: "1234567890",
                                password: "superfresh",
                            });
                        } catch(error) {
                            // Restituisce un errore se i dati sono incompleti
                            // o se il server fa le bizze
                        }

                        // Per aggiornare un utente già registratosi con l'app
                        try {
                            const user = User.update({
                                email: "pino@silvestre.it",
                                password: "superfresh",
                            });
                        } catch(error) {
                            // Restituisce un errore se i dati sono incompleti
                            // o se il server fa le bizze
                        }

                        // In entrambi i casi user sarà qualcosa del tipo
                        // ${JSON.stringify({id: 1, ...userData})}

                        // Se token è un'istanza di Token (documentato più sotto), 
                        // il login si può effettuare così:
                        try {
                            const user = User.login({
                                email: "pino@silvestre.it",
                                password: "superfresh",
                            }, token);
                        } catch(error) {
                            // Restituisce un errore se i dati sono incompleti
                            // o se il server fa le bizze
                        }

                        // Qui user sarà come sopra, ma con in più la proprietà
                        // token:
                        // ${JSON.stringify({id: 1, ...userData, token})}

                        // Una volta loggato, per il logout è sufficiente invocare
                        // l'omonimo metodo:
                        try {
                            // result è true se va tutto bene. In più il token
                            // viene eliminato.
                            const result = user.logout();
                        } catch(error) {
                            // Si finisce qui se il token non è stato definito
                            // o non è un'istanza di Token
                        }     
                    `}
                </code>
            </pre>
            <h3 id="api-token">API Token</h3>
            <p>Gli oggetti Token hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>accessToken</strong> valore del token di accesso restituito dal server</li>
                <li><strong>info</strong> oggetto contenente dati correlati al token restituiti dal server</li>
            </ul>
            <p>i seguenti metod <strong>static</strong> che restituiscono un'istanza di Token:</p>
            <ul>
                <li><strong>request(&#123;<em>email</em>,<em>password</em>&#125;)</strong> per ottenere un token dal server</li>
                <li><strong>load(<em>source</em>)</strong> per creare un'istanza di Token caricandolo dal JSON restituto dal server oppure dallo storage</li>
            </ul>
            <p>e i seguenti metodi d'istanza:</p>
            <ul>
                <li><strong>store(&#123;<em>storage</em>&#125;)</strong> per memorizzare il token nello storage indicato che di default è <em>sessionStorage</em></li>
                <li><strong>forget()</strong> per rimuovere il token dallo storage</li>
                <li><strong>get()</strong> per ottenere il token dallo storage</li>
            </ul>
            <pre>
                <code>
                    {`
                        import Token from '../../api/Token';
                        ...

                        // Per ottenere un token dal server e memorizzarlo 
                        // in sessionStorage, si può fare così:
                        try {
                            const token = await Token.request({
                                email: "pino@silvestre.it",
                                password: "superfresh",
                            });
                            token.store();
                        } catch(error) {
                            // qui si finisce se non ci sono le credenziali complete
                            // o se il server restituisce un errore
                        }

                        // Il risultato è qualcosa così:
                        // ${JSON.stringify(token)}

                        // Poi per leggere il valore dallo storage, si può
                        // usare get():
                        const accessToken = token.get();
                        // ${token.get()}

                        // Per eliminarlo dallo storage c'è il metodo forget()
                        // che ritorna true se tutto ha funzionato:
                        const result = token.forget();

                        // Per creare un oggetto Token partendo dal valore
                        // memorizzato in sessionStorage, si può fare così:
                        const token = Token.load();
                    `}
                </code>
            </pre>
        </div>
    );
};

export default UserDocs;