import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { navigate } from '@reach/router';
import FoodSubcategories from './FoodSubcategories';
import Alert from '../Utils/Alert';
import Category from '../../api/Category';

import TagManager from 'react-gtm-module'

const pushZone = (x) => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview',
        'categoryComune': x,
        'shopName': ''
    },
    dataLayerName: 'pageview'
});
const pushCat = (x) => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview',
        'businessCategory': x,
        'shopName': ''
    },
    dataLayerName: 'pageview'
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  centerize: {
    justifyContent: 'center',
  },
  transparent: {
    textAlign: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    paddingLeft:20,
    paddingRight:20,
  },
}));

let distanceTop = 0;

const FoodCategories = (props) => {
    const classes = useStyles();
    const { zone, activeCategory } = props;
    const [categories, setCategories] = React.useState([]);
    //const [activeCategory, setCategory] = React.useState([]);
    //const [subcategories, setSubcategories] = React.useState([]);
    const [openAlertError, setOpenAlertError] = React.useState(false);

    const [isSticky, setIsSticky] = React.useState(false)
    const ref = React.createRef()
    
    window.addEventListener('scroll', checkRef);

    function checkRef(){
        document.documentElement.scrollTop >= distanceTop ? setIsSticky(true) : setIsSticky(false);
    }

    React.useEffect(() => {

      if(ref && ref.current){
        distanceTop = ref.current.getBoundingClientRect().y + document.documentElement.scrollTop;
      }

      if (!zone.id) {
        return;
      }
      pushZone(zone.name);

      Category.listForZone(zone)
        .then((list) => {
          //setCategory(currentCat);
          setCategories(list);
          setOpenAlertError(false);
          //return list[0].loadSubcategories();
        })
        /* .then((firstCategory) => {
          setSubcategories(firstCategory.subcategories);
        }) */
        .catch((error) => {
            //console.log(error);
            setOpenAlertError(true);
        });

        return () => {
          // executed when unmount
          ref.current = false;
        }
    }, [zone]);

    function handleCloseAlertError(){
        setOpenAlertError(false);
    }


    function openCategory(){
      let mobileList = document.getElementById('mobileList');
      if(mobileList && !mobileList.classList.contains('mobile-list-show')){
        mobileList.classList.add('mobile-list-show');
      }
      else if(mobileList && mobileList.classList.contains('mobile-list-show')){
        mobileList.classList.remove('mobile-list-show');
      } else {
        return;
      }
    }

    async function handleClick(category) {
      let mobileList = document.getElementById('mobileList');
      if(mobileList){
        mobileList.classList.remove('mobile-list-show');
      }
      pushCat(category.info.name);
      window.scrollTo(0, 0);
      navigate(`${category.info.rewrite}`);
      /* setCategory(category);
      pushCat(category.info.name);
      window.scrollTo(0, 0); */
      //setSubcategories(await category.getSubcategories());
    }

  return(
    <div>
      <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Si è verificato un errore nel caricamento dei dati.`} />
      <Container style={{ maxWidth: '1180px' }} className="food-type-container" fixed ref={ref}>
        <Hidden only={['md', 'lg', 'xl']}>
          <Grid container direction="column" justify="center" alignItems="stretch" style={{ flexWrap: 'wrap' }} spacing={0}>
            { 
              (activeCategory && typeof activeCategory.info != 'undefined' && typeof activeCategory.info.name != 'undefined') && 
              <MenuList>
                <MenuItem className={classes.centerize} value={activeCategory.info.name} onClick={openCategory}>
                  <ListItemIcon>
                    <img src={activeCategory.info.svg} alt={activeCategory.info.svg_title} width="25" />
                  </ListItemIcon>
                  <Typography variant="inherit">{activeCategory.info.name}</Typography>
                  <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>
                </MenuItem>
              </MenuList>
            }
            <Paper id="mobileList" className="mobile-list">
              <MenuList>
              {
                categories.map((category, index) => 
                    <MenuItem value={category.info.name} key={`category-${index}`} onClick={() => handleClick(category)}>
                      <ListItemIcon>
                        <img src={category.info.svg} alt={category.info.svg_title} width="25" />
                      </ListItemIcon>
                      <Typography variant="inherit">{category.info.name}</Typography>
                    </MenuItem>
                )
              }
              </MenuList>
            </Paper>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid container direction="row" justify="center" alignItems="stretch" spacing={0}>
          {isSticky && <div className="categories-grid zone-name isSticky">{zone.name}</div>}
          {
            categories.map((category, index) => 
                <div className={"categories-grid" + (isSticky ? " isSticky" : "")} key={`category-${index}`}>
                  <div className="food-paper" onClick={() => handleClick(category)}>
                    <div className={activeCategory.info.name === category.info.name ? "food-type active" : "food-type"}><span className={"food-type-img" + (isSticky ? " isSticky" : "")}><img src={category.info.svg} alt={category.info.svg_title} className="food-img" /></span><span className={"food-type-text" + (isSticky ? " isSticky" : "")}>{category.info.name}</span></div>
                  </div>
                </div>
            )
          }
          </Grid>
        </Hidden>
      </Container>
      {zone && activeCategory && <FoodSubcategories zone={zone} activeCategory={activeCategory} />}
      {/*
      {subcategories.length > 0 ? (
        <FoodSubcategories zone={zone} activeCategory={activeCategory} subcategories={subcategories}/>
      ) : (
        <p style={{ textAlign: 'center', padding: '30px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>
      )}
      */}
    </div>
  )
}

FoodCategories.propTypes = {
  zone: PropTypes.object.isRequired,
};

FoodCategories.defaultProps = {
  zone: {},
};

export default FoodCategories;