import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'
import smoothscroll from 'smoothscroll-polyfill';
import { ReactComponent as Onda } from '../../assets/onda.svg';
import Referente from '../../assets/azienda/referente.svg';
import Azienda from '../../assets/motivi/1.svg';
import Consegna from '../../assets/azienda/consegna.svg';
import Grazie from '../../assets/azienda/grazie.svg';
import Alert from '../../components/Utils/Alert';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Category from '../../api/Category';
import Zone from '../../api/Zone';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const LoginButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Gotham Bold',
    fontSize: '1.125em',
    padding: '10px 70px',
    border: '1px solid',
    lineHeight: 1.5,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginTop:20,
    marginLeft:20,
    backgroundColor: '#009fe2',
    borderColor: '#009fe2',
    borderRadius: '30px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&:active': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&:disabled': {
        backgroundColor: '#eee',
        borderColor: '#eee',
        color: '#999'
    },
  },
  
})(Button);

const scrollToRef = (ref) => ref.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
});

const Adesione = (props) => {

    smoothscroll.polyfill();
    const myRef = React.useRef(null);
    const executeScroll = () => scrollToRef(myRef);

    const [tipologiaText, setTipologiaText] = React.useState('');

    const [categories, setCategories] = React.useState([]);
    React.useEffect(() => {
        pushGTM();
        Category.departments()
        .then((list) => {
            setCategories(list);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    const [zones, setZones] = React.useState([]);
    React.useEffect(() => {
        Zone.all()
            .then((zones) => {
                setZones(zones);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const [provinces, setProvinces] = React.useState([]);
    React.useEffect(() => {
        Zone.provinces()
            .then((provinces) => {
                setProvinces(provinces);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    React.useEffect(() => {
        pushGTM();
    }, []);

    const [valuesStep1, setValuesStep1] = React.useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        job: '',
        formErrors: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            job: '',
        },
        firstNameValid: false,
        lastNameValid: false,
        phoneValid: false,
        emailValid: false,
        jobValid: false,
        formValid: false,
        isSaving: false,
        isDone: false,
    });
    const [valuesStep2, setValuesStep2] = React.useState({
        ragSoc: '',
        insegna: '',
        tipologia: '',
        slogan: '',
        presentazione: '',
        via: '',
        citta: '',
        cap: '',
        provincia: '',
        company_phone: '',
        mobile: '',
        company_email: '',
        formErrors: {
            ragSoc: '',
            insegna: '',
            tipologia: '',
            slogan: '',
            presentazione: '',
            via: '',
            citta: '',
            cap: '',
            provincia: '',
            company_phone: '',
            mobile: '',
            company_email: '',
        },
        ragSocValid : false,
        insegnaValid : false,
        tipologiaValid : false,
        sloganValid : false,
        presentazioneValid : false,
        viaValid : false,
        cittaValid : false,
        capValid : false,
        provinciaValid : false,
        company_phoneValid : false,
        mobileValid : false,
        company_emailValid : false,
        formValid: false,
        isSaving: false,
        isDone: false,
    });
    const [valuesStep3, setValuesStep3] = React.useState({
        asporto: '',
        domicilio: '',
        comuni: [],
        continua_servizio: '',
        tipo_servizio: '',
        marketing: 'no',
        formErrors: {
            asporto: '',
            domicilio: '',
            comuni: '',
            continua_servizio: '',
            tipo_servizio: '',
        },
        asportoValid: false,
        domicilioValid: false,
        comuniValid: false,
        continua_servizioValid: false,
        tipo_servizioValid: false,
        formValid: false,
        isSaving: false,
        isDone: false,
    });

    const [openAlertRegistration, setOpenAlertRegistration] = React.useState(false);
    const [openAlertRegistrationError, setOpenAlertRegistrationError] = React.useState("");

    const handleChangeStep1 = prop => event => {
        let fieldValidationErrors = valuesStep1.formErrors;
        let firstNameValid = valuesStep1.firstNameValid;
        let lastNameValid = valuesStep1.lastNameValid;
        let emailValid = valuesStep1.emailValid;
        let phoneValid = valuesStep1.phoneValid;
        let jobValid = valuesStep1.jobValid;
        let eventValue = event.target.value;

        switch(prop) {
            case 'firstName':
                firstNameValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.firstName = firstNameValid ? '' : 'il campo nome è vuoto';
                break;
            case 'lastName':
                lastNameValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.lastName = lastNameValid ? '' : 'il campo cognome è vuoto';
                break;
            case 'phone':
                phoneValid = (/^\d+$/).test(eventValue);
                fieldValidationErrors.phone = phoneValid ? '' : 'il campo telefono non è valido (deve contenere solo numeri)';
                break;
            case 'email':
                emailValid = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(eventValue);
                fieldValidationErrors.email = emailValid ? '' : 'il campo email non è valido';
                break;
            case 'job':
                jobValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.job = jobValid ? '': 'il campo ruolo è vuoto';
                break;
            default:
                break;
        }
        setValuesStep1({ 
            ...valuesStep1, 
            [prop]: eventValue,
            formErrors: { ...fieldValidationErrors },
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            phoneValid: phoneValid,
            emailValid: emailValid,
            jobValid: jobValid,
            formValid: emailValid && jobValid && firstNameValid && lastNameValid && phoneValid
        });
        
    };

    const handleChangeStep2 = (prop, event, val) => {

        let fieldValidationErrors = valuesStep2.formErrors;
        let ragSocValid = valuesStep2.ragSocValid;
        let insegnaValid = valuesStep2.insegnaValid;
        let tipologiaValid = valuesStep2.tipologiaValid;
        let sloganValid = valuesStep2.sloganValid;
        let presentazioneValid = valuesStep2.presentazioneValid;
        let viaValid = valuesStep2.viaValid;
        let cittaValid = valuesStep2.cittaValid;
        let capValid = valuesStep2.capValid;
        let provinciaValid = valuesStep2.provinciaValid;
        let company_phoneValid = valuesStep2.company_phoneValid;
        let mobileValid = valuesStep2.mobileValid;
        let company_emailValid = valuesStep2.company_emailValid;
        let eventValue = event.target.value ? event.target.value : val;

        let index = event.nativeEvent.target.selectedIndex;
        let selectText = typeof index != 'undefined' ? event.nativeEvent.target[index].text : '';

        switch(prop) {
            case 'ragSoc':
                ragSocValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.ragSoc = ragSocValid ? '' : 'il campo ragione sociale è vuoto';
                break;
            case 'insegna':
                insegnaValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.insegna = insegnaValid ? '' : 'il campo insegna è vuoto';
                break;
            case 'tipologia':
                tipologiaValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.tipologia = tipologiaValid ? '' : 'il campo tipologia è vuoto';
                setTipologiaText(selectText);
                break;
            case 'slogan':
                sloganValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.slogan = sloganValid ? '' : 'il campo slogan è vuoto';
                break;
            case 'presentazione':
                presentazioneValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.presentazione = presentazioneValid ? '' : 'il campo presentazione è vuoto';
                break;
            case 'via':
                viaValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.via = viaValid ? '' : 'il campo via è vuoto';
                break;
            case 'citta':
                cittaValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.citta = cittaValid ? '' : 'il campo citta è vuoto';
                break;
            case 'cap':
                capValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.cap = capValid ? '' : 'il campo cap è vuoto';
                break;
            case 'provincia':
                provinciaValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.provincia = provinciaValid ? '' : 'il campo provincia è vuoto';
                break;
            case 'company_phone':
                company_phoneValid = (/^\d+$/).test(eventValue);
                fieldValidationErrors.company_phone = company_phoneValid ? '' : 'il campo telefono non è valido (deve contenere solo numeri)';
                break;
            case 'mobile':
                mobileValid = (/^\d+$/).test(eventValue);
                fieldValidationErrors.mobile = mobileValid ? '' : 'il campo cellulare non è valido (deve contenere solo numeri)';
                break;
            case 'company_email':
                company_emailValid = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(eventValue);
                fieldValidationErrors.company_email = company_emailValid ? '' : 'il campo email non è valido';
                break;
            default:
                break;
        }
        setValuesStep2({ 
            ...valuesStep2, 
            [prop]: eventValue,
            formErrors: { ...fieldValidationErrors },
            ragSocValid: ragSocValid,
            insegnaValid: insegnaValid,
            tipologiaValid: tipologiaValid,
            sloganValid: sloganValid,
            presentazioneValid: presentazioneValid,
            viaValid: viaValid,
            cittaValid: cittaValid,
            capValid: capValid,
            provinciaValid: provinciaValid,
            company_phoneValid: company_phoneValid,
            mobileValid: mobileValid,
            company_emailValid: company_emailValid,
            formValid: ragSocValid && insegnaValid && tipologiaValid && sloganValid && presentazioneValid && viaValid && cittaValid && capValid && provinciaValid && company_phoneValid && mobileValid && company_emailValid
        });
    };
    const handleChangeStep3 = (prop, event, val) => {
        let comuni = valuesStep3.comuni;
        let continua_servizio = valuesStep3.continua_servizio;
        let fieldValidationErrors = valuesStep3.formErrors;
        let asportoValid = valuesStep3.asportoValid;
        let domicilioValid = valuesStep3.domicilioValid;
        let comuniValid = valuesStep3.comuniValid;
        let continua_servizioValid = valuesStep3.continua_servizioValid;
        let tipo_servizioValid = valuesStep3.tipo_servizioValid;

        let valore = [];
        if(Array.isArray(val)) {
            val.map((v, i) => {
                valore.push(v.id);
            });
        } 

        let eventValue = event.target.value ? event.target.value : (valore.length > 0 ? valore : val);
        let eventChecked = event.target.checked;

        switch(prop) {
            case 'asporto':
                asportoValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.asporto = asportoValid ? '' : 'il campo asporto è vuoto';
                break;
            case 'domicilio':
                if(eventValue === '' || eventValue === 'no') {
                    comuni = [];
                    continua_servizio = '';
                    comuniValid = continua_servizioValid = true;
                } else {
                    comuniValid = !(/^ *$/).test(valuesStep3.comuni);
                    continua_servizioValid = !(/^ *$/).test(valuesStep3.continua_servizio);
                }
                domicilioValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.domicilio = domicilioValid ? '' : 'il campo domicilio è vuoto';
                break;
            case 'comuni':
                comuniValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.comuni = comuniValid ? '' : 'il campo comuni è vuoto';
                break;
            case 'continua_servizio':
                continua_servizioValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.continua_servizio = continua_servizioValid ? '' : 'il campo continua servizio è vuoto';
                break;
            case 'tipo_servizio':
                tipo_servizioValid = !(/^ *$/).test(eventValue);
                fieldValidationErrors.tipo_servizio = tipo_servizioValid ? '' : 'il campo tipo servizio è vuoto';
                break;
            case 'marketing':
                eventValue = eventChecked ? 'si' : 'no';
            default:
                break;
        }

        setValuesStep3({ 
            ...valuesStep3, 
            comuni: comuni,
            continua_servizio: continua_servizio,
            [prop]: eventValue,
            formErrors: { ...fieldValidationErrors },
            asportoValid: asportoValid,
            domicilioValid: domicilioValid,
            comuniValid: comuniValid,
            continua_servizioValid: continua_servizioValid,
            tipo_servizioValid: tipo_servizioValid,
            formValid: asportoValid && domicilioValid && comuniValid && continua_servizioValid && tipo_servizioValid
        });
        
    };

    function handleStep1() {
        setValuesStep1({ ...valuesStep1, formValid: false, isSaving: true });
        console.log(valuesStep1);
        //send form
        window.setTimeout(function(){
            setValuesStep1({ ...valuesStep1, formValid: true, isSaving: false, isDone: true });
            executeScroll();
        }, 1000);
    }

    function handleStep2() {
        setValuesStep2({ ...valuesStep2, formValid: false, isSaving: true });
        console.log(valuesStep2);
        //send form
        window.setTimeout(function(){
            setValuesStep2({ ...valuesStep2, formValid: true, isSaving: false, isDone: true });
            executeScroll();
        }, 1000);
    }

    function handleStep3() {
        setValuesStep3({ ...valuesStep3, formValid: false, isSaving: true });
        console.log(valuesStep3);
        //send form
        window.setTimeout(function(){
            setValuesStep3({ ...valuesStep3, formValid: true, isSaving: false, isDone: true });
        }, 1000);
    }

    function handleCloseAlert() {
        setOpenAlertRegistration(false);
    }

    return(
        <>
            <Helmet defaultTitle="Iscrivi gratuitamente la tua azienda a Rivemo e apri il tuo canale di vendita online.">
                <title>Iscrivi gratuitamente la tua azienda a Rivemo e apri il tuo canale di vendita online.</title>
                <meta name="description" content="Entra in Rivemo! Iscrivi gratuitamente la tua attività e aumenta i tuoi incassi in modo rapido e semplice. Contattaci subito e scopri di più." />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-shop form-page">
                <Onda className="onda" />
                <div className="top-text">
                    <h3>Iscrivi gratuitamente la tua attività a Rivemo</h3>
                </div>
            </div>
            <Alert open={openAlertRegistration} onClose={handleCloseAlert} title="Attenzione" content={openAlertRegistrationError} />
            <div className="generic adesione">
                <div className="step-adesione-container">
                    <div className="step-adesione active"><span className="num-step">1</span> <span>Referente</span></div>
                    <span className="sep-step active">></span>
                    <div className={valuesStep1.isDone ? "step-adesione active" : "step-adesione"}><span className="num-step">2</span> <span>Azienda</span></div>
                    <span className={(valuesStep1.isDone && valuesStep2.isDone) ? "sep-step active" : "sep-step"}>></span>
                    <div className={(valuesStep1.isDone && valuesStep2.isDone) ? "step-adesione active" : "step-adesione"}><span className="num-step">3</span> <span>Consegne</span></div>
                </div>
                <Container style={{ maxWidth: '1245px', position: 'relative', zIndex:0, padding: '0' }} fixed ref={myRef}>
                    <div className="adesione-form-container">
                        {
                            !valuesStep1.isDone &&
                            <>
                            <div className="adesione-icon-container">
                                <img src={Referente} alt="Inserisci i dati del referente aziendale" width="170" />
                                <span>Inserisci i dati del referente aziendale</span>
                                <span className="desc">Inserisci qui i dati del referente aziendale per le successive comunicazioni da parte di Rivemo</span>
                            </div>
                            <div className="adesione-modulo-container">
                                <form>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Nome</div>
                                        <div className="modulo-field"><input type="text" name="firstName" onChange={handleChangeStep1('firstName')} value={valuesStep1.firstName} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Cognome</div>
                                        <div className="modulo-field"><input type="text" name="lastName" onChange={handleChangeStep1('lastName')} value={valuesStep1.lastName} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Ruolo</div>
                                        <div className="modulo-field"><input type="text" name="job" onChange={handleChangeStep1('job')} value={valuesStep1.job} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Email</div>
                                        <div className="modulo-field"><input type="text" name="email" onChange={handleChangeStep1('email')} value={valuesStep1.email} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Cellulare</div>
                                        <div className="modulo-field"><input type="text" name="phone"onChange={handleChangeStep1('phone')} value={valuesStep1.phone}  /></div>
                                    </div>
                                    <div className="form-errors ad">
                                        {
                                            ((
                                                !valuesStep1.firstNameValid || 
                                                !valuesStep1.lastNameValid || 
                                                !valuesStep1.phoneValid || 
                                                !valuesStep1.emailValid || 
                                                !valuesStep1.jobValid) && (
                                                valuesStep1.phoneValid && valuesStep1.emailValid
                                            ))
                                            && <p>Compila tutti i campi</p>
                                        }
                                        {
                                            !valuesStep1.phoneValid && <p>{valuesStep1.formErrors.phone}</p>
                                        }
                                        {
                                            !valuesStep1.emailValid && <p>{valuesStep1.formErrors.email}</p>
                                        }
                                    </div>
                                    <div className="modulo-btn">
                                        {(valuesStep1.isSaving) && <FontAwesomeIcon icon={faSpinner} spin size="lg" style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '20px' }} />}
                                        {valuesStep1.formValid ? <LoginButton variant="contained" color="primary" disableRipple onClick={handleStep1}>CONTINUA</LoginButton> : <LoginButton variant="contained" color="primary" disabled disableRipple>CONTINUA</LoginButton>}
                                    </div>
                                </form>
                            </div>
                            </>
                        }
                        {
                            valuesStep1.isDone && !valuesStep2.isDone &&
                            <>
                            <div className="adesione-icon-container">
                                <img src={Azienda} alt="Inserisci i dati dell'azienda" width="170" />
                                <span>Inserisci i dati dell'azienda</span>
                                <span className="desc">Inserisci qui i dati della tua azienda, che verranno visualizzati nella scheda del tuo negozio.</span>
                            </div>
                            <div className="adesione-modulo-container step2">
                                <form>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Ragione sociale</div>
                                        <div className="modulo-field"><input type="text" name="ragSoc" onChange={(e) => handleChangeStep2('ragSoc', e)} value={valuesStep2.ragSoc} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Nome attività (insegna)</div>
                                        <div className="modulo-field"><input type="text" name="insegna" onChange={(e) => handleChangeStep2('insegna', e)} value={valuesStep2.insegna} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Tipologia</div>
                                        <div className="modulo-field">
                                            <span className="fake-select">{tipologiaText}</span>
                                            <select name="tipologia" onChange={(e) => handleChangeStep2('tipologia', e)} value={valuesStep2.tipologia}>
                                                <option value="">---</option>
                                                {
                                                    categories.map((category, index) => 
                                                    <option value={category.info.id_lang} key={`category-${index}`}>{category.info.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Breve slogan</div>
                                        <div className="modulo-field"><input type="text" maxLength="70" name="slogan" onChange={(e) => handleChangeStep2('slogan', e)} value={valuesStep2.slogan} placeholder="max 70 caratteri" /></div>
                                    </div>
                                    <div className="modulo-row start">
                                        <div className="modulo-label">Presentazione dell’impresa e dei prodotti</div>
                                        <div className="modulo-field"><textarea name="presentazione" maxLength="150" onChange={(e) => handleChangeStep2('presentazione', e)} value={valuesStep2.presentazione} placeholder="max 150 caratteri"></textarea></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Via</div>
                                        <div className="modulo-field"><input type="text" name="via" onChange={(e) => handleChangeStep2('via', e)} value={valuesStep2.via} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Città</div>
                                        <div className="modulo-field">
                                            <Autocomplete
                                                id="combo-box-citta"
                                                options={zones}
                                                className="auto-citta"
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e, value) => {
                                                    if(typeof value != 'undefined' && value != null){
                                                        handleChangeStep2('citta', e, value.id);
                                                    } else {
                                                        handleChangeStep2('citta', e, '');
                                                    }
                                                }}
                                                renderInput={(params) => <TextField name="citta" {...params} value={valuesStep2.citta} variant="standard" />}
                                            />
                                        </div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">CAP</div>
                                        <div className="modulo-field"><input type="text" name="cap" onChange={(e) => handleChangeStep2('cap', e)} value={valuesStep2.cap} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Provincia</div>
                                        <div className="modulo-field">
                                            <Autocomplete
                                                id="combo-box-pro"
                                                options={provinces}
                                                className="auto-citta"
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e, value) => {
                                                    if(typeof value != 'undefined' && value != null){
                                                        handleChangeStep2('provincia', e, value.id);
                                                    } else {
                                                        handleChangeStep2('provincia', e, '');
                                                    }
                                                }}
                                                renderInput={(params) => <TextField name="pro" {...params} value={valuesStep2.provincia} variant="standard" />}
                                            />
                                        </div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Telefono</div>
                                        <div className="modulo-field"><input type="text" name="company_phone" onChange={(e) => handleChangeStep2('company_phone', e)} value={valuesStep2.company_phone} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Cellulare (per gli ordini)</div>
                                        <div className="modulo-field"><input type="text" name="mobile" onChange={(e) => handleChangeStep2('mobile', e)} value={valuesStep2.mobile} /></div>
                                    </div>
                                    <div className="modulo-row">
                                        <div className="modulo-label">Email aziendale<br className="mob" /> <span>Sarà quella con cui accederai alla piattaforma di gestione del negozio</span></div>
                                        <div className="modulo-field"><input type="text" name="company_email" onChange={(e) => handleChangeStep2('company_email', e)} value={valuesStep2.company_email} /></div>
                                    </div>
                                    <div className="form-errors ad">
                                        {
                                            ((
                                                !valuesStep2.ragSocValid || 
                                                !valuesStep2.insegnaValid || 
                                                !valuesStep2.tipologiaValid || 
                                                !valuesStep2.sloganValid || 
                                                !valuesStep2.presentazioneValid || 
                                                !valuesStep2.viaValid || 
                                                !valuesStep2.cittaValid || 
                                                !valuesStep2.capValid || 
                                                !valuesStep2.provinciaValid || 
                                                !valuesStep2.company_phoneValid || 
                                                !valuesStep2.mobileValid) && (
                                                valuesStep2.company_phoneValid && valuesStep2.mobileValid && valuesStep2.company_emailValid
                                            ))
                                            && <p>Compila tutti i campi</p>
                                        }
                                        {
                                            !valuesStep2.company_phoneValid && <p>{valuesStep2.formErrors.company_phone}</p>
                                        }
                                        {
                                            !valuesStep2.mobileValid && <p>{valuesStep2.formErrors.mobile}</p>
                                        }
                                        {
                                            !valuesStep2.company_emailValid && <p>{valuesStep2.formErrors.company_email}</p>
                                        }
                                    </div>
                                    <div className="modulo-btn">
                                        {(valuesStep2.isSaving) && <FontAwesomeIcon icon={faSpinner} spin size="lg" style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '20px' }} />}
                                        {valuesStep2.formValid ? <LoginButton variant="contained" color="primary" disableRipple onClick={handleStep2}>CONTINUA</LoginButton> : <LoginButton variant="contained" color="primary" disabled disableRipple>CONTINUA</LoginButton>}
                                    </div>
                                </form>
                            </div>
                            </>
                        }
                        {
                            valuesStep1.isDone && valuesStep2.isDone && !valuesStep3.isDone &&
                            <>
                            <div className="adesione-icon-container">
                                <img src={Consegna} alt="Inserisci i dati sulle consegne" width="170" />
                                <span>Inserisci i dati sulle consegne</span>
                            </div>
                            <div className="adesione-modulo-container step3">
                                <form>
                                    <div className="modulo-row bigger">
                                        <div className="modulo-label">Ritiro per asporto su prenotazione</div>
                                        <div className="modulo-field flexy">
                                            <div className="modulo-radio">
                                                <input type="radio" name="asporto" value="si" id="asporto-si" onClick={(e) => handleChangeStep3('asporto', e)} />
                                                <label htmlFor="asporto-si"><span className="circle"></span><span>SI</span></label>
                                            </div>
                                            <div className="modulo-radio">
                                                <input type="radio" name="asporto" value="no" id="asporto-no" onClick={(e) => handleChangeStep3('asporto', e)} />
                                                <label htmlFor="asporto-no"><span className="circle"></span><span>NO</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modulo-row bigger">
                                        <div className="modulo-label">Consegne a domicilio</div>
                                        <div className="modulo-field flexy">
                                            <div className="modulo-radio">
                                                <input type="radio" name="domicilio" value="si" id="domicilio-si" onClick={(e) => handleChangeStep3('domicilio', e)} />
                                                <label htmlFor="domicilio-si"><span className="circle"></span><span>SI</span></label>
                                            </div>
                                            <div className="modulo-radio">
                                                <input type="radio" name="domicilio" value="no" id="domicilio-no" onClick={(e) => handleChangeStep3('domicilio', e)} />
                                                <label htmlFor="domicilio-no"><span className="circle"></span><span>NO</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        valuesStep3.domicilio === 'si' &&
                                        <>
                                        <div className="modulo-row bigger">
                                            <div className="modulo-label">Comuni in cui effettui già consegne a domicilio</div>
                                            <div className="modulo-field">
                                                <Autocomplete
                                                    multiple
                                                    id="combo-tags-comuni"
                                                    options={zones}
                                                    className="auto-citta"
                                                    getOptionLabel={(option) => option.name}
                                                    filterSelectedOptions
                                                    onChange={(e, value) => {
                                                        if(typeof value != 'undefined' && value != null){
                                                            handleChangeStep3('comuni', e, value);
                                                        } else {
                                                            handleChangeStep3('comuni', e, '');
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField name="comuni" {...params} variant="standard" />}
                                                />
                                            </div>
                                        </div>
                                        <div className="modulo-row bigger start">
                                            <div className="modulo-label">Una volta terminata l'attuale situazione di emergenza, continuerai a fornire il servizio di consegna a domicilio?</div>
                                            <div className="modulo-field">
                                                <div className="modulo-radio full">
                                                    <input type="radio" name="continua_servizio" value="si_autonomia" id="continua_servizio-1" onClick={(e) => handleChangeStep3('continua_servizio', e)} />
                                                    <label htmlFor="continua_servizio-1"><span className="circle"></span><span>SI</span></label>
                                                </div>
                                                <div className="modulo-radio full">
                                                    <input type="radio" name="continua_servizio" value="si_fattorini" id="continua_servizio-2" onClick={(e) => handleChangeStep3('continua_servizio', e)} />
                                                    <label htmlFor="continua_servizio-2"><span className="circle"></span><span>SI, affidando le consegne a fattorini esterni</span></label>
                                                </div>
                                                <div className="modulo-radio full">
                                                    <input type="radio" name="continua_servizio" value="no" id="continua_servizio-3" onClick={(e) => handleChangeStep3('continua_servizio', e)} />
                                                    <label htmlFor="continua_servizio-3"><span className="circle"></span><span>NO, interromperò le consegne a domicilio</span></label>
                                                </div>
                                                <div className="modulo-radio full">
                                                    <input type="radio" name="continua_servizio" value="non_so" id="continua_servizio-4" onClick={(e) => handleChangeStep3('continua_servizio', e)} />
                                                    <label htmlFor="continua_servizio-4"><span className="circle"></span><span>NON SO, devo ancora decidere</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="modulo-row start">
                                        <div className="modulo-label">Quale servizio di RIVEMO sei interessato ad attivare?</div>
                                        <div className="modulo-field">
                                            <div className="modulo-radio full">
                                                <input type="radio" name="tipo_servizio" value="vetrina" id="tipo_servizio-1" onClick={(e) => handleChangeStep3('tipo_servizio', e)} />
                                                <label htmlFor="tipo_servizio-1"><span className="circle"></span><span><strong>VETRINA ONLINE</strong> (presentazione aziendale)</span></label>
                                            </div>
                                            <div className="modulo-radio full">
                                                <input type="radio" name="tipo_servizio" value="ecommerce" id="tipo_servizio-2" onClick={(e) => handleChangeStep3('tipo_servizio', e)} />
                                                <label htmlFor="tipo_servizio-2"><span className="circle"></span><span><strong>NEGOZIO E-COMMERCE</strong> (presentazione aziendale + vendita online)</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-errors ad">
                                        {
                                            (
                                                !valuesStep3.asportoValid || 
                                                !valuesStep3.domicilioValid || 
                                                !valuesStep3.comuniValid ||
                                                !valuesStep3.continua_servizioValid ||
                                                !valuesStep3.tipo_servizioValid) 
                                            && <p>Compila tutti i campi</p>
                                        }
                                    </div>
                                    <div className="modulo-text">
                                        <p>Inviando il presente modulo acconsento che Rivemo possa trattare i miei dati personali, archiviandoli e utilizzando le informazioni solo per rispondere alla richiesta di iscrizione gratuita al servizio.</p>
                                        <div className="modulo-radio full">
                                            <input type="checkbox" name="marketing" value="si" id="marketing" onChange={(e) => handleChangeStep3('marketing', e)} />
                                            <label htmlFor="marketing"><span className="circle chck"></span><span>Accetto inoltre di ricevere email da Rivemo per comunicazioni di marketing e commerciali</span></label>
                                        </div>
                                    </div>
                                    <div className="modulo-btn">
                                        {(valuesStep3.isSaving) && <FontAwesomeIcon icon={faSpinner} spin size="lg" style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '20px' }} />}
                                        {valuesStep3.formValid ? <LoginButton variant="contained" color="primary" disableRipple onClick={handleStep3}>REGISTRATI</LoginButton> : <LoginButton variant="contained" color="primary" disabled disableRipple>REGISTRATI</LoginButton>}
                                    </div>
                                </form>
                            </div>
                            </>
                        }
                        {
                            valuesStep1.isDone && valuesStep2.isDone && valuesStep3.isDone &&
                            <>
                            <div className="adesione-icon-container thanks">
                                <img src={Grazie} alt="Grazie per aver completato l'iscrizione della tua azienda" width="215" />
                                <span>Grazie per aver completato l'iscrizione della tua azienda</span>
                                <span className="desc">Ti contatteremo nel più breve tempo possibile</span>
                            </div>
                            </>
                        }
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Adesione;