import React from 'react';
import { Link } from '@reach/router';

import ShopMap from './ShopMap';

import FB from '../../assets/social/facebook.svg';
import IG from '../../assets/social/instagram.svg';
import WWW from '../../assets/social/www.svg';

import Marker from '../../assets/condizioni/marker.svg';
import Info from '../../assets/condizioni/info.svg';
import Minimo from '../../assets/condizioni/minimo.svg';
import Wallet from '../../assets/condizioni/wallet.svg';
import Speed from '../../assets/condizioni/speed.svg';
import Phone from '../../assets/condizioni/phone.svg';
import Scooter from '../../assets/condizioni/scooter.svg';
import Take from '../../assets/condizioni/store.svg';
import Close from '../../assets/close.svg';

import Hidden from '@material-ui/core/Hidden';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as NextArrow } from '../../assets/arrow-sx-simple.svg';
import Masonry from 'react-masonry-css';

const breakpointColumns = {
  default: 2,
  768: 2,
  600: 2
};
const breakpointColumnsObj = {
  default: 4,
  768: 3,
  600: 2
};

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <NextArrow
      className={className}
      onClick={onClick}
    />
  );
}

const ShopTabs = (props) => {
  const { shop, tabShown } = props;

  const shopInfoPhone = (shop.info.phone !== '' && shop.info.phone != null) ? shop.info.phone.replace('_','') : "";
  const shopInfoMobile = (shop.info.mobile !== '' && shop.info.mobile != null) ? shop.info.mobile.replace('_','') : "";
  const hasPhone = shopInfoPhone !== '';
  const hasMobile = shopInfoMobile !== '';
  const shopInfoUrlMaps = (shop.info.url_maps !== '' && shop.info.url_maps != null) ? shop.info.url_maps.replace(/&#39/ig, "") : "";

  const deliveryMode = shop.info.delivery_mode;
  let delivery = false;
  let takeaway = false;
  if(deliveryMode && deliveryMode !== null && deliveryMode !== '' ){
    deliveryMode.indexOf('DELIVERY') !== -1 ? delivery = true : delivery = false;
    deliveryMode.indexOf('TAKEAWAY') !== -1 ? takeaway = true : takeaway = false;
  }

  const shopName = shop.info.company_name;

  let childElements = null;
  if((Array.isArray(shop.info.image) && shop.info.image[0] !== null)){
    childElements = shop.info.image.map(function(element, i){
        return (
          <img src={element} key={`child-${i}`} onClick={() => openGallery(i)} />
        );
    });
  }

  const myRef = React.useRef(null);
  const slider = React.useRef(null);

  const settingsZoom = {
      dots: true,
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <SampleNextArrow />,
      nextArrow: <SampleNextArrow />
  };
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    adaptiveHeight: true,
    prevArrow: <SampleNextArrow />,
    nextArrow: <SampleNextArrow />
  };

  function openGallery(s){
      slider.current.slickGoTo(s, true);
      myRef.current.classList.remove('closed');
      myRef.current.classList.add('opened');
  }
  function closeGallery(){
      myRef.current.classList.remove('opened');
      window.setTimeout(function(){
          myRef.current.classList.add('closed');
      }, 400);
  }

  return(
    <div>
          {
            tabShown === 'info' ? (
              <div className="tab-scheda">
                {
                  (shop.info.products_type !== '' && shop.info.products_type !== null) &&
                    <>
                    <strong className="prod-info">{shop.info.products_type}</strong><br className="mob" />
                    </>
                }
                {
                  (shop.info.description !== '' && shop.info.description !== null) &&
                    <>
                    <div className="dangerously" dangerouslySetInnerHTML={{__html: shop.info.description}} />
                    </>
                }
                <div className="lightbox-container closed" id="lt-container" ref={myRef}>
                    <div className="lightbox-overlay" onClick={closeGallery}></div>
                    <div className="lightbox">
                        <Slider ref={slider} {...settingsZoom}>
                            {shop.info.image.map((element, i) =>
                                <div key={`zoom-${i}`}><div className="lightbox-img" style={{ backgroundImage: `url(${element})` }}></div></div>
                            )}
                        </Slider>
                    </div>
                    <span className="lightbox-close" id="lt-close" onClick={closeGallery}><img src={Close} /></span>
                </div>
                {
                  childElements &&
                    <div>
                      <Hidden xsDown>
                        {shop.info.image.length < 3 ? (
                          <div className="tab-scheda-masonry">
                            <Masonry
                              breakpointCols={breakpointColumns}
                              className="my-masonry-grid"
                              columnClassName="my-masonry-grid_column"
                            >
                              {childElements}
                            </Masonry>
                          </div>
                        ) : (
                          <div className="tab-scheda-img-container">
                            <Slider {...settings}>
                              {childElements}
                            </Slider>
                          </div>
                        )}
                      </Hidden>
                      <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div className="tab-scheda-masonry">
                          <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                          >
                            {childElements}
                          </Masonry>
                        </div>
                      </Hidden>
                    </div>
                }
                <ShopMap shop={shop} shopInfoUrlMaps={shopInfoUrlMaps} />
                {
                  (hasPhone || hasMobile) &&
                    <div className={(hasPhone && hasMobile) ? "tab-scheda-phone" : "tab-scheda-phone only-one"}>
                      {hasPhone &&
                        <div className="tab-scheda-num">
                          {/* TELEFONO<br className="mob" /><strong>{shopInfoPhone}</strong> */}
                          <a href={`tel:${shopInfoPhone}`} className="name-shop-url under phone btn-shop-call"><img src={Phone} alt="Chiama" />&nbsp; {shopInfoPhone}</a>
                        </div>
                      }
                      {hasMobile &&
                        <div className="tab-scheda-num">
                          {/* CELLULARE<br className="mob" /><strong>{shopInfoMobile}</strong> */}
                          <a href={`tel:${shopInfoMobile}`} className="name-shop-url under phone btn-shop-call"><img src={Phone} alt="Chiama" />&nbsp; {shopInfoMobile}</a>
                        </div>
                      }
                    </div>
                }
                {
                  ((shop.info.url_site !== '' && shop.info.url_site !== null && shop.info.url_site.indexOf('http') !== -1) || (shop.info.url_facebook !== '' && shop.info.url_facebook !== null) || (shop.info.url_instagram !== '' && shop.info.url_instagram !== null)) &&
                    <div className="tab-scheda-canali">
                      <span className="tab-scheda-url-title"><strong className="tab-scheda-tit nomarg">Canali online</strong></span>
                      {(shop.info.url_facebook !== '' && shop.info.url_facebook !== null) &&
                        <span className="tab-scheda-url"><a href={shop.info.url_facebook} target="_blank" rel="noopener noreferrer" className="link_shop_facebook"><img src={FB} alt="facebook attivita" width="40" /></a></span>
                      }
                      {(shop.info.url_instagram !== '' && shop.info.url_instagram !== null) &&
                        <span className="tab-scheda-url"><a href={shop.info.url_instagram} target="_blank" rel="noopener noreferrer" className="link_shop_instagram"><img src={IG} alt="instagram attivita" width="40" /></a></span>
                      }
                      {(shop.info.url_site !== '' && shop.info.url_site !== null && shop.info.url_site.indexOf('http') !== -1)&&
                        <span className="tab-scheda-url"><a href={shop.info.url_site} target="_blank" rel="noopener noreferrer" className="link_shop_site"><img src={WWW} alt="sito web attivita" width="40" /></a></span>
                      }
                    </div>
                }
                {(shop.info.is_registered != 1 && shop.info.has_catalog != 1) && 
                  <div className="tab-request">
                    <Link to={`/azienda`} className="proprietario">Sei il proprietario di <strong>{shopName}</strong>?<br /> Contattaci e gestisci gratuitamente il tuo negozio online!</Link>
                  </div>
                }
              </div>
            ) : (
              <div className="tab-scheda condizioni">
                {
                  delivery &&
                    <div className={shop.info.has_catalog == 1 ? 'condizioni-container con-listino' : 'condizioni-container'}>
                      <div className="condizioni-title"><img src={Scooter} alt="a domicilio" width="40" /> <span>A DOMICILIO</span></div>
                      <div className="tab-scheda-row">
                        <span className="tab-scheda-icon"><img src={Marker} /></span>
                        <div className="tab-scheda-info">
                          <h3 className="tab-scheda-tit">Aree di consegna a domicilio</h3><br />
                          <p>
                            {(shop.info.delivery_places !== '' && shop.info.delivery_places !== null) ? shop.info.delivery_places : shop.info.city}
                            {(shop.info.delivery_provinces !== '' && shop.info.delivery_provinces !== null) ? ` e nelle province di: ${shop.info.delivery_provinces}` : ``}
                          </p>
                        </div>
                      </div>
                      <div className="tab-scheda-row">
                        <span className="tab-scheda-icon"><img src={Info} /></span>
                        <div className="tab-scheda-info">
                          <h3 className="tab-scheda-tit">Informazioni utili per la consegna a domicilio</h3><br />
                          <p>{(shop.info.delivery_note !== '' && shop.info.delivery_note !== null) ? shop.info.delivery_note : `Da concordare telefonicamente`}</p>
                        </div>
                      </div>
                      <div className="tab-scheda-row">
                        <span className="tab-scheda-icon"><img src={Minimo} /></span>
                        <div className="tab-scheda-info">
                          <strong className="tab-scheda-tit">Minimo d'ordine</strong><br />
                          <p>{(shop.info.minimum_order !== '' && shop.info.minimum_order !== null) ? shop.info.minimum_order : `Informazione non disponibile`}</p>
                        </div>
                      </div>
                      <div className="tab-scheda-row">
                        <span className="tab-scheda-icon"><img src={Wallet} /></span>
                        <div className="tab-scheda-info">
                          <strong className="tab-scheda-tit">Pagamenti accettati</strong><br />
                          <p>{(shop.info.payments !== '' && shop.info.minimum_order !== null) ? shop.info.payments : `Contanti`}</p>
                        </div>
                      </div>
                    </div>
                }
                {
                  takeaway &&
                    <div className={shop.info.has_catalog == 1 ? 'condizioni-container con-listino' : 'condizioni-container'}>
                      <div className="condizioni-title"><img src={Take} alt="ritiro per asporto" width="30" /> <span>RITIRO PER ASPORTO</span></div>
                      <div className="tab-scheda-row">
                        <span className="tab-scheda-icon"><img src={Speed} /></span>
                        <div className="tab-scheda-info">
                          <strong className="tab-scheda-tit">{`Ritiro presso ${shopName}`}</strong><br />
                          <p>{(shop.info.takeaway_note !== '' && shop.info.takeaway_note !== null) ? shop.info.takeaway_note : `Da concordare telefonicamente`}</p><br className="mob" />
                          <ShopMap shop={shop} shopInfoUrlMaps={shopInfoUrlMaps} />
                        </div>  
                      </div>
                    </div>
                }
              </div>
            )
          }
    </div>
  )
}

export default ShopTabs;