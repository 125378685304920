import http, { BASE_URL } from './lib/http';
import Option from "./Option";

class Product {

    /**
     * Costruttore
     * @param {Object} data
     * @param {number} data.id          id numerico 
     * @param {string} data.title       dicitura
     * @param {string} data.description descrizione estesa
     * @param {string} data.note        note
     * @param {string} data.img         URL immagine
     * @param {number} data.price       prezzo
     * @param {Array}  data.accessories accessori già inclusi
     * @param {...*}   info             dati restanti
     */
    constructor({id, title, description, note, img, price = 0, accessories = [], ...info}) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.note = note;
        this.imageUrl = img;
        this.price = price;
        this.standardOptions = accessories.map((data) => new Option(data));
        this.info = info;
        this.selectedOptions = [];
        this.removedStandardOptions = [];
    }

    /**
     * Metodo statico per creare un'istanza di Product da un'altra istanza
     * @param {object} data
     * @param {number} data.id
     * @param {string} data.title
     * @param {string} data.description
     * @param {string} data.note
     * @param {string} data.imageUrl
     * @param {number} data.price
     * @param {object} data.standardOptions
     * @param {object} data.info
     * @returns {Product}
     */
    static make({ id, title, description, note, imageUrl, price, standardOptions, info }) {
        return new Product({
            id, 
            title, 
            description, 
            note, 
            img: imageUrl, 
            price, 
            accessories: [], 
            ...info,
        }).withStandardOptions(standardOptions);
    }

    /**
     * Metodo statico per ripristinare un'istanza di Product da un Object
     * @param {object} data 
     * @param {object} data.selectedOptions 
     * @param {object} data.removeStandardOptions 
     * @param {...*}   attrs
     * @returns {Product} 
     */
    static hydrate({ selectedOptions, removedStandardOptions, ...attrs }) {
        return Product.make(attrs)
            .withSelectedOptions(selectedOptions)
            .withRemovedStandardOptions(removedStandardOptions);
    }

    /**
     * Popola l'array delle standard option con oggetti Option
     * @param {object} options 
     * @returns {this}
     */
    withStandardOptions(options) {
        return this.hydrateOptions('standardOptions', options);
    }

    /**
     * Popola l'array delle selected option con oggetti Option
     * @param {object} options 
     * @returns {this}
     */
    withSelectedOptions(options) {
        return this.hydrateOptions('selectedOptions', options);
    }

    /**
     * Popola l'array delle removed standard option con oggetti Option
     * @param {object} options 
     * @returns {this}
     */
    withRemovedStandardOptions(options) {
        return this.hydrateOptions('removedStandardOptions', options);
    }

    /**
     * Popola l'array delle option indicato con oggetti Option
     * @param {string} key 
     * @param {object} data 
     * @returns {this}
     */
    hydrateOptions(key, data) {
        this[key] = data.map((option) => Option.make(option));
        return this;
    }

    /**
     * Restituisce l'elenco di tutte le opzioni disponibili per il prodotto ed il
     * numero massimo di opzioni selezionabili
     * @returns {Array} array di due elementi: il primo è l'elenco di Option, 
     *                  il secondo il numero massimo di opzioni selezionabili
     * @throws {Error}
     */
    async getAllOptions() {
        if (this.options && this.options.length > 0) {
            return [
                this.options,
                this.maxOptions,
                this.minptions,
            ];
        }

        try {
            await this.loadAllOptions();
        } catch (error) {
            throw error;
        }

        return [
            this.options,
            this.maxOptions,
            this.minOptions,
        ];
    }

    /**
     * Restituisce l'elenco di tutte le opzioni aggiunte
     * @returns {Array} array di opzioni aggiunte
     * @throws {Error}
     */
    getSelectedOptions() {
            return [
                this.selectedOptions,
                this.minOptions
            ];
    }

    /**
     * Carica i dati delle opzioni disponibili per il prodotto
     * @returns {Product}
     * @throws {Error}
     */
    async loadAllOptions() {
        const { shopId, subcategoryId } = this.info;
//console.log(this.info);
        if (!shopId || !subcategoryId) {
            throw new Error("Product data is missing!");
        }

        try {
            const data = await http.get(`${BASE_URL}/get-accessories/${shopId}/${subcategoryId}`);
            this.maxOptions = parseInt(this.info.max_accessories);
            this.minOptions = parseInt(this.info.min_accessories);
            this.options = data.accessories && data.accessories.map((accessoryInfo) => new Option(accessoryInfo));
        } catch (error) {
            throw error;
        }

        return this;
    }

    /**
     * Popola l'array delle opzioni selezionate
     * @param {Option} option 
     * @returns {this}
     * @throws {Error}
     */
    selectOption(option) {

        if (!(option instanceof Option)) {
            throw new Error("Invalid option!");
        }

        if (this.maxOptions && this.selectedOptions.length === this.maxOptions) {
            throw new Error(`Number of options exceeded (max: ${this.maxOptions})`);
        }

        this.selectedOptions.push(option);

        return this;
    }

    /**
     * Verifica se l'opzione indicata è fra quelle selezionate
     * @param {Option} option 
     * @returns {boolean}
     * @throws {Error}
     */
    hasOption(option) {

        if (!(option instanceof Option)) {
            throw new Error("Invalid option!");
        }

        return Boolean(this.selectedOptions.find(({ id }) => id === option.id));
    }

    /**
     * Rimuove l'opzione dall'elenco di quelle selezionate
     * @param {Option} option 
     * @returns {this}
     * @throws {Error}
     */
    removeOption(option) {

        if (!(option instanceof Option)) {
            throw new Error("Invalid option!");
        }

        this.selectedOptions = this.selectedOptions.filter(({ id }) => id !== option.id);
        return this;
    }

    /**
     * Restituisce il prezzo totale del prodotto ottenuto sommandone il prezzo
     * base a quello delle eventuali opzioni selezionate
     * @returns {number}
     */
    getTotalPrice() {
        let optionsPrice = this.selectedOptions.reduce(
            (sum, { price }) => price ? sum += price : sum,
            0
        );
        
        return this.price + optionsPrice;
    }

    /**
     * Verifica se l'opzione passata è fra quelle standard del prodotto
     * @param {Option} option 
     * @returns {boolean}
     * @throws {Error}
     */
    isStandardOption(option) {

        if (!(option instanceof Option)) {
            throw new Error("Invalid option!");
        }

        return Boolean(this.standardOptions.find(({ id }) => id === option.id));
    }

    /**
     * Verifica se l'opzione passata è fra quelle standard rimosse
     * @param {Option} standardOption 
     * @returns {boolean}
     * @throws {Error}
     */
    isStandardOptionRemoved(standardOption) {

        if (!(standardOption instanceof Option)) {
            throw new Error("Invalid option!");
        }

        if (!this.isStandardOption(standardOption)) {
            throw new Error("Not a standard option!");
        }

        return Boolean(this.removedStandardOptions.find(({ id }) => id === standardOption.id));
    }

    /**
     * Ripristina l'opzione standard eliminandola dall'elenco di quelle rimosse
     * @param {Option} standardOption 
     * @returns {this}
     * @throws {Error}
     */
    restoreStandardOption(standardOption) {

        if (!(standardOption instanceof Option)) {
            throw new Error("Invalid option!");
        }

        if (!this.isStandardOption(standardOption)) {
            throw new Error("Not a standard option!");
        }

        this.removedStandardOptions = this.removedStandardOptions.filter(({ id }) => id !== standardOption.id);
        return this;
    }

    /**
     * Aggiunge l'opzione passato all'elenco di quelle standard rimosse
     * @param {Option} standardOption 
     * @returns {this}
     * @throws {Error}
     */
    removeStandardOption(standardOption) {

        if (!(standardOption instanceof Option)) {
            throw new Error("Invalid option!");
        }

        if (!this.isStandardOption(standardOption)) {
            throw new Error("Not a standard option!");
        }

        this.removedStandardOptions.push(standardOption);
        return this;
    }

    /**
     * Aggiunge le note al prodotto
     * @param {String} note 
     * @returns {this}
     * @throws {Error}
     */
    insertNote(note) {

        this.note = note;
        return this;
    }
}

export default Product;