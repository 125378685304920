import React, { createContext, useReducer } from 'react';
import { hydrate } from './helpers';
import { stateReducer } from './reducers';
import Shop from '../../api/Shop';
import Zone from '../../api/Zone';
import Product from '../../api/Product';
import User from '../../api/User';
import Token from '../../api/Token';
import DeliveryAddress from '../../api/DeliveryAddress';

export const Store = createContext("");

/**
 * Ripristina il contenuto dello store dal localStorage
 * @param   {object} emptyStore
 * @returns {object}
 */
function hydrateStore(emptyStore) {
    const plainStore = hydrate("store");
    if (!plainStore) {
        return emptyStore;
    }
    
    const { user, zone, shop, cart } = plainStore;
//console.log(user);
    return {
        user: Object.keys(user).length === 0 
            ? {} 
            : User.make(
                user, 
                new Token(user.token), 
                new DeliveryAddress(user.deliveryAddress || {}),
                user.note,
                user.isShop,
                user.pwd
            ),
        zone: !zone || Object.keys(zone).length === 0 ? {} : new Zone(zone),
        shop: !shop ? {} : Shop.make(shop),
        cart: cart.map((item) => ({
            counter: item.counter,
            product: Product.hydrate(item.product),
        })),
    }
}

const initialState = hydrateStore({
    user: {},
    zone: {},
    shop: {},
    cart: [], 
});

export function StoreProvider(props) {
    const [state, dispatch] = useReducer(stateReducer, initialState);
    const value = { state, dispatch };
    return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
