import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from '@reach/router';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import CitiesList from '../../components/Cities/CitiesList';

const CitiesMenu = (props) => {
  const { currentZone, currentCat } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  
  /* function handleClick(event) {
    setAnchorEl(event.currentTarget);
  } */
  
  function handleClose() {
    setAnchorEl(null);
  }
  
  function setLocation(code, cat, id, isShowcase){
      isShowcase ? navigate(`../${code}`) : navigate(`/${code}/${cat}`);
  }

  return(
    <div className="location-name">
      {/*
        currentZone.name && <h1>
          Acquista a <span onClick={handleClick} style={{ position: 'relative', display:'inline-block' }} aria-haspopup="true" aria-controls="simple-menu">{currentZone.name} &nbsp;<FontAwesomeIcon icon={faChevronDown} size="xs" /></span>
        </h1>
      */}
      {
        currentZone.name && <div className="text-center"><span className="top-title">Acquista a</span> <div className="city-search-zone"><CitiesList setLocation={setLocation} zone={currentZone.name} currentCat={currentCat} /></div></div>
      }
      {/*
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      > 
        {zones.length > 0 && zones.map(({ id, rewrite, name }) => (
          <MenuItem key={id} onClick={handleClose}><Link to={`/${rewrite}/${currentCat.info.rewrite}`}><span>{name}</span></Link></MenuItem>
        ))}
      </Menu>
      */}
    </div>
  )
}

CitiesMenu.propTypes = {
  currentZone: PropTypes.object.isRequired,
  zones: PropTypes.array.isRequired,
};

CitiesMenu.defaultProps = {
  currentZone: {},
  zones: [],
};

export default CitiesMenu;