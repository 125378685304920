import DeliveryAddress from "../../DeliveryAddress";
import Rule from './Rule';
import Zone from "../../Zone";
import Shop from "../../Shop";
import User from "../../User";
import TimeSlot from "../../TimeSlot";

class Validator {

    /**
     * Costruttore
     * @param {*} rule
     */
    constructor(rule) {
        this.rule = rule;
    }

    /**
     * Metodo statico per creare un'istanza
     * @param {*} obj
     * @returns {Validator}
     */
    static checkIf(obj) {
        const rule = new Rule(obj);
        return new Validator(rule);
    }

    /**
     * Verifica se l'oggetto passa il test
     * @returns {boolean}
     */
    passes() {
        return this.rule.validate();
    }

    /**
     * Verifica se l'oggetto fallisce il test
     * @returns {boolean}
     */
    fails() {
        return !this.passes();
    }

    /**
     * Genera un validatore per verificare se l'oggetto del test è un'istanza 
     * di DeliveryAddress
     * @param {*} obj
     * @returns {Validator}
     */
    static isAValidDeliveryAddress(obj) {
        const rule = Rule.checkIf(obj).isA(DeliveryAddress);
        return new Validator(rule);
    }

    /**
     * Imposta la regola per verificare che l'oggetto abbia tutti i parametri
     * indicati
     * @param {Array} requiredParams
     * @returns {this}
     */
    hasAllRequiredParams(requiredParams) {
        this.rule.hasAll(requiredParams);
        return this;
    }

    /**
     * Genera un validatore per verificare se l'oggetto del test è un'istanza 
     * di Zone
     * @param {*} obj
     * @returns {Validator}
     */
    static isAValidZone(obj) {
        const rule = Rule.checkIf(obj).isA(Zone);
        return new Validator(rule);
    }
    
    /**
     * Genera un validatore per verificare se l'oggetto del test è un'istanza 
     * di Shop
     * @param {*} obj
     * @returns {Validator}
     */
    static isAValidShop(obj) {
        const rule = Rule.checkIf(obj).isA(Shop);
        return new Validator(rule);
    }
    
    /**
     * Genera un validatore per verificare se l'oggetto del test è un'istanza 
     * di User
     * @param {*} obj
     * @returns {Validator}
     */
    static isAValidUser(obj) {
        const rule = Rule.checkIf(obj).isA(User);
        return new Validator(rule);
    }
    
    /**
     * Genera un validatore per verificare se l'oggetto del test è un'istanza 
     * di TimeSlot
     * @param {*} obj
     * @returns {Validator}
     */
    static isAValidTimeSlot(obj) {
        const rule = Rule.checkIf(obj).isA(TimeSlot);
        return new Validator(rule);
    }
}

export default Validator;