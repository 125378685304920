import React, { useState, useEffect } from 'react';
import DeliveryPoint from '../../api/DeliveryPoint';
import OpenStreetMap from '../Map/OpenStreetMap';
import console from './helpers';

const MapDocs = () => {
    const [address] = useState({
        street: "Via del Sagittario",
        number: "27",
        city: "Bibione",
    });
    const [markerPosition, setMarkerPosition] = useState([45.635947, 13.047939]);
    const [deliveryPointProperties, setDeliveryPointProperties] = useState({
        latitude: 45.635947,
        longitude: 13.047939,
        address: "Via del Sagittario 27 Bibione",
    });
    const [beachMarkers, setBeachMarkers] = useState([]);

    useEffect(() => {
        DeliveryPoint.fromAddress(address)
        .then((deliveryPoint) => {
            setDeliveryPointProperties(deliveryPoint.getProperties());
            setMarkerPosition(deliveryPoint.getPosition());
        });

        DeliveryPoint.onTheBeach()
        .then((list) => {
            setBeachMarkers(list);
        });
    }, [address]);

    function getMarkerInfo(info) {
        console.pretty("markerInfo", info, "dir");
    }

    return (
        <div className={"code"}>
            <h1>MapDocs</h1>
            <h3 id="api-map-point">API MapPoint</h3>
            <p>Gli oggetti MapPoint hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>latitude</strong> la latitudine</li>
                <li><strong>longitude</strong> la longitudine</li>
            </ul>
            <p>e il seguente metodo d'istanza:</p>
            <ul>
                <li><strong>getPosition()</strong> che restituisce le coordinate in un array del tipo [ <em>latitudine</em>, <em>longitudine</em> ]</li>
            </ul>
            <h3 id="api-delivery-point">API DeliveryPoint</h3>
            <p>Gli oggetti DeliveryPoint, che sono un'estensione di MapPoint, hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>latitude</strong> la latitudine</li>
                <li><strong>longitude</strong> la longitudine</li>
                <li><strong>address</strong> l'indirizzo</li>
            </ul>
            <p>i seguenti metodi <strong>statici</strong>:</p>
            <ul>
                <li><strong>fromAddress(&#123;<em>street</em>, <em>number</em>, <em>city</em>&#125;)</strong> metodo <em>asincrono</em> per ottenere le coordinate dell'indirizzo indicato</li>
                <li><strong>onTheBeach()</strong> metodo <em>asincrono</em> per ottenere dal server l'elenco di tutti gli indirizzi di consegna in spiaggia</li>
            </ul>
            <p>e i seguenti metodi d'istanza:</p>
            <ul>
                <li><strong>isCovered()</strong> metodo <em>asincrono</em> per verificare se il delivery point &egrave; coperto dal servizio. Restituisce un booleano.</li>
                <li><strong>getPosition()</strong> che restituisce le coordinate in un array del tipo [ <em>latitudine</em>, <em>longitudine</em> ]</li>
                <li><strong>getProperties()</strong> che restituisce un oggetto con coordinate e indirizzo</li>
            </ul>
            <pre>
                <code>
                    {`
                        import DeliveryPoint from '../../api/DeliveryPoint';
                        ...
                        // Per ottenere un delivery point dall'indirizzo, occorre eseguire
                        // la chiamata seguente:
                        try {
                            const deliveryPoint = await DeliveryPoint.fromAddress({
                                street: "Via del Sagittario",
                                number: "27",
                                city: "Bibione",
                            });
                        } catch(error) {
                            // qui si finisce se OpenStreetMap non risponde con
                            // delle coordinate
                        }

                        // Per estrarre le coordinate in un array con i valori, 
                        // in quest'ordine, di latitudine e longitudine, si può
                        // invocare il metodo getPosition():
                        const position = deliveryPoint.getPosition();
                        
                        // Quello che si ottiene è qualcosa del tipo:
                        // ${JSON.stringify(markerPosition)}

                        // Per ottenere tutte le proprietà di un delivery point 
                        // racchiuse in un oggetto, si può invocare getProperties():
                        const deliveryPointProperties = deliveryPoint.getProperties();

                        // Ecco cosa si ottiene:
                        // ${JSON.stringify(deliveryPointProperties)};

                        // Per verifica se il delivery point è coperto dal
                        // servizio, si interpella il server attraverso il metodo
                        // isCovered(). Il risultato sarà di tipo booleano:
                        let check;
                        try {
                            check = await deliveryPoint.isCovered();
                        } catch(error) {
                            // se il server risponde con un errore, si finisce qui
                        }

                        // Per ottenere l'elenco dei punti di consegna in spiaggia,
                        // occorre invocare il metodo onTheBeach():
                        try {
                            const list = await DeliveryPoint.onTheBeach();
                        } catch(error) {
                            // se ci sono errori sul server, si arriva qui
                        }
                    `}
                </code>
            </pre>
            <h3 id="api-osm">API OpenStreetMap</h3>
            <p>
                <span role="img" aria-label="wait">✋</span> 
                &nbsp;Apri la <strong>console</strong> per vedere alcuni esempi <em>live</em>&nbsp; 
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
            </p>
            <p>OpenStreetMap &egrave; il componente che utilizza la libreria <strong>Leaflet</strong> per renderizzare le mappe.</p>
            <p>Prevede le seguenti props:</p>
            <ul>
                <li><strong>center</strong> array contenente le coordinate su cui centrare la mappa</li>
                <li><strong>zoom</strong> il livello numerico dello zoom (default: 13)</li>
                <li><strong>maxZoom</strong> il massimo valore assumibile dalla prop zoom (default: 19)</li>
                <li><strong>markers</strong> array i cui elementi sono array di oggetti contenenti i campi <em>latitude</em>, <em>longitude</em> e <em>address</em>.</li>
                <li><strong>onMarkerClick</strong> callback per ottenere le coordinate del marker quando viene cliccato</li>
            </ul>
            <p><strong>NB:</strong> nello specificare le coordinate la latitudine deve precedere la longitudine.</p>
            <h4>Consegna a domicilio</h4>
            <OpenStreetMap
                center={[45.632684, 13.052917]}
                zoom={15}
                maxZoom={19}
                markers={[deliveryPointProperties]}
                onMarkerClick={getMarkerInfo}
            />
            <h4>Consegna in spiaggia</h4>
            <OpenStreetMap
                center={[45.632684, 13.052917]}
                zoom={15}
                maxZoom={19}
                markers={beachMarkers.map((deliveryPoint) => deliveryPoint.getProperties())}
                onMarkerClick={getMarkerInfo}
            />
        </div>
    );

};

export default MapDocs;