import React from 'react';
import Logo from '../../assets/rivemo-delivery.svg';
import Logout from '../../assets/logout.svg';
import { Link, navigate } from '@reach/router';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import User from '../../api/User';
import Alert from '../../components/Utils/Alert';
import { Store } from '../../contexts/Store/Store';
import { resetUser } from '../../contexts/Store/actions';

import './Header.scss';

const LoginButton = withStyles({
  root: {
    boxShadow: '0 0 6px rgba(0,0,0,.2)',
    textTransform: 'none',
    fontSize: '0.9em',
    padding: '10px 0px',
    width: '145px',
    border: '1px solid',
    lineHeight: 1.5,
    marginLeft:0,
    marginRight:0,
    backgroundColor: '#009fe2',
    borderColor: '#009fe2',
    borderRadius: '20px',
    fontFamily: 'Gotham Bold',
    '&:hover': {
        boxShadow: 'none',
        backgroundColor: '#009fe2',
        borderColor: '#009fe2',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#009fe2',
        borderColor: '#009fe2',
    },
  },
})(Button);

const Header = () => {
    const { state, dispatch } = React.useContext(Store);
    const user = state.user;

    const [openAlertError, setOpenAlertError] = React.useState(false);

    function handleCloseAlertError(){
        setOpenAlertError(false);
    }


    function goToLoginAndRemember(){
        sessionStorage.setItem('prevURL', window.location.href);
        navigate('/login');
    }

    function userLogout(){
        User.logoutUser()
        .then(() => {
            try {
                user.logout();
                dispatch(resetUser({ user }));
                window.location.reload();
                setOpenAlertError(false);
            } catch(error) {
                setOpenAlertError(true);
            }  
        })
        .catch(error => {
            setOpenAlertError(true);
        });      
    }

    return(
        <div>
            <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Si è verificato un errore`} />
            {
                (typeof user.isShop == 'undefined' || user.isShop === false) &&
                <header className="rivemo">
                    <div className="top-login-container">
                        <div className="top-login">
                            <Link to="/azienda">ISCRIVI LA TUA AZIENDA</Link>
                            <Link to="/rider">DIVENTA RIDER</Link>
                        </div>
                        <div className="top-login dx">
                            {
                                (typeof user.id !== 'undefined' && user.isLoggedIn()) ? (
                                    <>
                                    Ciao <strong>{ user.firstName ? user.firstName : user.email}</strong><br className="mob" /><span id="logout" onClick={userLogout}><img src={Logout} width="16" /> <strong>LOGOUT</strong></span>
                                    </>
                                ) : (
                                    <>
                                    {/*<Link to="/register" style={{ textDecoration: 'none', color: 'white' }} ><LoginButton variant="contained" color="primary" disableRipple>REGISTRATI</LoginButton></Link>*/}
                                    <LoginButton variant="contained" color="primary" disableRipple onClick={goToLoginAndRemember}>ACCEDI</LoginButton>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <Link to="/"><img src={Logo} className="logo" alt="Shopping e spesa online, consegna a domicilio con Rivemo!" /></Link>
                </header>
            }
        </div>
    )
};

export default Header;