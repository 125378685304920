import { createAction } from './helpers';

export const addProduct = createAction('product/add');
export const removeProduct = createAction('product/remove');
export const incrementProductCounter = createAction('product/counter/increment');
export const decrementProductCounter = createAction('product/counter/decrement');

export const resetCart = createAction('cart/reset');

export const setShop = createAction('shop/set');

export const setZone = createAction('zone/set');

export const setUser = createAction('user/set');
export const resetUser = createAction('user/reset');
