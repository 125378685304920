import React from 'react';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ShopGrid from './ShopGrid';
import Alert from '../Utils/Alert';
import Shop from '../../api/Shop';
import NoShop from '../../assets/no-stores.svg';

import FB from '../../assets/social/fb.svg';
import TW from '../../assets/social/tw.svg';
import WAP from '../../assets/social/wap.svg';

const FoodSubcategories = (props) => {
  const { zone, activeCategory } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [shops, setShops] = React.useState([]);
  const [shopsOut, setShopsOut] = React.useState([]);
  //const [activeSubcategory, setSubcategory] = React.useState([]);
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const [catName, setCatName] = React.useState("");

  let metaDescription = "";
  React.useEffect(() => {
    if(activeCategory){
      switch(activeCategory.info.id_lang){
        default:
        case '81':
            metaDescription = "Ordina la tua spesa online con consegna a domicilio o ritiro in negozio a Pordenone. Su Rivemo trovi supermecati, alimentari, panifici, macellerie, aziende agricole e molto altro!";
            break;
        case '66':
            metaDescription = "Ordina online su Rivemo i tuoi articoli di casalinghi, ferramenta, giardinaggio e animali a Pordenone. Scegli la comoda consegna a domicilio o ritira in negozio!";
            break;
        case '69':
            metaDescription = "Ordina online con consegna a domicilio o ritiro per asporto a Pordenone. Su Rivemo trovi ristoranti, pizzerie, gelaterie, pasticcerie, rosticcerie e gastronomie e molto altro!";
            break;
        case '72':
            metaDescription = "Con Rivemo trovi facilmente servizi a domicilio di riparazioni e manutenzioni per la casa e l'automobile a Pordenone. ";
            break;
        case '75':
            metaDescription = "Ordina online con consegna a domicilio o ritiro in negozio da farmacie, parafarmacie, erboristerie, sanitarie e ottiche a Pordenone!";
            break;
        case '78':
            metaDescription = "Fai shopping online da negozi di abbigliamento, fiorerie, profumerie e molto altro a Pordenone. Ritira in negozio oppure scegli la comoda consegna a domicilio!";
            break;
      }
    }

    let shopSameCity = [];
    let shopOtherCity = [];
    setShops([]);
    setShopsOut([]);
    setIsLoading(true);
    if(activeCategory){
      setCatName(activeCategory.info.name.toLowerCase());
      sessionStorage.setItem('activeCategory', activeCategory.info.id_lang);
      Shop.listForCategory(activeCategory, zone)
        .then((list) => {
            shopSameCity = list.filter(function(shop) {
              return shop.info.city.toLowerCase() === zone.name.toLowerCase();
            });
            shopOtherCity = list.filter(function(shop) {
              return shop.info.city.toLowerCase() !== zone.name.toLowerCase();
            });
            setShops(shopSameCity);
            setShopsOut(shopOtherCity);
            setIsLoading(false);
            setOpenAlertError(false);
        })
        .catch((error) => {
            setOpenAlertError(true);
        });
    }
  }, [zone, activeCategory]);

    function handleCloseAlertError(){
        setOpenAlertError(false);
    }

  /* async function chipClick(subcategory) {
    setSubcategory(subcategory);
    setShops(await Shop.listForSubcategory(subcategory));
  } */

  return(
    <div className="restaurant-list">
    {
      (activeCategory && zone) &&
        <Helmet defaultTitle={`${activeCategory.info.svg_title} a ${zone.name}`}>
          <title>{`${activeCategory.info.svg_title} a ${zone.name}`}</title>
          <meta charSet="utf-8" />
          <meta name="description" content={metaDescription} />
          <meta name="format-detection" content="telephone=no" />
          <link rel="canonical" href={`https://www.rivemo.it/${zone.rewrite}/${activeCategory.info.rewrite}`} />
        </Helmet>
      }
      <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Si è verificato un errore nel caricamento dei dati.`} />
      {/*
      <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0 }} className="chip-container" fixed>
        {
          subcategories.map((subcategory, index) => 
            <Chip key={`subcategory-${index}`} label={subcategory.title} className={activeSubcategory.title === subcategory.title ? "food-chip active" : "food-chip"} onClick={() => chipClick(subcategory)} component="span" clickable />
          )
        }
      </Container>
      */}

        {isLoading === true &&
          <>
          <p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>
          </>
        }
        
        {shops.length > 0 &&
          <>
          <h1>{`${shops.length} attività per ${catName} che ${shops.length === 1 ? `consegna` : `consegnano`} a ${zone.name}`}</h1>
          <h2>Fai la spesa online e richiedi la consegna a domicilio, a casa o in ufficio!</h2>
          <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0 }} className="restaurants-container" fixed>
            <Grid container direction="row" alignItems="stretch" className="restaurants-grid" spacing={0}>
              <ShopGrid activeCategory={activeCategory} shops={shops} />
            </Grid>
          </Container>
          </>
        }

        {shopsOut.length > 0 &&
          <>
          <h2>{`${shopsOut.length} attività con sede in altri Comuni, effettuano consegne a domicilio a ${zone.name}`}</h2>
          <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0 }} className="restaurants-container" fixed>
            <Grid container direction="row" alignItems="stretch" className="restaurants-grid" spacing={0}>
              <ShopGrid activeCategory={activeCategory} shops={shopsOut} isOut={1} />
            </Grid>
          </Container>
          </>
        }

        {(shops.length === 0 && shopsOut.length === 0 && isLoading === false) &&
          <div className="spiacenti">
            <img src={NoShop} alt=""  />
            <p>Siamo spiacenti, ma non abbiamo trovato nessuno disponibile nella tua zona.</p>
            <p>Conosci qualcuno?<br className="mob" /> <strong>Digli di iscriversi a Rivemo, è gratuito!</strong></p>
            <p style={{ textAlign:'center' }}>
              <a href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.rivemo.it" target="_blank" rel="noopener noreferrer" className="share-icon fb"><img src={FB} alt="facebook" height="23" /></a>
              <a href="whatsapp://send?text=https%3A%2F%2Fwww.rivemo.it%20%0ASpesa%20online%2C%20negozi%20e%20ristoranti%20con%20consegna%20a%20domicilio%20-%20Rivemo%21" target="_blank" rel="noopener noreferrer" className="share-icon wap"><img src={WAP} alt="whatsapp" height="23" /></a>
              <a href="https://twitter.com/share?url=https%3A%2F%2Fwww.rivemo.it&text=Spesa%20online%2C%20negozi%20e%20ristoranti%20con%20consegna%20a%20domicilio%20-%20Rivemo%21" target="_blank" rel="noopener noreferrer" className="share-icon tw"><img src={TW} alt="twitter" height="23" /></a>
            </p>
          </div>
        }
    </div>
  )
}

export default FoodSubcategories;