import React from "react";
import { Link } from "@reach/router";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

export default function Docs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div>
      <div className={classes.root}>
        <AppBar position="static"  color="default">
          <Tabs value={value} onChange={handleChange}>
            <Tab 
              component={Link}
              to="bibione-spiaggia"
              label="Location"
            />
            <Tab 
              component={Link}
              to="bibione-spiaggia/shop/peperoncino"
              label="Shop"
            />
            <Tab 
              component={Link}
              to="cart"
              label="Cart"
            />
            <Tab 
              component={Link}
              to="user"
              label="User"
            />
            <Tab 
              component={Link}
              to="order"
              label="Order"
            />
            <Tab 
              component={Link}
              to="map"
              label="Map"
            />
          </Tabs>
        </AppBar>
      </div>
      {props.children}
    </div>
  );
};