import React, { useEffect, useState } from 'react';
import './docs.css';
import Link from '@material-ui/core/Link';
import accessoriesData from '../../api/__tests__/fixtures/product-accessories.json';
import productData from '../../api/__tests__/fixtures/restaurant-products.json';
import User from '../../api/User';
import Token from '../../api/Token';
import Product from '../../api/Product';
import Option from '../../api/Option';
import Order from '../../api/Order';
import Payment, { CARD_PAYMENT } from '../../api/Payment';
import Braintree from '../Cart/Braintree';
import console from './helpers';

const OrderDocs = (props) => {
    const [token, setToken] = useState();
    const [order, setOrder] = useState();
    const [payment, setPayment] = useState();

    useEffect(() => {
        Token.request({
                email: "pino@silvestre.it",
                password: "verymuchnewfragrance",
            })
            .then((token) => {
                console.pretty("token", token, "dir");
                token.store();

                return User.login({
                    email: "pino@silvestre.it",
                    password: "verymuchnewfragrance",
                }, token);
            })
            .then((user) => {
                console.pretty("user", user, "dir");

                const product = new Product({ 
                    ...productData.info[2],
                    shopId: 16,
                    subcategoryId: 62,
                });
            
                const options = [
                    new Option(accessoriesData.info.accessories[0]),
                    new Option(accessoriesData.info.accessories[1]),
                ];
            
                options.forEach((option) => {
                    product.selectOption(option);
                });
            
                const cart = [{
                    counter: 2,
                    product,
                }];
                console.pretty("cart", cart, "table");

                const order = new Order({ id: 1, user });

                console.pretty("order", order, "dir");
                setOrder(order);

                const paymentMethod = Payment.init({
                    user: order.user,
                    method: CARD_PAYMENT, 
                });
                console.pretty("payment", paymentMethod, "dir");

                setPayment(paymentMethod);
            })
    }, [token]);

    return (
        <div className={"code"}>
            <h1>Order docs</h1>
            <p>
                <span role="img" aria-label="wait">✋</span> 
                &nbsp;Apri la <strong>console</strong> per vedere alcuni esempi <em>live</em>&nbsp; 
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
                <span role="img" aria-label="finger">👉</span>
            </p>
            <h3 id="api-order">API Order</h3>
            <p>Gli oggetti Order hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>id</strong> id numerico dell'ordine</li>
                <li><strong>shippingPrice</strong> il costo di spedizione</li>
                <li><strong>user</strong> oggetto User contenente dati sull'utente che effettua l'ordine</li>
                <li><strong>timeSlot</strong> oggetto TimeSlot contenente dati sullo slot selezionato</li>
            </ul>
            <p>i seguenti metodi <strong>statici</strong>:</p>
            <ul>
                <li><strong>checkout(&#123;<em>user</em>,<em>cart</em>,<em>timeSlot</em>&#125;)</strong> metodo <em>asincrono</em> per comunicare al server la creazione dell'ordine e che restituisce un'istanza di Order</li>
                <li><strong>getShippingPrice(<em>deliveryAddress</em>)</strong> metodo <em>asincrono</em> per ottenere dal server il costo della consegna presso l'indirizzo indicato (v. DeliveryAddress)</li>
            </ul>
            <p>e i seguenti metodi d'istanza:</p>
            <ul>
                <li><strong>addTimeSlot(<em>timeSlot</em>)</strong> per aggiungere le info sullo slot prescelto (v. TimeSlot)</li>
                <li><strong>purchase(<em>payment</em>)</strong> metodo <em>asincrono</em> per completare l'ordine effettuando il pagamento</li>
            </ul>
            <pre>
                <code>
                    {`
                        import Order from '../../api/Order';
                        import DeliveryAddress, { HOME_DELIVERY } from '../../api/DeliveryAddress';
                        ...
                        
                        // Per creare un ordine si parte dall'utente e dal contenuto
                        // del carrello. I dati dell'utente vanno passati sotto
                        // forma di un oggetto della classe User; il carrello
                        // dev'essere invece la proprietà cart dello stato globale
                        // dell'applicazione. Si tratta dunque di un array di oggetti
                        // ognuno dei quali ha una proprietà "product", che è un
                        // oggetto di tipo Product, e una proprietà "counter", che
                        // indica la quantità ordinata di quel prodotto.

                        // Lo user deve avere già impostato l'indirizzo di consegna.
                        // Posto che questo sia rappresentato da un oggetto di
                        // tipo DeliveryPoint, è possibile passarlo a user così:
                        try {
                            const deliveryAddress = DeliveryAddress.make({
                                ...deliveryPoint.getProperties(),
                                type: HOME_DELIVERY,
                            });
                            user.setDeliveryAddress(deliveryAddress);
                        } catch(error) {
                            // di errori possibili ci sono:
                            // - la mancanza di un parametro
                            // - un valore non valido per "type"
                        }

                        // A questo punto occorre scegliere lo slot temporale.
                        // Prima si ottiene l'elenco degli slot disponibili chiamando
                        // lo specifico metodo della classe OrderSlotsCollection.
                        // Tutti i dati richiesti da questo metodo, tranne il 
                        // tipo di consegna, sono presenti nello stato globale
                        // dell'applicazione:
                        let availableSlots;
                        try {
                            availableSlots = await OrderSlotsCollection({
                                ...state,
                                deliveryCode: HOME_DELIVERY,
                            });
                        } catch(error) {
                            // errori possibili:
                            // - parametri mancanti o non validi
                            // - il server risponde con un errore
                        }

                        // Ora ci sono tutti i dati per creare l'ordine invocando
                        // il metodo checkout():
                        try {
                            const order = await Order.checkout({
                                user, 
                                cart, 
                                timeSlot: availableSlots.today[0]
                            });
                        } catch(error) {
                            // qui si finisce se user, cart o il timeSlot non 
                            // vengono passati o sono null o undefined
                        }

                        // Una volta creato l'ordine, si può passare all'acquisto.
                        // Per far ciò occorre avere i dati del metodo di pagamento,
                        // racchiusi in un oggetto di tipo Payment (documentato più sotto)
                        try {
                            const success = order.purchase(payment);
                        } catch(error) {
                            // qui si finisce se i dati del pagamento non sono
                            // corretti o se qualcosa non piace al server
                        }
                    `}
                </code>
            </pre>
            <h3 id="api-timeslot">API TimeSlot</h3>
            <p>Gli oggetti TimeSlot hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>id</strong> id numerico</li>
                <li><strong>start</strong> orario d'inizio in formato hh:ii</li>
                <li><strong>end</strong> orario di fine in formato hh:ii</li>
                <li><strong>enabled</strong> booleano che indica se lo slot è attivo</li>
            </ul>
            <p> e il seguente metodo <strong>statico</strong> che restituisce un'istanza di TimeSlot:</p>
            <ul>
                <li><strong>parse(<em>serverData</em>)</strong> crea l'istanza a partire dai dati del server</li>
            </ul>
            <h3 id="api-orderslotscollection">API OrderSlotsCollection</h3>
            <p>Gli oggetti OrderSlotsCollection hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>today</strong> array di TimeSlot con gli slot odierni</li>
                <li><strong>tomorrow</strong> array di TimeSlot con gli slot di domani</li>
            </ul>
            <p> e il seguente metodo <strong>asincrono statico</strong> che restituisce un'istanza di OrderSlotsCollection:</p>
            <ul>
                <li><strong>allAvailableFor(&#123;<em>zone</em>,<em>shop</em>,<em>user</em>,<em>cart</em>,<em>deliveryCode</em>&#125;)</strong> crea l'istanza con i dati ottenuti dal server</li>
            </ul>
            <h3 id="api-delivery-address">API DeliveryAddress</h3>
            <p>Gli oggetti DeliveryAddress hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>id</strong> id numerico</li>
                <li><strong>address</strong> l'indirizzo</li>
                <li><strong>type</strong> il tipo di consegna (v. sotto per i valori consentiti)</li>
                <li><strong>latitude</strong> la latitudine</li>
                <li><strong>longitude</strong> la longitudine</li>
            </ul>
            <p>i seguenti metodi <strong>statici</strong>:</p>
            <ul>
                <li><strong>make(&#123;<em>id</em>, <em>address</em>, <em>type</em>, <em>latitude</em>, <em>longitude</em>&#125;)</strong> per creare un oggetto verificando la validità dei dati</li>
                <li><strong>isThisAValidTypeCode(<em>code</em>)</strong> per verificare la validit&agrave; del codice del tipo di consegna</li>
            </ul>
            <p>e il seguente metodo d'istanza:</p>
            <ul>
                <li><strong>withId(<em>id</em>)</strong> per aggiungere l'id fornito dal server</li>
            </ul>
            <p>I possibili valori per il parametro <strong>type</strong>, importabili dalla stessa libreria DeliveryAddress, sono:</p>
            <ul>
                <li>HOME_DELIVERY</li>
                <li>BEACH_DELIVERY</li>
                <li>RESTAURANT_DELIVERY</li>
            </ul>
            <h3 id="api-payment">API Payment</h3>
            <p>Gli oggetti Payment hanno le seguenti propriet&agrave;:</p>
            <ul>
                <li><strong>user</strong> oggetto User contenente dati sull'utente che effettua il pagamento</li>
                <li><strong>method</strong> il codice del metodo di pagamento (v. sotto)</li>
            </ul>
            <p>il seguente metodo <strong>statico</strong> che restituisce un'istanza di Order:</p>
            <ul>
                <li><strong>init(&#123;<em>user</em>,<em>method</em>&#125;)</strong> per creare un'istanza del pagamento</li>
            </ul>
            <p>e i seguenti metodi d'istanza:</p>
            <ul>
                <li><strong>getToken()</strong> metodo <em>asincrono </em>per ottenere il client token di Braintree nel caso di pagamento con <strong>carta di credito</strong></li>
                <li><strong>addNonce()</strong> per memorizzare il nonce di Braintree nel caso di pagamento con <strong>carta di credito</strong></li>
                <li><strong>isByCard()</strong> per verificare se il pagamento è con carta di credito</li>
                <li><strong>isInCash()</strong> per verificare se il pagamento è in contanti</li>
            </ul>
            <p>I codici dei metodi di pagamento sono importabili dalla libreria Payment e sono:</p>
            <ul>
                <li>CASH_PAYMENT</li>
                <li>CARD_PAYMENT</li>
            </ul>
            <pre>
                <code>
                    {`
                        import Payment, { CASH_PAYMENT, CARD_PAYMENT } from '../api/Payment';
                        ...
                        
                        // Nel caso di pagamento in contanti, il workflow è semplice:
                        const payment = Payment.init({
                            user,
                            method: CASH_PAYMENT,
                        });

                        // Et voilà! Abbiamo un'istanza di Payment da passare a
                        // order.purchase()

                        // Nel caso di pagamento con carta di credito, processata
                        // dunque da Braintree, i passaggi sono di più.
                        // Bisogna prima ottenere il client token con cui inizializzare
                        // il drop-in (https://github.com/Cretezy/braintree-web-drop-in-react#readme)
                        const payment = Payment.init({
                            user,
                            method: CARD_PAYMENT,
                        });
                        try {
                            const clientToken = await payment.getToken();
                        } catch(error) {
                            // si finisce qui se il server delle API risponde picche
                        }
                        // Con il client token si inizializza il drop-in.
                        // Quindi dal drop-in dobbiamo ottenere il nonce da inviare
                        // al server delle API per chiudere il cerchio.
                        // Dunque, si memorizza il nonce in payment:
                        payment.addNonce(nonce);

                        // e ora si può concludere invocando order.purchase()
                    `}
                </code>
            </pre>
            <div className={"code"}>
                <h2>Demo del drop-in di Braintree</h2>
                <ul>
                    <li>Un numero di carta per i test &egrave; <strong>4111111111111111</strong></li>
                    <li>Altri se ne possono trovare <Link href="https://developers.braintreepayments.com/reference/general/testing/php">a questo link</Link></li>
                    <li>Expiration date e CVV possono assumere qualsiasi valore pertinente con le indicazioni fornite dalla UI</li>
                </ul>
                {
                    !payment ? (
                        <div><h3>Loading...</h3></div>
                    ) : (
                        <Braintree order={order} payment={payment} />
                    )
                }
            </div>
        </div>
    );
};

export default OrderDocs;