import React from 'react';
import {Helmet} from "react-helmet";

import TopShop from '../../components/Shop/TopShop';
import ShopMenu from '../../components/Shop/ShopMenu';
import ShopInfo from '../../components/Shop/ShopInfo';
import Alert from '../../components/Utils/Alert';
import { Store } from '../../contexts/Store/Store';
import { setShop, resetCart, setUser } from '../../contexts/Store/actions';
import ShopAPI from '../../api/Shop';
import Category from '../../api/Category';

import Token from '../../api/Token';
import User from '../../api/User';

import './Shop.scss';

import TagManager from 'react-gtm-module'

const pushGTM = (x, y, z) => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview',
        'categoryComune': x,
        'businessCategory': y,
        'shopName': z,
    },
    dataLayerName: 'pageview'
});

const Shop = (props) => {
    const { zoneCode, catCode, shopName, shopID, hash } = props;
    const { state, dispatch } = React.useContext(Store);

    const [currentShop, setCurrentShop] = React.useState();
    const [openAlertError, setOpenAlertError] = React.useState(false);
    const [category, setCategory] = React.useState(null);

    const zoneName = state.zone.name ? state.zone.name : zoneCode;
    const zoneRewrite = state.zone.rewrite ? state.zone.rewrite : zoneCode;

    function getShop(sID) {
      ShopAPI.load(sID)
      .then((shop) => {
          dispatch(setShop({ shop }));
          setCurrentShop(shop);
          setOpenAlertError(false);
          if(!hash && category) pushGTM(zoneName, category.info.name, shop.info.company_name);
      })
      .catch((error) => {
          setOpenAlertError(true);
      });
    }

    React.useEffect(() => {
      let currentCat = [];
      setCategory(null);
      Category.departments()
      .then((list) => {
          currentCat = list.filter(function(cat) {
            return cat.info.rewrite.toLowerCase() === catCode.toLowerCase();
          });
          setCategory(new Category(currentCat[0].info));
      })
      .catch((error) => {
          console.log(error)
      });
  }, [catCode]);

    React.useEffect(() => {
      dispatch(resetCart());
      if (!hash && category) {
          getShop(shopID);
      }
    }, [category]);
  

    function handleCloseAlertError(){
        setOpenAlertError(false);
    }

    React.useEffect(() => {
      if(hash){
        const hash_email = hash.split('|')[0];
        const hash_password = hash.split('|')[1];
        Token.request({
            email: hash_email,
            password: hash_password,
            crypt: 1
        })
        .then((token) => {
            token.store();
            const user = User.login({
                email: hash_email,
                password: hash_password,
                crypt: 1
            }, token, true);
            return user;
        })
        .then((user) => {
            dispatch(setUser({ user }));
            setOpenAlertError(false);
            getShop(shopID);
        })
        .catch(err => {
            setOpenAlertError(true);
        });
      }
    }, [hash]);

  return(
    <div>
      <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Si è verificato un errore nel caricamento dei dati.`} />
      {
        (currentShop && currentShop.id  && category) && 
        <Helmet defaultTitle={(currentShop && currentShop.id  && category) && `${currentShop.info.company_name} ${category.info.name} (${zoneName}) con consegna a domicilio o ritiro - Rivemo!`}>
          <title>{`${currentShop.info.company_name} ${category.info.name} (${zoneName}) con consegna a domicilio o ritiro - Rivemo!`}</title>
          <meta charSet="utf-8" />
          <meta name="description" content={(currentShop && currentShop.id) && `${currentShop.info.description}`} />
          <meta name="format-detection" content="telephone=no" />
          <link rel="canonical" href={`https://www.rivemo.it/${zoneCode}/${catCode}/${shopName}/${shopID}`} />
        </Helmet>
      }
      {
          currentShop && currentShop.id && category && (
            <>
            {
              (typeof state.user.isShop == 'undefined' || state.user.isShop === false) &&
              <TopShop shop={currentShop} zoneName={zoneName} zoneRewrite={zoneRewrite} category={category} />
            }
            <div className="shop-page">
              {currentShop.info.has_catalog == 1 ? (
                <ShopMenu shop={currentShop} />
              ) : (
                <ShopInfo shop={currentShop} />
              )}
            </div>
            </>
          )
      }
    </div>
  )
}

export default Shop;