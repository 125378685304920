import http, { BASE_URL } from './lib/http';

const STORAGE_KEY = 'token';

class Token {

    /**
     * Costruttore
     * @param {object} data
     * @param {string} data.accessToken
     * @param {object} data.info
     */
    constructor({ accessToken, info }) {
        this.accessToken = accessToken;
        this.info = info;
    }

    /**
     * Metodo statico per richiedere il token di accesso al server
     * @param {object} credentials 
     * @param {string} credentials.email 
     * @param {string} credentials.password 
     * @returns {Token}
     * @throws {Error}
     */
    static async request(credentials) {

        const requiredParams = [
            'email',
            'password',
        ];

        if (!requiredParams.every((param) => Boolean(credentials[param]))) {
            throw new Error("Data is missing!");
        }

        let response;
        try {
            response = await http.auth(`${BASE_URL}/oauth2/token`, credentials);
        } catch (error) {
            throw error;
        }

        return Token.load(response);
    }

    /**
     * Memorizza il token di accesso nello storage indicato. Se non viene 
     * indicato uno storage specifico, viene utilizzato sessionStorage.
     * @param {object} storage libreria di storage da utilizzare
     * @returns {this}
     */
    store(storage = null) {
        this.storage = storage;

        (this.storage || window.sessionStorage).setItem(STORAGE_KEY, this.accessToken);

        return this;
    }

    /**
     * Metodo statico per creare un'istanza della classe partendo da dati 
     * provenienti dal server o dallo storage
     * @param {object} source 
     * @returns {Token}
     */
    static load(source = null) {

        if (source && source.access_token) {
            const data = Token.parseServerResponse(source);
            return new Token(data);
        }

        if (source && source.getItem) {
            this.storage = source;
        }

        const accessToken = (this.storage || window.sessionStorage).getItem(STORAGE_KEY);

        return new Token({ accessToken });
    }

    /**
     * Processa la risposta del server e la mappa con quanto atteso dal costruttore
     * @param {object} serverResponse 
     * @returns {object}
     */
    static parseServerResponse(serverResponse) {
        const data = {
            accessToken: serverResponse.access_token,
            info: {...serverResponse},
        };
        delete data.info.access_token;

        return data;
    }

    /**
     * Elimina il token dallo storage
     * @returns {boolean}
     */
    forget() {
        const storage = this.storage || window.sessionStorage;

        storage.removeItem(STORAGE_KEY);

        return Boolean(storage.getItem(STORAGE_KEY)) === false;
    }

    /**
     * Resituisce il valore del token di accesso memorizzato nello storage
     * @returns {string}
     */
    get() {
        return (this.storage || window.sessionStorage).getItem(STORAGE_KEY);
    }
}

export default Token;