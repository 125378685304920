import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import DropIn from "braintree-web-drop-in-react";
import Button from '@material-ui/core/Button';
import { Redirect } from '@reach/router';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'

import Alert from '../Utils/Alert';

const LoginButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: 'Gotham Bold',
    fontSize: '1.125em',
    padding: '10px 100px',
    border: '1px solid',
    lineHeight: 1.5,
    display: 'block',
    marginLeft:'auto',
    marginRight:'auto',
    backgroundColor: '#009fe2',
    borderColor: '#009fe2',
    borderRadius: '30px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
    '&:active': {
        backgroundColor: '#fbca00',
        borderColor: '#fbca00',
        color: '#000000'
    },
  },
})(Button);

const Braintree = (props) => {
    const { order, payment } = props;
    const [clientToken, setClientToken] = useState();
    const [instance, setInstance] = useState();
    const [isBuying, setIsBuying] = useState(false);
    const [successfulTransaction, setSuccessfulTransaction] = useState(false);
    const [openAlertPurchaseCashError, setOpenAlertPurchaseCashError] = React.useState(false);

    useEffect(() => {
        payment.getToken()
            .then((token) => {
                setClientToken(token);
                setOpenAlertPurchaseCashError(false);
            })
            .catch(error => {
                setOpenAlertPurchaseCashError(true);
            });
    }, [payment]);

    async function buy(instance) {
        setIsBuying(true);
        const { nonce } = await instance.requestPaymentMethod();
        
        payment.addNonce(nonce);

        let success;
        try {
            success = await order.purchase(payment);
            setOpenAlertPurchaseCashError(false);
        } catch (error) {
            success = false;
            setIsBuying(false);
            setOpenAlertPurchaseCashError(true);
        }

        setSuccessfulTransaction(success);
    }

    function handleCloseAlertError(){
        setOpenAlertPurchaseCashError(false);
    }

    return (
        <div>
            <Alert open={openAlertPurchaseCashError} onClose={handleCloseAlertError} title="Attenzione" content={`Il pagamento non è andato a buon fine, si prega di riprovare.`} />
        {!clientToken ? (
                <p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>
           ) : !successfulTransaction ? (
                <>
                <DropIn
                    options={{ authorization: clientToken }}
                    onInstance={(instance) => (setInstance(instance))}
                />
                {!isBuying && <LoginButton variant="contained" color="primary" disableRipple onClick={() => buy(instance)}>PAGA E INVIA ORDINE</LoginButton>}
                </>
            ) : ( 
                <Redirect noThrow to={`/your-order/${order.id}`} />
            )
        }
        </div>
    );

};

Braintree.propTypes = {
    order: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
}

export default Braintree;