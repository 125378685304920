import http, { BASE_URL } from './lib/http';
import Subcategory from './Subcategory';
import withZone from './lib/withZone';

class Category {
    
    /**
     * Costruttore
     * @param {Object} data 
     */
    constructor({ ...info }) {
        this.info = info;
    }

    /**
     * Restituisce l'elenco delle categorie  
     * @returns {Array<Category>}
     * @throws {Error} 
     */
    static async departments() {
        try {
            const data = await http.get(`${BASE_URL}/departments`);
            return data.map((departments) => new Category(departments));
        } catch (error) {
            throw error;
        }
    }
    
    /**
     * Restituisce l'elenco delle categorie associate alla zona 
     * @param {Zone} zone
     * @returns {Array<Category>}
     * @throws {Error} 
     */
    static async listForZone(zone) {
        if (!zone || !zone.id) {
            throw new Error("Zone data is missing!")
        }

        try {
            //const data = await http.get(`${BASE_URL}/get-macro-categories-by-zone/${zone.id}`);
            const data = await http.get(`${BASE_URL}/departments`);
            return data.map((categoryInfo) => withZone(zone)(new Category(categoryInfo)));
        } catch (error) {
            throw error;
        }
    }

    /**
     * Restituisce l'elenco delle sottocategorie
     * @returns {Array<Subcategory>}
     * @throws {Error} 
     */
    async getSubcategories() {
        if (this.subcategories && this.subcategories.length > 0) {
            return this.subcategories;
        }

        try {
            await this.loadSubcategories();
        } catch (error) {
            throw error;
        }

        return this.subcategories;
    }

    /**
     * Carica le sottocategorie dal server e le memorizza nella proprietà
     * subcategories
     * @returns {Category}
     * @throws {Error}
     */
    async loadSubcategories() {
        if (!this.info.encoded_title) {
            throw new Error("Encoded title is missing!");
        }

        try {
            const data = await http.get(`${BASE_URL}/get-sub-categories-by-macrocat-and-zone/${this.info.encoded_title}/${this.zone.id}`);
            this.subcategories = data.map((subcategoryInfo) => withZone(this.zone)(new Subcategory(subcategoryInfo)));
        } catch (error) {
            throw error;
        }

        return this;
    }
}

export default Category;