import http, { BASE_URL } from './lib/http';
import Validator from './lib/validation/Validator';

class Order {

    /**
     * Costruttore
     * @param {object} data 
     * @param {number} data.id 
     */
    constructor({ id, shippingPrice, user, timeSlot }) {
        this.id = id;
        this.shippingPrice = shippingPrice;
        this.user = user;
        this.timeSlot = timeSlot;
    }

    /**
     * Metodo statico la creazione di un ordine sul server
     * @param {object} params 
     * @param {User} params.user 
     * @param {Array<object>} params.cart
     * @param {TimeSlot} params.timeSlot
     * @returns {Order}
     * @throws {Error} 
     */
    static async checkout({ user, cart, timeSlot }) {

        if (Validator.isAValidUser(user).fails()) {
            throw new Error("Not a valid user!");
        }

        if (!cart) {
            throw new Error("Data is missing!");
        }

        /* if (Validator.isAValidTimeSlot(timeSlot).fails()) {
            throw new Error("Not a valid TimeSlot!");
        } */

        let response;
        try {
            await user.confirmDeliveryAddress();

            response = await http.post(
                `${BASE_URL}/order-checkout`,
                Order.formatCheckoutRequest(user, cart, timeSlot),
                {
                    "Access-Token": user.token.get(),
                }
            );
        } catch (error) {
            throw error;
        }

        return new Order({
            user,
            timeSlot,
            ...Order.parseServerResponse(response), 
        });
    }

    /**
     * Formatta i dati ricevuti dal server per conformarli a quanto atteso
     * dal costruttore
     * @param {object} data 
     * @param {number} data.id 
     * @param {number} data.ship_price
     * @returns {object} 
     */
    static parseServerResponse({ id, ship_price }) {
        return {
            id,
            shippingPrice: ship_price,
        };
    }

    /**
     * Metodo statico per formattare i dati secondo quanto richiesto dalla
     * documentazione delle API. Estrapolato da checkout per mere ragioni
     * di testabilità
     * @param {User} user 
     * @param {Array<object>} cart
     * @param {TimeSlot} timeSlot
     * @returns {object} 
     */
    static formatCheckoutRequest(user, cart, timeSlot) {
        return {
            recipient_id: user.id,
            extra: [],
            products: cart.map(({ counter, product }) => ({
                amount: counter,
                title: product.title,
                product_id: product.id,
                product_code: product.info.product_code,
                extra: product.selectedOptions.map(({ id }) => ({ id, amount: 1, })),
                accessories_excluded: product.removedStandardOptions.map(({ id }) => ({ id })),
                note: product.note || ""
            })),
            address_id: user.deliveryAddress.id,
            slot_id: timeSlot.id,
            ship_notes: user.note || "",
        };
    }

    /**
     * Effettua la richiesta al server per completare l'acquisto
     * @param {Payment} payment 
     * @returns {boolean}
     * @throws {Error}
     */
    async purchase(payment) {

        const user_note = this.user.note || "";
        const user_firstName = this.user.deliveryAddress.firstName || "";
        const user_lastName = this.user.deliveryAddress.lastName || "";
        const user_phone = this.user.deliveryAddress.phone || "";
        const user_email = this.user.deliveryAddress.email || "";
        const user_info = user_note + "\n nome: " + user_firstName + "\n cognome: " + user_lastName + "\n telefono: " + user_phone + "\n email: " + user_email;
        
        const { 
            method: payment_code, 
            nonce: payment_method_nonce,
        } = payment.getData();

        if (payment.isByCard() && !payment_method_nonce) {
            throw new Error("nonce is missing!");
        }

        try {
            await http.post(
                `${BASE_URL}/set-payment`,
                {
                    origin: this.user.isShop ? 'PHN' : 'WEB',
                    note: this.user.isShop ? user_info : user_note,
                    order_id: this.id,
                    payment_code,
                    payment_method_nonce,
                },
                {
                    "Access-Token": this.user.token.get(),
                }
            );
        } catch (error) {
            throw error;
        }

        return true;
    }

    /**
     * Richiede al server il costo di spedizione per l'indirizzo indicato
     * @param {DeliveryAddress} deliveryAddressType
     * @returns {number} 
     * @throws {Error}
     */
    static async getShippingPrice(deliveryAddressType, id_catalog) {

        try {
            const { ship_price } = await http.get(`${BASE_URL}/shipping-costs/${deliveryAddressType}/${id_catalog}`);
            return ship_price;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Richiede al server il costo di spedizione per l'indirizzo indicato
     * @param {DeliveryAddress} deliveryAddress
     * @returns {number} 
     * @throws {Error}
     */
    static async getShippingPriceDelivery(deliveryAddress, id_catalog) {

        if (Validator.isAValidDeliveryAddress(deliveryAddress).fails()) {
            throw new Error("Not a valid DeliveryAddress!");
        }

        try {
            const { ship_price } = await http.get(`${BASE_URL}/shipping-costs/${deliveryAddress.type}/${id_catalog}`);
            return ship_price;
        } catch (error) {
            throw error;
        }
    }

    /**
     * Memorizza lo slot prescelto per completare l'ordine
     * @param {TimeSlot} timeSlot 
     * @returns {this}
     * @throws {Error}
     */
    addTimeSlot(timeSlot) {

        if (Validator.isAValidTimeSlot(timeSlot).fails()) {
            throw new Error("Not a valid TimeSlot!");
        }

        this.timeSlot = timeSlot;

        return this;
    }
}

export default Order;