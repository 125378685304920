import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link, navigate } from "@reach/router"

import { ReactComponent as Onda } from '../../assets/onda.svg';
import { ReactComponent as BgApp } from '../../assets/bg-blu.svg';
import { ReactComponent as Ico } from '../../assets/ico-rivemo.svg';
import { ReactComponent as NextArrow } from '../../assets/arrow-sx.svg';

import Coda from '../../assets/motivi/1.svg';
import Driver from '../../assets/motivi/2.svg';
import Tempo from '../../assets/motivi/3.svg';
import Cibi from '../../assets/motivi/4.svg';
import Prezzi from '../../assets/motivi/5.svg';

import iphone from '../../assets/iphone.png';
import appStore from '../../assets/app-store.png';
import playStore from '../../assets/play-store.png';
import appStore2x from '../../assets/app-store@2x.png';
import playStore2x from '../../assets/play-store@2x.png';
import Slide1 from '../../assets/slide1.jpg';
import Slide2 from '../../assets/slide2.jpg';
import Slide3 from '../../assets/slide3.jpg';

import CitiesList from '../../components/Cities/CitiesList';
import Alert from '../../components/Utils/Alert';

import Category from '../../api/Category';

import './Home.scss';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: 0,
  },
  transparent: {
    textAlign: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
  },
  slideImg: {
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: 0,
  },
  slideText: {
    textAlign: 'left',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    fontSize: '2.5em',
  },
}));

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <NextArrow
      className={className}
      onClick={onClick}
    />
  );
}

const Home = () => {

    const [locationCode, setLocationCode] = useState("");
    const [isShowcase, setIsShowcase] = useState(false);
    const [categories, setCategories] = useState([]);
    const [openAlertError, setOpenAlertError] = useState(false);
    const citySearch = document.getElementById('citySearch');
    
    useEffect(() => {
        pushGTM();
        Category.departments()
        .then((list) => {
            setCategories(list);
            setOpenAlertError(false);
        })
        .catch((error) => {
            setOpenAlertError(true);
        });
    }, []);

    const classes = useStyles();
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <SampleNextArrow />,
      nextArrow: <SampleNextArrow />,
      appendDots: dots => (
        <div className="dot-container">
          <ul style={{ margin: "0px", padding:"0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div className="page-dot">
          {i + 1}
        </div>
      )
    };

    function handleCloseAlertError(){
        setOpenAlertError(false);
    }

    
    function setLocation(code, cat, id, showcase){
        setLocationCode(code);
        setIsShowcase(showcase);
        //setLocationId(id);
        citySearch.classList.remove('flash');
    }

    function getLocationCode(){
        citySearch.classList.remove('flash');
        if(locationCode === "") {
            window.setTimeout(function(){citySearch.classList.add('flash');}, 100);
        } else {
            citySearch.classList.remove('flash');
            isShowcase ? navigate(locationCode) : navigate(`${locationCode}/pizzerie-ristoranti-consegna-a-domicilio-o-asporto`);
        }
    }

    return (
        <div>
            <Helmet defaultTitle="Spesa online, negozi e ristoranti con consegna a domicilio | Rivemo!">
                <title>Spesa online, negozi e ristoranti con consegna a domicilio | Rivemo!</title>
                <meta name="description" content="Rivemo ti consegna a domicilio prodotti di ristoranti, negozi, panifici e molto altro! Fai la spesa online, ritira per asporto oppure scegli il comodo servizio di delivery." />
                <meta name="format-detection" content="telephone=no" />
                <meta property="og:url" content="https://www.rivemo.it" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Spesa online, negozi e ristoranti con consegna a domicilio | Rivemo!" />
                <meta property="og:description" content="Rivemo ti consegna a domicilio prodotti di ristoranti, negozi, panifici e molto altro! Fai la spesa online, ritira per asporto oppure scegli il comodo servizio di delivery." />
                <meta property="og:image" content={Slide3} />
            </Helmet>
            <Alert open={openAlertError} onClose={handleCloseAlertError} title="Attenzione" content={`Si è verificato un errore nel caricamento dei dati.`} />
            <div className="top-container">
                <Onda className="onda" />
                <h1>Shopping e spesa online, <br />consegna a domicilio!</h1>
                <div className="reparti-home">
                    <div className="zone-search">
                        <div id="citySearch" className="city-search">
                            <div className="city-input"><CitiesList setLocation={setLocation} /></div>
                            <span className="city-btn" onClick={getLocationCode}>CERCA</span>
                        </div>
                        <span className="city-btn mob" onClick={getLocationCode}>CERCA</span>
                        <h2>Cerca negozi e ristoranti che consegnano a domicilio nel tuo Comune</h2>
                    </div>
                    <div className="reparti-home-icons">
                        {
                            categories.map((category, index) => 
                            <div className="reparti-home-icon" key={`category-${index}`}>
                                <span className="reparti-home-icon-img">
                                    <img src={category.info.svg} alt={category.info.svg_title} />
                                </span>
                                {category.info.name}
                            </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="why-rivemo">
                <h2>5 buoni motivi per scegliere RIVEMO!</h2>
                <Grid container direction="row" justify="center" alignItems="flex-end" spacing={0}>
                    <Grid item xs={12} sm={4} md>
                        <Paper className={classes.paper}>
                            <img src={Coda} alt="Niente code" className="motivi-img" />
                            <div className="why"><em>Trovi facilmente e in un unico luogo tutte le attività che fanno consegne a domicilio nella tua zona.</em><span className="num">1</span></div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                        <Paper className={classes.paper}>
                            <img src={Driver} alt="Rider" className="motivi-img" />
                            <div className="why"><em>Eviti stress e borse pesanti saltando le code. Ordini velocemente e ricevi tutto a casa tua.</em><span className="num">2</span></div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                        <Paper className={classes.paper}>
                            <img src={Tempo} alt="Consegna rapida" className="motivi-img" />
                            <div className="why"><em>Puoi ordinare di tutto: dalla pizza allo shampoo direttamente nelle tue mani.</em><span className="num">3</span></div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                        <Paper className={classes.paper}>
                            <img src={Cibi} alt="Puoi ordinare di tutto" className="motivi-img" />
                            <div className="why"><em>Consegna rapida, precisa e in totale sicurezza.</em><span className="num">4</span></div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                        <Paper className={classes.paper}>
                            <img src={Prezzi} alt="Prezzi trasparenti" className="motivi-img" />
                            <div className="why"><em>Prezzi trasparenti, non applichiamo maggiorazioni e non lo faremo mai.</em><span className="num">5</span></div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div className="app-rivemo">
                <BgApp className="bg-app" />
                <Container style={{ maxWidth: '1040px', position: 'relative', zIndex:0 }} fixed>
                    <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                        <Grid item xs={12} sm>
                            <Paper className={classes.transparent}>
                                <div className="ico-app">
                                    <Ico /><br />
                                    <p>
                                        <strong>Scarica l’app.</strong><br className="mob" />
                                        Ordina ciò che <br />desideri!
                                    </p>
                                    <a href="https://itunes.apple.com/us/app/rivemo/id1410851454?l=it&ls=1&mt=8" target="_blank" rel="noopener noreferrer" className="app-img"><img src={appStore} srcSet={`${appStore2x} 2x`} alt="App Store" className="app-icons ios" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=it.wedigital.rivemo" target="_blank" rel="noopener noreferrer" className="app-img"><img src={playStore} srcSet={`${playStore2x} 2x`} alt="Google Play Store" className="app-icons android" /></a>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm>
                            <Paper className={classes.transparent}>
                                <div className="iphone-app"><img src={iphone} alt="iPhone" /></div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className="how-to">
                <h2>Ordina ciò che ti serve <br />e ricevilo a casa o in ufficio!</h2>
                <Slider {...settings}>
                    <div>
                        <Container style={{ maxWidth: '1800px', position: 'relative', zIndex:0 }} fixed>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                                <Grid item sm={12} md={8} lg>
                                    <Paper className={classes.slideImg}>
                                        <img src={Slide1} alt="" />
                                    </Paper>
                                </Grid>
                                <Grid item sm={12} md={4} lg>
                                    <Paper className={classes.slideText}>
                                        <div className="slide-text">Trova le attività vicine a te che vendono ciò che ti serve</div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                    <div>
                        <Container style={{ maxWidth: '1800px', position: 'relative', zIndex:0 }} fixed>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                                <Grid item sm={12} md={8} lg>
                                    <Paper className={classes.slideImg}>
                                        <img src={Slide2} alt="" />
                                    </Paper>
                                </Grid>
                                <Grid item sm={12} md={4} lg>
                                    <Paper className={classes.slideText}>
                                        <div className="slide-text">Ordina quello che preferisci.</div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                    <div>
                        <Container style={{ maxWidth: '1800px', position: 'relative', zIndex:0 }} fixed>
                            <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                                <Grid item sm={12} md={8} lg>
                                    <Paper className={classes.slideImg}>
                                        <img src={Slide3} alt="" />
                                    </Paper>
                                </Grid>
                                <Grid item sm={12} md={4} lg>
                                    <Paper className={classes.slideText}>
                                        <div className="slide-text">Ricevilo in breve tempo direttamente a casa tua.</div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Slider>
            </div>
            <div className="home-form">
                <h2>Collabora con Rivemo per le consegne a domicilio</h2>
                <div className="home-form-row">
                    <div className="home-form-card">
                        <div className="home-form-img azienda"></div>
                        <h3>Sei un negoziante?</h3>
                        <p>Vuoi aumentare le tue vendite? Iscrivendo la tua attività a Rivemo otterrai un canale di vendita virtuale pronto all’uso in modo completamente gratuito e i tuoi clienti potranno trovarti con un semplice click.</p>
                        <Link to="/azienda" className="form-btn">ISCRIVI LA TUA ATTIVITÀ</Link>
                    </div>
                    <div className="home-form-card">
                        <div className="home-form-img rider"></div>
                        <h3>Vuoi diventare un rider?</h3>
                        <p>Vuoi lavorare in modo flessibile? Le consegne a domicilio sono l’occasione giusta per te! Diventando un rider di Rivemo potrai gestire i tuoi orari in autonomia e lavorare anche nel tuo tempo libero.</p>
                        <Link to="/rider" className="form-btn">INVIA LA TUA CANDIDATURA</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home