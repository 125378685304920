import React from 'react';
import {Helmet} from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link } from '@reach/router';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ReactComponent as Onda } from '../../assets/onda.svg';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    flexBasis: '100%',
    flexShrink: 0,
  }
}));

const Faq = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    
    React.useEffect(() => {
        pushGTM();
    }, []);
    
    return(
        <>
            <Helmet defaultTitle="Qualcosa non ti è chiaro? Trova informazioni utili e chiarimenti nelle Faq di Rivemo!">
                <title>Qualcosa non ti è chiaro? Trova informazioni utili e chiarimenti nelle Faq di Rivemo!</title>
                <meta name="description" content="Se hai bisogno di chiarimenti o di qualche informazione in più consulta le nostre Faq. Abbiamo preparato alcune risposte alle domande più frequenti dei nostri clienti." />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-location in-cart">
                <Onda className="onda" />
                <div className="shop-products thank-you">
                    <Container style={{ maxWidth: '1180px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                        <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
                            <span className="thank-you-title">FAQ</span>
                            <span className="thank-you-subtitle">Spesa online, negozi e ristoranti con consegna a domicilio? Rivemo!</span>
                        </div>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2} className="faq-container">
                            {/*<Grid item xs={12} sm={4}>
                                <img src={FaqImg} alt="Faq" style={{ maxWidth:'100%' }} />
                            </Grid>*/}
                            <Grid item xs={12} sm={12}>
                                <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Cos’è Rivemo?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails> 
                                        <Typography className="faq-text">
                                            Rivemo è il servizio che ti permette di scoprire quali attività della tua zona effettuano consegne a domicilio. Dal 2018 e abbiamo effettuato migliaia di consegne; scarica l’app o visita il sito, potrai ordinare ciò che ti serve e lo riceverai comodamente a casa tua in breve tempo!
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Come faccio a sapere se il servizio è presente nella mia zona?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails> 
                                        <Typography className="faq-text">
                                            Che tu stia utilizzando l’app o il sito, ti basterà digitare il tuo indirizzo o indicare il tuo comune per scoprire quali attività offrono servizio di consegna a domicilio vicino a te.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Come faccio a sapere se le consegne avvengono in sicurezza?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails> 
                                        <Typography className="faq-text">
                                            I collaboratori e le aziende partner di Rivemo si attengono scrupolosamente alle raccomandazioni del Ministero della Salute, di seguito un elenco delle principali misure:<br />
                                            1) Gli esercenti mettono a disposizione del proprio personale prodotti igienizzanti, assicurandosi del loro utilizzo tutte le volte che ne occorra la necessità. <br />
                                            2) Nello svolgimento di tutte le attività necessarie all’evasione dell’ordine, gli addetti mantengono sempre la distanza interpersonale di almeno un metro dai colleghi.<br />
                                            3) Nel caso di preparazione di alimenti, il cibo viene chiuso in appositi contenitori tramite adesivi chiudi sacchetto, graffette o altro per assicurarne la massima protezione.<br />
                                            4) Il cibo da asporto  viene riposto immediatamente negli zaini termici o nei contenitori per il trasporto che vengono mantenuti puliti con prodotti igienizzanti, per garantire il mantenimento dei requisiti di sicurezza alimentare.<br />
                                            5) La consegna della merce avviene mantenendo  la distanza di sicurezza interpersonale di almeno un metro e l’assenza di contatto diretto.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Quanto costa la consegna?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails> 
                                        <Typography className="faq-text">
                                            Rivemo non impone dei costi fissi di consegna, che variano a seconda dell’esercente e della distanza da percorrere. Sia in caso di pagamento tramite app o sito, che in caso di pagamento alla consegna, vedrai chiaramente il prezzo di consegna indicato al momento dell’ordinazione.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Quali sono i metodi di pagamento previsti?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails> 
                                        <Typography className="faq-text">
                                            Pagando attraverso app o sito Rivemo, puoi sempre utilizzare i principali circuiti di carte di credito e Paypal, mentre la possibilità di pagare in contanti potrebbe non essere sempre disponibile poichè dipende dall’esercente: controlla nella scheda “condizioni” oppure telefona chiadi maggiori informazioni telefonicamente.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Posso ordinare da app e ritirare in negozio?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails> 
                                        <Typography className="faq-text">
                                            Molti negozi lo permettono, consigliamo di verificare telefonicamente eventuali giorni e orari in cui sono disponibili.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>L’ordine consegnato è sbagliato, cosa posso fare?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails> 
                                        <Typography className="faq-text">
                                            In caso di ordine sbagliato, rivolgiti direttamente all’esercente spiegando il problema. Ricorda che puoi avvalerti del diritto di recesso per tutti quei prodotti che non appartengono alle categorie del cibo o dei prodotti creati ad hoc per te.
                                            Nel caso in cui non riuscissi a risolvere il problema, puoi contattare Rivemo compilando questo <Link to={`/contatti`}>form</Link>.
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Sono un esercente, come posso aggiungere la mia attività a Rivemo?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails> 
                                        <Typography className="faq-text">
                                            Che tu sia un ristoratore o un fioraio, aggiungendo gratuitamente la tua attività su Rivemo potrai offrire un comodo servizio ai tuoi clienti e contemporaneamente attrarne di nuovi. Compila gratuitamente questo <Link to={`/azienda`}>form</Link> e unisciti a Rivemo!
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Come faccio a diventare un rider?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails> 
                                        <Typography className="faq-text">
                                            Vuoi entrare nel team Rivemo? Fantastico!  Contattaci tramite questo <Link to={`/rider`}>form</Link> e raccontaci qualcosa di te :-)
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Faq;