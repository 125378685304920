import React from 'react';
import {Helmet} from "react-helmet";
import Container from '@material-ui/core/Container';
import HubspotForm from 'react-hubspot-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync as faSpinner } from '@fortawesome/free-solid-svg-icons'

import { ReactComponent as Onda } from '../../assets/onda.svg';
import contactsImg from '../../assets/contatti.jpg';

import './hubspot-form.css';

import TagManager from 'react-gtm-module'

const pushGTM = () => TagManager.dataLayer({
    dataLayer: {
        'event': 'pageview'
    },
    dataLayerName: 'pageview'
});

const Contacts = (props) => {
    
    React.useEffect(() => {
        pushGTM();
    }, []);

    return(
        <>
            <Helmet defaultTitle="Hai bisogno di noi? Contatta lo staff di Rivemo">
                <title>Hai bisogno di noi? Contatta lo staff di Rivemo</title>
                <meta name="description" content="Se hai bisogno di chiarimenti o di qualche informazione contattaci subito! Lo staff di Rivemo è pronto a rispondere a tutte le tue domande." />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <div className="top-shop normal-page" style={{ backgroundImage: `url(${contactsImg})`, backgroundPosition: 'center center' }}>
                <Onda className="onda" />
                <div className="top-text">
                    <h1>Hai qualche domanda?</h1>
                    <h2>Per maggiori informazioni e altre tipologie di richieste, compila il nostro modulo di contatto.</h2>
                </div>
            </div>
            <div className="generic">
                <Container style={{ maxWidth: '600px', position: 'relative', zIndex:0, padding: '0' }} fixed>
                    <div className="chisiamo cnt">
                        <div className="chisiamo-text full">
                            <h3 className="offriamo">Compila il form</h3>
                            <p>Inserisci i tuoi dati, ti contatteremo noi al più presto.</p><br className="mob" />
                        </div>
                        <div className="hbs-container">
                            <HubspotForm
                                portalId='6208112'
                                formId='710b71ba-a48a-4b3e-a18e-f33dfc0bfc5a'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<p style={{ textAlign: 'center', margin: '10px' }}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Contacts;