export const BEACH_DELIVERY = "BEACH";
export const HOME_DELIVERY = "HOME";
export const RESTAURANT_DELIVERY = "RESTAURANT";

const VALID_DELIVERY_ADDRESS_TYPES = new Set([
    BEACH_DELIVERY,
    HOME_DELIVERY,
    RESTAURANT_DELIVERY,
]);

class DeliveryAddress {

    /**
     * Costruttore
     * @param {object} params 
     * @param {number} params.id 
     * @param {string} params.address 
     * @param {string} params.type 
     * @param {number} params.latitude 
     * @param {number} params.longitude 
     */
    constructor({ id, address, type, latitude, longitude, firstName, lastName, phone, email }) {
        this.id = id;
        this.address = address;
        this.type = type;
        this.latitude = latitude;
        this.longitude = longitude;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phone = phone;
        this.email = email;
    }

    /**
     * Metodo statico per creare un'istanza della classe verificando anche
     * la validità del parametro "type"
     * @param {object} params
     * @param {string} params.type
     * @param {object} params.data
     * @returns {DeliveryAddress}
     * @throws {Error}
     */
    static make({ type, ...data }) {
        if (!DeliveryAddress.isThisAValidTypeCode(type)) {
            throw new Error(`"${type}" is not a valid address type!`);
        }

        return new DeliveryAddress({
            ...data,
            type,
        });
    }

    /**
     * Imposta l'id 
     * @param {number} id
     * @returns {this} 
     */
    withId(id) {
        this.id = id;
        return this;
    }

    /**
     * Verifica la validità del codice passato
     * @param {string} code 
     * @returns {boolean}
     */
    static isThisAValidTypeCode(code) {
        return VALID_DELIVERY_ADDRESS_TYPES.has(code);
    }

}

export default DeliveryAddress;